import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation AccountUpdateStatusMutation($action: String!, $email: String!) {
  accountUpdateStatus(action: $action, email: $email) { 
    ok
    status
    error
  }
}
`;

export default ({
  id,
  action,
  email
}, done) => {

  const variables = {
    action,
    email
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { accountUpdateStatus: { ok, status } }) => {
        if (ok) {
          const account = proxyStore.get(id);
          account.setValue(status, 'status');
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.accountUpdateStatus;
        done(ok, error);
      },
      onError: error => console.error(error),
    }
  );
};
