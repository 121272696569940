import * as R from 'ramda';
import './Layout.less';
import React from 'react';
import { Layout } from 'antd';
import SignOutButton from './components/SignOutButton';
const { Sider, Content } = Layout;
import Menu from './components/Menu';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

const buildConfig = (routes) => {
  let config = {};

  routes.forEach(({ path, menu }) => {
    config = R.assocPath(menu, path, config);
  });

  return config;
};

const LayoutQuery = graphql`
  query LayoutQuery($envVar: EnvEnum!) {
    env(envVar: $envVar)
  }
`;

const Brand = ({ children }) => <div
  style={{
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '10px'
  }}
>
  {children}
</div>;

const Component = ({
  routes,
  children
}) => (
  <Layout>
    <Sider width={300}>
      <Menu config={buildConfig(routes)}/>

      <SignOutButton/>

      <Brand>SPID Admin</Brand>

      <QueryRenderer
        lookup
        environment={environment}
        query={LayoutQuery}
        variables={{
          envVar: 'TAG_VERSION'
        }}
        render={({ error, props }) => {
          if (!props || error) {
            return null;
          }

          return <Brand>
            {R.pathOr('', ['env'], props)}
          </Brand>;
        }}
      />

    </Sider>
    <Content>
      {children}
    </Content>
  </Layout>
);

export default Component;
