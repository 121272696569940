import * as R from 'ramda';
import React, { useState } from 'react';
import { Output } from '../../components/index';
import { Button } from 'antd';
import SelectManager from './components/updateCommercialManager/SelectManager';
import UsersList from './components/updateCommercialManager/UsersList';
import AccountUpdateCommercialManagerMutation from '../../mutations/AccountUpdateCommercialManagerMutation';

const updateUsers = async (commercialManagerEmail, users, setResult, setLoading) => {
  let result = { ok: true, error: null };
  await Promise.all(R.map(async function (user) {
    setLoading(true);
    AccountUpdateCommercialManagerMutation({ id: user.id, email: user.email, commercialManagerEmail, update: false }, (ok, error) => {
      setResult({ ok: result.ok && ok, error: error });
      setLoading(false);
    });
  }, users));
};

const UpdateCommercialManager = () => {

  const [managerToReplace, setManagerToReplace] = useState(null);
  const [newManager, setNewManager] = useState(null);
  const [isValid, setIsValid] = useState({ managerToReplace: false, newManager: false });
  const [id, setId] = useState(null);
  const [usersToUpdate, setUsersToUpdate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  return <Output>
    <h4>{'Mise à jour d\'un manager commercial'}</h4>

    <SelectManager
      title={'Entrez l\'adresse du manager à remplacer :'}
      manager={managerToReplace}
      setManager={setManagerToReplace}
      style={{ marginBottom: '20px', marginTop: '30px' }}
      setIsValid={(v) => {
        if (v !== isValid.managerToReplace) setIsValid({ ...isValid, managerToReplace: v });
      }}
      setId={(v) => {
        if (v !== id) setId(v);
      }}
    />

    {isValid.managerToReplace &&
      <SelectManager
        title={'Entrez l\'adresse du nouveau manager :'}
        manager={newManager}
        setManager={setNewManager}
        style={{ marginBottom: '30px' }}
        setIsValid={(v) => {
          if (v !== isValid.newManager) setIsValid({ ...isValid, newManager: v });
        }}
      />
    }

    {isValid.managerToReplace && !R.isNil(id) &&
      <UsersList id={id} setUsers={setUsersToUpdate}/>
    }

    {isValid.managerToReplace &&
    isValid.newManager &&
    newManager !== managerToReplace &&
    R.length(usersToUpdate) > 0 &&
    <div>
      <Button
        onClick={async () => await updateUsers(newManager, usersToUpdate, setResult, setLoading)} icon="edit" size="large"
        loading={loading}
        disabled={!R.isNil(result)}
      >
        Mettre à jour le manager
      </Button>
      {!R.isNil(result) &&
      <span style={{ color: result.ok ? 'green' : 'red', marginLeft: '15px' }}>{result.ok ? 'Les utilisateurs ont été mis à jour.' : `Une erreur est survenue [${result.error}].`}</span>
      }
    </div>
    }

  </Output>;
};

export default UpdateCommercialManager;
