import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
  mutation BuildFileStatusMutation($token: String!) {
    buildFileStatus(token: $token) {
      ok
      token
      status
      error
    }
  }
`;

export default ({ token }, done) => {
  const variables = {
    token
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, token, status, error } = response.buildFileStatus;
        done(ok, token, status, error);
      },
      onError: err => console.error(err),
    }
  );
};
