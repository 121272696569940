import React from 'react';
import Test from './Test';
import TableParametrage from './TableParametrage';
import Output from '../../components/Output';

const TableParametrageBody = ({ offerType }) => {
  return <Output>

    <TableParametrage offerType={offerType}/>

    <div style={{ margin: '40px 0' }}/>

    <Test offerType={offerType}/>

  </Output>;
};

export default TableParametrageBody;
