import * as R from 'ramda';
import React, { useState } from 'react';
import SelectDocuments from './SelectDocument';
import { Button } from 'antd';
import ConvertDocumentMutation from '../../../mutations/ConvertDocumentMutation';

const ConvertDocument = () => {

  let [document, setDocument] = useState(null);
  let [loading, setLoading] = useState(false);
  let [url, setUrl] = useState(null);
  let [loaded, setLoaded] = useState(false);
  let [error, setError] = useState(false);

  const convertDocument = () => {
    if (!R.isNil(document)) {
      setUrl(null);
      setLoading(true);
      setError(false);

      ConvertDocumentMutation(document, (ok, error, url) => {
        if (error) {
          setError(true);
          console.log(error);
        }
        if (ok) setUrl(url);
        setLoading(false);
      });
    }
  };

  const onChange = (document) => {
    setDocument(document);
    setUrl(null);
    setError(false);
  };

  return <div>
    <SelectDocuments onChange={onChange} setLoaded={setLoaded}/>

    <Button
      icon="file-pdf"
      disabled={R.isNil(document)}
      style={{
        display: !loaded ? 'none' : 'inline-block',
        marginLeft: '10px'
      }}
      loading={loading}
      onClick={convertDocument}
    >
      {loading ? 'Conversion en cours' : 'Convertir'}
    </Button>

    <Button
      type="primary"
      icon="eye"
      style={{
        display: R.isNil(url) ? 'none' : 'inline-block',
        marginLeft: '10px'
      }}
      href={url}
      target="_blank"
    >
      Voir le document
    </Button>

    {error && <span style={{ marginLeft: '10px' }}>La conversion a échoué.</span>}

  </div>;
};

export default ConvertDocument;
