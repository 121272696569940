/**
 * @flow
 * @relayHash fbef4efdf70b6e718c12954d945421ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CheckOtpCodeMutationVariables = {|
  codeId: string,
  code: string,
|};
export type CheckOtpCodeMutationResponse = {|
  +checkOtpCode: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type CheckOtpCodeMutation = {|
  variables: CheckOtpCodeMutationVariables,
  response: CheckOtpCodeMutationResponse,
|};
*/


/*
mutation CheckOtpCodeMutation(
  $codeId: String!
  $code: String!
) {
  checkOtpCode(codeId: $codeId, code: $code) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "codeId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      },
      {
        "kind": "Variable",
        "name": "codeId",
        "variableName": "codeId"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "checkOtpCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckOtpCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckOtpCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "fbef4efdf70b6e718c12954d945421ea",
    "metadata": {},
    "name": "CheckOtpCodeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2b4f2b358ffd4d025397917a7baa332';

module.exports = node;
