import * as R from 'ramda';
import React, { useState } from 'react';

import { Output } from '../../components/index';

import {
  Form, Input, Button,
} from 'antd';

const useBatchIdForm = (form) => {

  const buildUrl = (batchId) => R.when(R.compose(R.not, R.isNil), batchId => `/file/download/report/${batchId}`)(batchId);

  const [batchId, setBatchId] = useState();

  const onSubmitFields = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
      setBatchId(values.batchId);
      form.resetFields();
    });
  };

  return {
    batchId,
    uploadReportUrl: buildUrl(batchId),
    onSubmitFields
  };
};

const Reports = ({
  form

}) => {
  const {
    getFieldDecorator
  } = form;

  const {
    onSubmitFields,
    uploadReportUrl,
    batchId
  } = useBatchIdForm(form);

  return (
    <Output>
      <h4>Récupération des statistiques</h4>
      <div>
        <Form layout="inline" onSubmit={onSubmitFields}>
          <Form.Item>
            {getFieldDecorator('batchId', {
              rules: [{ required: true }]
            })(
              <Input placeholder="batchId" />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              {'OK'}
            </Button>
          </Form.Item>
        </Form>
        {
          !R.isNil(uploadReportUrl) && (
            <div>
              <br/>
              <a href={uploadReportUrl}>{`télécharger le rapport ${batchId}`}</a>
            </div>
          )
        }
      </div>
    </Output>
  );
};

export default Form.create({ name: 'reports' })(Reports);
