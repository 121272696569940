import React from 'react';
import SignOutMutation from '../../mutations/SignOutMutation';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from 'antd';

const SignOut = () => {
  const history = useHistory();

  const onSignOut = () => {
    SignOutMutation(() => {
      history.replace('/');
    });
  };
  return (
    <div>
      <Button
        size="small"
        onClick={onSignOut}
      >
        {'Déconnexion'}
      </Button>
    </div>
  );
};

export default withRouter(SignOut);
