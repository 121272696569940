import * as R from 'ramda';
import React, { useState } from 'react';
import { Button, Checkbox, Input, InputNumber, message } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import UpdateHomeMessageMutation from '../../mutations/UpdateHomeMessageMutation';
import Output from '../../components/Output';

const HomeMessageQuery = graphql`
query HomeMessageQuery {
  homemessage {
    message
    display
    length
  }
}
`;

const updateHomeMessage = (values) => {
  UpdateHomeMessageMutation(values, (ok, error) => {
    if (ok && !error) {
      message.success('Le message a bien été mis à jour.');
    } else {
      message.error('Une erreur est survenue.');
    }
  });
};

const HomeMessageForm = ({ message, display, length }) => {
  const [homeMessage, setHomeMessage] = useState({ message, display, length });

  return <div>

    <Input.TextArea
      value={homeMessage.message}
      defaultValue={message}
      onChange={e => setHomeMessage(R.assoc('message', e.target.value, homeMessage))}
      placeholder="Entrez le message à afficher sur la page d'accueil (3 lignes max)"
      autoSize={{ minRows: 3, maxRows: 3 }}
    />

    <Checkbox
      checked={homeMessage.display}
      defaultChecked={display}
      onChange={e => setHomeMessage(R.assoc('display', e.target.checked, homeMessage))}
    >
      {'Afficher le message sur la page d\'accueil'}
    </Checkbox>

    <br/>

    {'Durée d\'affichage du message (en secondes) : '}
    <InputNumber
      value={homeMessage.length}
      defaultValue={length}
      onChange={v => setHomeMessage(R.assoc('length', v, homeMessage))}
    />

    <br/><br/>

    <Button type="primary" onClick={() => updateHomeMessage(homeMessage)}>
      Mettre à jour le message
    </Button>

  </div>;
};

const HomeMessage = () => {

  return <Output>
    <h4>{'Modification du message d\'accueil'}</h4>

    <QueryRenderer
      environment={environment}
      query={HomeMessageQuery}
      render={({ error, props }) => {
        if (error || !props) return null;

        const message = R.pathOr('', ['homemessage', 'message'])(props);
        const display = R.pathOr(true, ['homemessage', 'display'])(props);
        const length = R.pathOr(20, ['homemessage', 'length'])(props);

        return <HomeMessageForm message={message} display={display} length={length}/>;

      }}
    />

  </Output>;
};

export default HomeMessage;
