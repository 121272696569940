/**
 * @flow
 * @relayHash 23dfb330923a52cfa69f10313f7ca8ed
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type ConvertDocumentMutationVariables = {|
  id: string,
  offerType: OfferTypeEnum,
  folder: string,
  version: string,
|};
export type ConvertDocumentMutationResponse = {|
  +convertDocument: ?{|
    +ok: boolean,
    +error: ?string,
    +url: ?string,
  |}
|};
export type ConvertDocumentMutation = {|
  variables: ConvertDocumentMutationVariables,
  response: ConvertDocumentMutationResponse,
|};
*/


/*
mutation ConvertDocumentMutation(
  $id: String!
  $offerType: OfferTypeEnum!
  $folder: String!
  $version: String!
) {
  convertDocument(id: $id, offerType: $offerType, folder: $folder, version: $version) {
    ok
    error
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "folder",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "folder",
        "variableName": "folder"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "UrlResponse",
    "kind": "LinkedField",
    "name": "convertDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvertDocumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConvertDocumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "23dfb330923a52cfa69f10313f7ca8ed",
    "metadata": {},
    "name": "ConvertDocumentMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d7d2252b13175dedb125ba7dd006c72';

module.exports = node;
