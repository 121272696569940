import React, { useState } from 'react';
import { inc } from 'ramda';

import {
  Col,
  Button,
  Row
} from 'antd';

import ContractHistoryItems from './ContractHistoryItems';

const ContractHistory = ({
  contractId,
  reload = true
}) => {
  const [reloadIndex, setReloadIndex] = useState(0);
  const onReload = () => setReloadIndex(inc);

  return <div>
    {reload && <Row>
      <Col>
        <span>{'rafraîchir'}&nbsp;</span>
        <Button size="small" shape="circle" icon="sync" onClick={onReload}/>
      </Col>
    </Row>}
    <ContractHistoryItems key={reloadIndex} contractId={contractId}/>
  </div>;
};

export default ContractHistory;
