import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { Card, Col, List, Row } from 'antd';
import { Loading } from '../helpers';
import environment from '../../Environment';
import ContractHistory from './ContractHistory';
import Moment from 'moment';
import ContractGeneral from './ContractGeneral';

const ContractAmendmentsQuery = graphql`
  query ContractAmendmentsQuery ($filter: ContractFilterInput) {
    contracts (filter: $filter) {
      id
      propositionNumber
      dates {
        creation
        lastUpdate
      }
      status
      companyInformation {
        contactLastname
        contactFirstname
        contactEmail
      }
      user {
        roles
        name
        email
      }
      signature {
        type
        id
      }
      contractNumbers
    }
  }
`;

const ContractAmendments = ({
  contract
}) => {
  const formatDate = (date) => R.isNil(date) ? '' : Moment(date).format('DD/MM/YYYY - HH:mm');

  return <QueryRenderer
    environment={environment}
    query={ContractAmendmentsQuery}
    variables={{
      filter: { formerContractId: R.prop('id')(contract) }
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error}</div>;
      }

      if (!props) {
        return <Loading/>;
      }

      const contracts = R.propOr([], 'contracts')(props);

      if (R.length(contracts) === 0) {
        return null;
      }

      return <Card title={'Avenant(s)'} size="small" style={{ marginTop: '5xp' }}>
        <List
          itemLayout="vertical"
          dataSource={contracts}
          renderItem={contract => {
            const get = (prop) => R.pathOr('', prop.split('.'))(contract);

            return <List.Item style={{ padding: '6px' }}>
              <h4 style={{ marginBottom: '-8px' }}><a href={`/contract/find?search=${get('propositionNumber')}`}>{`Avenant du ${formatDate(get('dates.creation'))}`}</a></h4>
              <span style={{ fontStyle: 'italic', fontSize: '0.7em' }}>{`Dernière modification le ${formatDate(get('dates.lastUpdate'))}`}</span>

              <Row gutter={8}>
                <ContractGeneral contract={contract} isAmendment={true}/>
                <Col span={12}>
                  <ContractHistory contractId={get('id')} reload={false}/>
                </Col>
              </Row>
            </List.Item>;
          }}
        />
      </Card>;
    }}
  />;
};

export default ContractAmendments;
