import * as R from 'ramda';
import React, { useState } from 'react';
import { Input, Button, List } from 'antd';
import Note from './components/Note';
import environment from '../../Environment';
import ContractNotesMutation from '../../mutations/ContractNotesMutation';
import { graphql, QueryRenderer } from 'react-relay';
import NoteInputStatus from './components/NoteInputStatus';

const ContractNotesQuery = graphql`
 query ContractNotesQuery($contractId: ID!) {
   contract(contractId: $contractId) {
     notes {
       id
       value
       dates {
         creation
         lastUpdate
       }
       action {
         tag
         status
       }
     }
   }
 }
`;

const useManageNewNote = (contractId) => {

  const defaultState = {
    value: '',
    action: { tag: '', status: 'pending' }
  };
  const [newNote, setNewNote] = useState(defaultState);

  const onCreateNewNote = (reload, setReload) => {
    if (!R.isEmpty(newNote.value) && !R.isEmpty(newNote.action.tag) && !R.isEmpty(newNote.action.status)) {
      ContractNotesMutation(contractId, { action: 'add', value: newNote }, () => {
        setNewNote(defaultState);
        setReload(reload + 1);
      });
    }
  };

  const onUpdateNewNoteValue = (e) => {
    newNote.value = e.target.value;
    setNewNote(newNote);
  };

  const onUpdateNewNoteStatus = (v) => {
    newNote.action.status = v;
    setNewNote(newNote);
  };

  const onUpdateNewNoteTag = (e) => {
    newNote.action.tag = e.target.value;
    setNewNote(newNote);
  };

  return {
    onCreateNewNote,
    newNote,
    onUpdateNewNoteValue,
    onUpdateNewNoteStatus,
    onUpdateNewNoteTag
  };
};

const ContractNotes = (
  ({
    contractId
  }) => {

    const { onUpdateNewNoteValue, onUpdateNewNoteStatus, onUpdateNewNoteTag, onCreateNewNote } = useManageNewNote(contractId);
    const [reload, setReload] = useState(0);

    return <QueryRenderer
      environment={environment}
      query={ContractNotesQuery}
      variables={{
        contractId,
        reload
      }}
      render={({ error, props }) => {

        if (error) {
          return <div>{error.stack}</div>;
        } else if (!props) return null;

        const notes = R.pathOr([], ['contract', 'notes'])(props);

        return <div>
          <div style={{ width: '80%', margin: 'auto', marginBottom: '50px' }}>
            <Input onChange={onUpdateNewNoteValue} placeholder="Note"/>
            <NoteInputStatus onChange={onUpdateNewNoteTag} onSelect={onUpdateNewNoteStatus}/>
            <Button
              type="primary"
              icon="edit"
              onClick={() => onCreateNewNote(reload, setReload)}
              style={{ float: 'right' }}
            >Ajouter la note</Button>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={notes}
            renderItem={note => <Note note={note} contractId={contractId} reload={reload} setReload={setReload}/>}
          />
        </div>;
      }}
    />;
  }
);

export default ContractNotes;
