import * as R from 'ramda';
import React from 'react';
import { Table, Button } from 'antd';
import Moment from 'moment';

const columns = [{
  title: 'Fichier',
  dataIndex: 'type'
}, {
  title: 'Date',
  dataIndex: 'date',
  sorter: (a, b) => a.time - b.time,
  sortOrder: 'descend',
  sortDirections: ['descend']
}, {
  title: 'Télécharger',
  dataIndex: 'download',
  width: '50'
}];

const formatFiles = (files, type, exts, showFilename = false) => {
  const formattedFiles = [];

  if (!R.isNil(files)) {
    R.forEach(file => {
      const uploadDateMoment = Moment(file.uploadDate);
      formattedFiles.push({
        id: file.id,
        type: showFilename ? file.filename : type,
        date: uploadDateMoment.format('DD/MM/YYYY - H[h]mm[:]ss'),
        time: uploadDateMoment.unix(),
        download: R.map(ext => {
          return <Button
            type="primary"
            icon="download"
            key={`${file.id}${ext}`}
            href={`/file/download/${file.id}?filename=${file.filename}`}
            style={{ marginRight: '5px' }}>{ext.toUpperCase()}</Button>;
        })(exts)
      });
    })(files);
  }

  return formattedFiles;
};

const FilesList = ({
  files,
  type,
  exts,
  pageSize = 10,
  showFilename = false
}) => {
  return <Table
    pagination={{ pageSize }}
    columns={columns}
    dataSource={formatFiles(files, type, exts, showFilename)}
    locale={{ emptyText: 'Aucun fichier n\'a été trouvé.' }}
    loading={R.isNil(files)}
    rowKey="id"
  />;
};

export default FilesList;
