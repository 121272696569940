import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation DeleteContractsBySirenMutation($siren: String!) {
    deleteContractsBySiren(siren: $siren) { 
      ok
      error
      reason
    }
  }
`;

export default ({
  siren
}, done) => {

  const variables = {
    siren
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error, reason } = response.deleteContractsBySiren;
        done(ok, error, reason);
      },
      onError: error => console.error(error),
    }
  );
};
