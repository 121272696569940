import * as R from 'ramda';

import Moment from 'moment';

import React from 'react';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import { Card, Col } from 'antd';

const ContractDetailsQuery = graphql`
query ContractDetailsQuery($contractId: ID!, $withTitles: Boolean) {
  contract(contractId: $contractId, withTitles: $withTitles) {
    id
    ccnTitle
    offerTypes
    relatedContract {
      startDate
      checked
    }
    contractDescription {
      C {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      NC {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      APP {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      AM {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      TOUS {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
    }
    amendment {
      formerContractDescription {
        C {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        NC {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        APP {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        AM {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        TOUS {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
      }
    }
    staff {
      C {
        isSelected
        count
      }
      NC {
        isSelected
        count
      }
      APP {
        isSelected
        count
      }
      AM {
        isSelected
        count
      }
      TOUS {
        isSelected
        count
      }
    }
    health {
      contractDescription {
        C {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        NC {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        APP {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        AM {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        TOUS {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
      }
      staff {
        C {
          isSelected
          count
        }
        NC {
          isSelected
          count
        }
        APP {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        TOUS {
          isSelected
          count
        }
      }
    }
    startDate
  }
} 
`;

const ContractDescription = ({
  contract,
  offerType,
  offerTypeLabel,
  color,
}) => {
  const hasOfferType = R.compose(
    R.includes(offerType),
    R.propOr([], 'offerTypes')
  )(contract);

  if (!hasOfferType) {
    return null;
  }

  const {
    contractDescription
  } = offerType === 'SANTE' ? contract.health : contract;

  return <div style={{ marginBottom: '10px' }}>
    <div style={{ fontWeight: 'bold', color }}>{offerTypeLabel}</div>

    {R.map((college) => {
      if (R.isEmpty(R.pathOr([], [college, 'bases'], contractDescription))) {
        return null;
      }

      return <div key={college}>
        <h5>{college}</h5>

        {R.map(
          ({ baseTitle, options }) => (
            <div key={baseTitle}>
              <div>{baseTitle}</div>

              {R.when(
                R.compose(
                  R.not,
                  R.isNil
                ),
                R.map(({ optionTitle }) => (<div key={optionTitle}>{optionTitle}</div>))
              )(options)}
            </div>
          )
        )(contractDescription[college].bases)}

      </div>;
    })(['C', 'NC', 'APP', 'AM', 'TOUS'])}
  </div>;
};

const ContractDetails = ({
  contractId,
  formatContract // Fonction pour modifier le contrat récupéré par la query
}) => {
  return <QueryRenderer
    environment={environment}
    query={ContractDetailsQuery}
    variables={{
      contractId,
      withTitles: true
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.stack}</div>;
      } else if (!props) {
        return null;
      }

      let contract = props.contract;

      if (R.is(Function, formatContract)) {
        contract = formatContract(contract);
      }

      const ccnTitle = R.pathOr('', ['contract', 'ccnTitle'], props);

      const isHealthRelatedContract = R.both( // Suite économique santé
        R.pathEq(['relatedContract', 'checked'], true),
        R.propSatisfies(R.includes('SANTE'), 'offerTypes')
      )(contract);

      const startDate = isHealthRelatedContract
        ? R.pathOr('', ['relatedContract', 'startDate'], contract)
        : Moment(R.propOr('', 'startDate')(contract)).format('DD/MM/YYYY');

      return <Col span={12}>
        <Card size="small" title="Description du contrat">
          <div>{`CCN : ${ccnTitle}`}</div>

          <div style={{ marginBottom: '8xp' }}>{`Date d'effet : ${startDate}`}</div>

          <ContractDescription
            offerType="PREVOYANCE"
            offerTypeLabel="Prévoyance"
            contract={contract}
            color="#e300e3"
          />

          <ContractDescription
            offerType="SANTE"
            offerTypeLabel="Santé"
            contract={contract}
            color="#00cf00"
          />
        </Card>
      </Col>;
    }}
  />;
};

export default ContractDetails;
