import './less/app.less';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Tokens from './lib/Tokens';
import reducers from './reducers';
import Routes from './Routes';
import { logger } from 'redux-logger';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

// eslint-disable-next-line no-undef
Tokens.setKey(API_USER_ID, API_KEY);

const store = createStore(
  reducers,
  applyMiddleware(thunkMiddleware, logger)
);

const getDocumentRoot = () => {
  const element = document.createElement('div');
  document.body.appendChild(element);
  return element;
};

const root = createRoot(getDocumentRoot());

root.render(
  <ConfigProvider locale={frFR}>
    <Routes store={store}/>
  </ConfigProvider>
);
