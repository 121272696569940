import './Loading.less';
import React from 'react';

import { 
  Spin
} from 'antd';

const Loading = () => (
  <div className="loading">
    <Spin/>
  </div>
);

export default Loading;