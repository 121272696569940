const salesList = [
  { label: 'Mdpro', value: 'Mdpro' },
  { label: 'MDE', value: 'MDE' },
  { label: 'VAD', value: 'VAD' },
  { label: 'VADC', value: 'VADC' }
];

export {
  salesList
};
