/**
 * @flow
 * @relayHash cb6cf80867a6515ee0bfec0e06599bc8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateElectronicSignatureIdMutationVariables = {|
  propositionId: string,
  electronicSignatureId?: ?$ReadOnlyArray<?string>,
|};
export type UpdateElectronicSignatureIdMutationResponse = {|
  +updateElectronicSignatureId: ?{|
    +ok: boolean,
    +error: ?string,
    +contract: ?{|
      +id: ?string,
      +signature: ?{|
        +id: ?string,
        +type: ?string,
      |},
    |},
  |}
|};
export type UpdateElectronicSignatureIdMutation = {|
  variables: UpdateElectronicSignatureIdMutationVariables,
  response: UpdateElectronicSignatureIdMutationResponse,
|};
*/


/*
mutation UpdateElectronicSignatureIdMutation(
  $propositionId: ID!
  $electronicSignatureId: [String]
) {
  updateElectronicSignatureId(propositionId: $propositionId, electronicSignatureId: $electronicSignatureId) {
    ok
    error
    contract {
      id
      signature {
        id
        type
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "electronicSignatureId",
    "type": "[String]"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "electronicSignatureId",
        "variableName": "electronicSignatureId"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "ContractResponse",
    "kind": "LinkedField",
    "name": "updateElectronicSignatureId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractSignature",
            "kind": "LinkedField",
            "name": "signature",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateElectronicSignatureIdMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateElectronicSignatureIdMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "cb6cf80867a6515ee0bfec0e06599bc8",
    "metadata": {},
    "name": "UpdateElectronicSignatureIdMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b6645482d7e4c3a3f5a0e162db94920';

module.exports = node;
