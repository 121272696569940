import * as R from 'ramda';

import React, { useState } from 'react';

import {
  withRouter
} from 'react-router-dom';

import { Output } from '../../components/index';

import { Input } from 'antd';

import EmailList from './EmailList';
import getQueryParams from '../../lib/getQueryParams';

const Search = ({
  location,
  history
}) => {
  const [filter, setFilter] = useState(() => {
    const query = getQueryParams(location);
    return { recipient: R.propOr('', 'recipient', query) };
  });

  const onChange = (e) => {
    setFilter({ recipient: e.target.value });
    history.replace(`/emails/search?recipient=${e.target.value}`);
  };
  return (
    <Output>
      <h4>{'Recherche d\'un contrat'}</h4>
      <Input
        placeholder="adresse email"
        value={filter.recipient}
        onChange={onChange}
      />
      <EmailList filter={filter}/>
    </Output>
  );
};

export default withRouter(Search);
