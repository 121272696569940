/**
 * @flow
 * @relayHash eb92e69c1f64f06e17af9850049e39c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NoteInput = {|
  action?: ?ActionNoteInput,
  dates?: ?DatesInput,
  id?: ?string,
  value?: ?string,
|};
export type ActionNoteInput = {|
  status?: ?string,
  tag?: ?string,
|};
export type DatesInput = {|
  creation?: ?string,
  lastUpdate?: ?string,
|};
export type ContractNotesMutationVariables = {|
  contractId: string,
  value: NoteInput,
  action?: ?string,
|};
export type ContractNotesMutationResponse = {|
  +contractNotes: ?{|
    +ok: boolean,
    +error: ?string,
    +notes: ?$ReadOnlyArray<?{|
      +id: ?string,
      +value: ?string,
      +dates: ?{|
        +creation: ?string,
        +lastUpdate: ?string,
      |},
      +action: ?{|
        +tag: ?string,
        +status: ?string,
      |},
    |}>,
  |}
|};
export type ContractNotesMutation = {|
  variables: ContractNotesMutationVariables,
  response: ContractNotesMutationResponse,
|};
*/


/*
mutation ContractNotesMutation(
  $contractId: ID!
  $value: NoteInput!
  $action: String
) {
  contractNotes(contractId: $contractId, value: $value, action: $action) {
    ok
    error
    notes {
      id
      value
      dates {
        creation
        lastUpdate
      }
      action {
        tag
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value",
    "type": "NoteInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "action",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "NotesResponse",
    "kind": "LinkedField",
    "name": "contractNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Note",
        "kind": "LinkedField",
        "name": "notes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Dates",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUpdate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ActionNote",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tag",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractNotesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractNotesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "eb92e69c1f64f06e17af9850049e39c3",
    "metadata": {},
    "name": "ContractNotesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f10e646246ffe5307860d28111e8646c';

module.exports = node;
