import * as R from 'ramda';
import React, { useState } from 'react';
import { Input, Button, List, Tag, Card, Popconfirm } from 'antd';
import Moment from 'moment';
import NoteInputStatus from './NoteInputStatus';
import ContractNotesMutation from '../../../mutations/ContractNotesMutation';

const formatDate = (date) => R.isEmpty(date) ? '' : Moment(date).format('DD/MM/YYYY H:mm');

const getColor = (status) => {
  const colors = [];
  colors['pending'] = 'gold';
  colors['processing'] = 'geekblue';
  colors['done'] = 'green';
  return colors[status];
};

const useManageNote = (contractId, note, reload, setReload) => {

  let [updatedNote, setUpdatedNote] = useState({
    id: note.id,
    value: note.value,
    action: { ...note.action }
  });

  const onDelete = () => {
    if (!R.isNil(contractId) && !R.isNil(note)) {
      ContractNotesMutation(contractId, { action: 'remove', value: note }, (ok, error) => {
        if (ok) setReload(reload + 1);
        if (error) console.log(error);
      });
    }
  };

  const onUpdate = (setIsEditing) => {
    if (!R.isNil(contractId) && !R.isNil(updatedNote)) {
      ContractNotesMutation(contractId, { action: 'update', value: updatedNote }, (ok, error) => {
        setIsEditing(false);
        if (ok) setReload(reload + 1);
        if (error) console.log(error);
      });
    }
  };

  const onUpdateNoteValue = (v) => {
    updatedNote.value = v;
    setUpdatedNote(updatedNote);
  };

  const onUpdateNoteStatus = (v) => {
    updatedNote.action.status = v;
    setUpdatedNote(updatedNote);
  };

  const onUpdateNoteTag = (e) => {
    updatedNote.action.tag = e.target.value;
    setUpdatedNote(updatedNote);
  };

  return {
    onDelete,
    onUpdate,
    onUpdateNoteStatus,
    onUpdateNoteTag,
    onUpdateNoteValue
  };

};

const Note = (
  ({
    contractId,
    note,
    reload,
    setReload
  }) => {
    const [isEditing, setIsEditing] = useState(false);
    const { onDelete, onUpdateNoteValue, onUpdateNoteStatus, onUpdateNoteTag, onUpdate } = useManageNote(contractId, note, reload, setReload);

    const v = (path) => R.pathOr('', path)(note);

    return !isEditing ?
      <div>
        <List.Item>
          <Card style={{ width: '100%' }}>
            <Card.Grid style={{ width: '100%', padding: '10px' }}>{v(['value'])}</Card.Grid>
            <Card.Grid style={{ width: '100%', padding: '0' }}>
              <div style={{ display: 'inline-block', marginTop: '5px' }}>
                <Tag color="purple">{v(['action', 'tag'])}</Tag>
                <Tag color={getColor(v(['action', 'status']))}>{v(['action', 'status'])}</Tag>
              </div>
              <span style={{ fontStyle: 'italic', fontSize: '0.7em' }}>{`Créée le ${formatDate(v(['dates', 'creation']))}, modifiée le ${formatDate(v((['dates', 'lastUpdate'])))}.`}</span>
              <div style={{ float: 'right' }}>
                <Button type="primary" icon="form" onClick={() => setIsEditing(true)}>Modifier</Button>
                <Popconfirm
                  placement="topRight"
                  title="Êtes-vous sûr de vouloir supprimer la note ?"
                  onConfirm={onDelete}
                  okText="Supprimer"
                  cancelText="Annuler">
                  <Button type="danger" icon="delete">Supprimer</Button>
                </Popconfirm>
              </div>
            </Card.Grid>
          </Card>
        </List.Item>
      </div>
      :
      <List.Item>
        <Card style={{ width: '100%' }}>
          <Card.Grid style={{ width: '100%', padding: '10px' }}><Input defaultValue={v(['value'])} onChange={e => onUpdateNoteValue(e.target.value)}/></Card.Grid>
          <Card.Grid style={{ width: '100%', padding: '0' }}>
            <NoteInputStatus onChange={onUpdateNoteTag} onSelect={onUpdateNoteStatus} tagValue={v(['action', 'tag'])} statusValue={v(['action', 'status'])} style={{ width: '85%' }}/>
            <Button type="primary" icon="save" onClick={() => onUpdate(setIsEditing)} style={{ width: '15%' }}>Enregistrer</Button>
          </Card.Grid>
        </Card>
      </List.Item>
    ;
  }
);

export default Note;
