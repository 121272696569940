import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../Environment';
import Account from './Account';
import AccountPanelHeader from './components/AccountPanelHeader';
import { Collapse } from 'antd';
import { Loading } from '../../../components/helpers';
import LocalPagination from '../../../components/LocalPagination';
import usePagination from '../../../hooks/usePagination';

const Panel = Collapse.Panel;

const AccountsListQuery = graphql`
  query AccountsListQuery($role: String, $managementCenter: String, $query: String, $pagination: PaginationInput!) {
    usersWithPagination(role: $role, managementCenter: $managementCenter, query: $query, pagination: $pagination) {
      users {
        id
        email
        username
        status
        roles
        dates {
          signUp
          lastSignIn
          lastConnection
          lastSignOut
        }
        extra {
          userCode
          region
          regionName
          managementCenter
          managementCenterName
          lastname
          firstname
          sales
          commercialManagerEmail
          regionalManagerEmail
          providers
          providerEmail
        }
      }
      pagination {
        limit
        page
        pages
        total
      }
    }
  }
`;

const AccountsList = ({
  userRole,
  managementCenter,
  searchStr
}) => {
  const {
    pagination,
    onUpdatePageSize,
    onUpdatePageNumber
  } = usePagination();

  return <QueryRenderer
    environment={environment}
    query={AccountsListQuery}
    variables={{
      role: userRole,
      managementCenter,
      query: searchStr,
      pagination
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error}</div>;
      } else if (!props) {
        return <Loading/>;
      }

      const users = R.pathOr([], ['usersWithPagination', 'users'])(props);
      const pagination = R.pathOr({}, ['usersWithPagination', 'pagination'])(props);

      if (R.isEmpty(users)) {
        return null;
      }

      const paginationProps = type => ({
        pagination,
        onUpdatePageSize,
        onUpdatePageNumber,
        type
      });

      return <>
        <LocalPagination {...paginationProps('top')}/>

        <Collapse accordion style={{ marginTop: '10px' }}>

          {users.map((user) => {
            const roles = R.propOr([], 'roles')(user);
            const name = `${R.pathOr('', ['extra', 'firstname'])(user)} ${R.pathOr('', ['extra', 'lastname'])(user)}`;
            const status = R.propOr('', 'status')(user);

            return <Panel
              header={<AccountPanelHeader node={{ name, roles, status }}/>}
              key={user.id}
            >
              <Account user={user} key={user.id}/>
            </Panel>;
          })}
        </Collapse>

        <LocalPagination {...paginationProps('bot')}/>

      </>;
    }}
  />;
};

export default AccountsList;
