import * as R from 'ramda';
import React, { useState } from 'react';
import TestOutput from './components/TestOutput';
import { Button } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../../components/helpers';

const TestQuery = graphql`
  query TestQuery($offerType: OfferTypeEnum!) {
    references(offerType: $offerType) {
      numberPassed
      numberFailed
      errors
    }
  }
`;

const Test = ({ offerType }) => {
  const [clicked, setClicked] = useState(false);

  return <>
    <h4 style={{ marginBottom: '10px' }}>{'Tester la table de paramétrage sur les contrats existants'}</h4>

    {
      !clicked ?
        <Button type="primary" icon="check" onClick={() => setClicked(true)}>
          Tester
        </Button>
        :
        <QueryRenderer
          environment={environment}
          query={TestQuery}
          variables={{
            offerType
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error}</div>;
            } else if (!props) return <Loading/>;

            const references = R.pathOr({}, ['references'])(props);
            return <TestOutput references={references}/>;

          }}
        />
    }

  </>;
};

export default Test;
