/**
 * @flow
 * @relayHash 4a4005367ff18db447d0b795160ab2b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CommercialCodesQueryVariables = {||};
export type CommercialCodesQueryResponse = {|
  +commercialCodes: ?$ReadOnlyArray<?{|
    +code: ?string,
    +email: ?string,
    +firstName: ?string,
    +lastName: ?string,
    +sales: ?string,
  |}>
|};
export type CommercialCodesQuery = {|
  variables: CommercialCodesQueryVariables,
  response: CommercialCodesQueryResponse,
|};
*/


/*
query CommercialCodesQuery {
  commercialCodes {
    code
    email
    firstName
    lastName
    sales
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CommercialCode",
    "kind": "LinkedField",
    "name": "commercialCodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sales",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommercialCodesQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommercialCodesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "4a4005367ff18db447d0b795160ab2b3",
    "metadata": {},
    "name": "CommercialCodesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03b1c37677b919901f049de06188cad9';

module.exports = node;
