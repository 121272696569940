import { Pagination } from 'antd';
import React from 'react';

const LocalPagination = ({ pagination, onUpdatePageNumber, onUpdatePageSize, type }) => <div className={`contracts-pagination-${type}`}>
  <Pagination
    size="small"
    showSizeChanger
    showQuickJumper
    defaultCurrent={pagination.page}
    pageSize={pagination.limit}
    total={pagination.total}
    pageSizeOptions={['10', '25', '50', '100']}
    onChange={onUpdatePageNumber}
    onShowSizeChange={onUpdatePageSize}
  />
</div>;
    
export default LocalPagination;
