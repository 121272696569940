import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ContractNotesMutation($contractId: ID!, $value: NoteInput!, $action: String) {
  contractNotes(contractId: $contractId, value: $value, action: $action) { 
    ok
    error
    notes {
      id
      value
      dates {
        creation
        lastUpdate
      }
      action {
        tag
        status
      }
    }
  }
}
`;

export default (contractId, { action, value }, done) => {
  const variables = {
    action,
    contractId,
    value
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.contractNotes;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
