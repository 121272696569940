import * as R from 'ramda';

const rolesList = [
  {
    name: 'admin',
    title: 'Administrateur',
    color: 'blue',
    bold: true
  },
  {
    name: 'national_manager',
    title: 'Manager national',
    color: 'red',
    bold: true
  },
  {
    name: 'national_manager_vadc',
    title: 'Manager national VADC',
    color: 'red',
    bold: true
  },
  {
    name: 'regional_manager',
    title: 'Manager régional',
    color: 'magenta',
    bold: true
  },
  {
    name: 'manager',
    title: 'Manager',
    color: 'orange'
  },
  {
    name: 'reseau_commercial',
    title: 'Réseau commercial',
    color: 'green'
  },
  {
    name: 'groupe_adhesion',
    title: 'Groupe adhésion',
    color: 'purple'
  },
  {
    name: 'apporteur',
    title: 'Apporteur',
    color: 'gold'
  }
];

const getRole = role => R.find(R.propEq('name', role))(rolesList);
const sortedRoles = R.pluck('name', rolesList);

export {
  rolesList,
  sortedRoles,
  getRole
};
