import * as R from 'ramda';
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation AccountManageRoleMutation($role: UserRoleEnum!, $email: String!, $action: UserRoleActionEnum!) {
  accountManageRole(role: $role, email: $email, action: $action) { 
    ok
    error
  }
}
`;

export default ({
  id,
  role,
  email,
  action
}, done) => {

  const variables = {
    role,
    email,
    action
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { accountManageRole: { ok } }) => {
        if (ok) {
          const account = proxyStore.get(id);
          let roles = account.getValue('roles');

          if (action === 'add')
            roles = R.append(role, roles);
          else if (action === 'drop')
            roles = R.reject((r) => r === role, roles);

          account.setValue(roles, 'roles');
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.accountManageRole;
        done(ok, error);
      },
      onError: error => console.error(error),
    }
  );
};
