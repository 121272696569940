import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation CheckOtpCodeMutation($codeId: String!, $code: String!) {
  checkOtpCode(codeId: $codeId, code: $code) {
    ok
    error
  }
}
`;

export default ({ codeId, code }, done) => {
  const variables = {
    codeId,
    code
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error } = response.checkOtpCode;
        done(ok, error);
      },
      onError: error => {
        done(false, error);
      },
    }
  );
};
