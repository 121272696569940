import React, { useState } from 'react';
import Output from '../../components/Output';
import { Button, Input, message, Popconfirm } from 'antd';
import { isSIREN } from 'siret';
import DownloadContractsBySirenMutation from '../../mutations/DownloadContractsBySirenMutation';
import * as R from 'ramda';
import DeleteContractsBySirenMutation from '../../mutations/DeleteContractsBySirenMutation';

const useRGPD = () => {
  const [siren, setSiren] = useState('');
  const [loading, setLoading] = useState(false);

  const sirenIsValid = isSIREN(siren);

  const downloadContracts = () => {
    setLoading(true);

    DownloadContractsBySirenMutation({ siren }, (ok, error, url) => {
      setLoading(false);

      if (ok && !error && !R.isNil(url)) {
        window.open(url, '__blank');
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.');
      }
    });
  };

  const deleteContracts = () => {
    setLoading(true);

    DeleteContractsBySirenMutation({ siren }, (ok, error, reason) => {
      setLoading(false);

      if (ok && !error) {
        if (!R.isNil(reason)) {
          const errorMessage = R.propOr(reason, reason, {
            CONTRACT_CONFIRMED_IN_MANAGEMENT_SYSTEM: 'Suppression impossible : SIREN associé à un ou plusieurs contrats en gestion.',
            CONTRACT_NOT_FOUND: 'Aucune donnée n\'a été trouvée.'
          });

          message.error(errorMessage);
        } else {
          message.success('Les dossiers ont été supprimés.');
        }
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.');
      }
    });
  };

  return {
    siren,
    setSiren,
    sirenIsValid,
    loading,
    downloadContracts,
    deleteContracts
  };
};

const RGPD = () => {
  const {
    siren,
    setSiren,
    sirenIsValid,
    loading,
    downloadContracts,
    deleteContracts
  } = useRGPD();

  return <Output>
    <div style={{ maxWidth: 500 }}>
      <Input
        value={siren}
        onChange={e => setSiren(e.target.value)}
        placeholder="Saisir un SIREN"
      />

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 12, columnGap: 8 }}>
        <Button
          loading={loading}
          disabled={!sirenIsValid}
          onClick={downloadContracts}
          type="primary"
        >
          Extraire les données du SIREN
        </Button>

        <Popconfirm
          placement="topRight"
          title="Attention, la suppression des données est irréversible !"
          onConfirm={deleteContracts}
          okText="Supprimer"
          cancelText="Annuler"
          disabled={!sirenIsValid}
        >
          <Button
            loading={loading}
            disabled={!sirenIsValid}
            type="primary"
          >
            Supprimer les données du SIREN
          </Button>
        </Popconfirm>
      </div>
    </div>
  </Output>;
};

export default RGPD;
