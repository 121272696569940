import React from 'react';
import { Button } from 'antd';

const FileHeader = ({
  status,
  onBuild,
  onReload,
  label,
  allowGeneration = true
}) => {
  const isDev = process.env.NODE_ENV === 'development';

  return <div>
    {(allowGeneration || isDev) && <Button
      type="primary"
      onClick={onBuild}
      style={{ marginRight: '10px', marginBottom: '10px' }}
    >
      {isDev && !allowGeneration ? '[DEV] ' : ''}{label}
    </Button>}

    <Button
      type="primary"
      icon="reload"
      onClick={onReload} style={{ marginRight: '10px' }}
    >
      Actualiser la liste
    </Button>

    { status === 'building' && <span>Le fichier est en cours de génération, veuillez patienter...</span> }
    { status === 'building_failed' && <span>{ 'Une erreur est survenue, le fichier n\'a pas pu être généré.' }</span> }
    { status === 'built' && <span>Le fichier a été généré.</span> }
  </div>;
};

export default FileHeader;
