import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation AccountUpdateRegionalManagerMutation($regionalManagerEmail: String!, $email: String!) {
  accountUpdateRegionalManager(regionalManagerEmail: $regionalManagerEmail, email: $email) { 
    ok
    error
  }
}
`;

export default ({
  id,
  email,
  regionalManagerEmail,
  update
}, done) => {

  const variables = {
    email,
    regionalManagerEmail
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { accountUpdateRegionalManager: { ok } }) => {
        if (ok && update) {
          const account = proxyStore.get(id);
          const extra = account.getLinkedRecord('extra');
          extra.setValue(regionalManagerEmail, 'regionalManagerEmail');
          account.setLinkedRecord(extra, 'extra');
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.accountUpdateRegionalManager;
        done(ok, error);
      },
      onError: error => console.error(error),
    }
  );
};
