import React from 'react';
import Filter from '../components/Filter';
import { Select } from 'antd';
import * as R from 'ramda';

const FilterSelect = ({
  value,
  onChange,
  label,
  placeholder,
  options = [],
  multi
}) => {
  return <Filter
    label={label}
  >
    <Select
      placeholder={placeholder}
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      allowClear
      mode={multi ? 'multiple' : 'default'}
    >
      {R.map(({ label, value }) => {
        return <Select.Option
          key={value}
          value={value}
        >
          {label}
        </Select.Option>;
      })(options)}
    </Select>
  </Filter>;
};

export default FilterSelect;
