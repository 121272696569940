import * as R from 'ramda';
import React from 'react';
import { Input, Select } from 'antd';
const { Option } = Select;

const SelectStatus = (
  ({
    onSelect,
    defaultValue
  }) =>
    <Select defaultValue={defaultValue} style={{ width: '120px' }} onChange={onSelect}>
      <Option value="pending">pending</Option>
      <Option value="processing">processing</Option>
      <Option value="done">done</Option>
    </Select>
);

const NoteInputStatus = (
  ({
    onChange,
    onSelect,
    tagValue,
    statusValue,
    style
  }) => <Input
    onChange={onChange}
    placeholder="Tag"
    style={style}
    defaultValue={R.isNil(tagValue) ? '' : tagValue}
    addonAfter={<SelectStatus onSelect={onSelect} defaultValue={R.isNil(statusValue) ? 'pending' : statusValue}/>}
  />
);

export default NoteInputStatus;
