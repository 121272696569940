/**
 * @flow
 * @relayHash b399d2046cff4012d0535ebe9e2e4b9c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type TestQueryVariables = {|
  offerType: OfferTypeEnum
|};
export type TestQueryResponse = {|
  +references: ?{|
    +numberPassed: ?number,
    +numberFailed: ?number,
    +errors: ?$ReadOnlyArray<?string>,
  |}
|};
export type TestQuery = {|
  variables: TestQueryVariables,
  response: TestQueryResponse,
|};
*/


/*
query TestQuery(
  $offerType: OfferTypeEnum!
) {
  references(offerType: $offerType) {
    numberPassed
    numberFailed
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      }
    ],
    "concreteType": "References",
    "kind": "LinkedField",
    "name": "references",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberPassed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberFailed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b399d2046cff4012d0535ebe9e2e4b9c",
    "metadata": {},
    "name": "TestQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '79ec98cda28d994012bbfba589358317';

module.exports = node;
