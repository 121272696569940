/**
 * @flow
 * @relayHash 22ec1c7b0ad8fde2754cfdcc6580eca5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type ContractDetailsQueryVariables = {|
  contractId: string,
  withTitles?: ?boolean,
|};
export type ContractDetailsQueryResponse = {|
  +contract: ?{|
    +id: ?string,
    +ccnTitle: ?string,
    +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
    +relatedContract: ?{|
      +startDate: ?string,
      +checked: ?boolean,
    |},
    +contractDescription: ?{|
      +C: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +baseTitle: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +optionTitle: ?string,
          |}>,
        |}>
      |},
      +NC: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +baseTitle: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +optionTitle: ?string,
          |}>,
        |}>
      |},
      +APP: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +baseTitle: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +optionTitle: ?string,
          |}>,
        |}>
      |},
      +AM: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +baseTitle: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +optionTitle: ?string,
          |}>,
        |}>
      |},
      +TOUS: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +baseTitle: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string,
            +optionTitle: ?string,
          |}>,
        |}>
      |},
    |},
    +amendment: ?{|
      +formerContractDescription: ?{|
        +C: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +NC: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +APP: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +AM: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +TOUS: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
      |}
    |},
    +staff: ?{|
      +C: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +NC: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +APP: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +AM: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +TOUS: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
    |},
    +health: ?{|
      +contractDescription: ?{|
        +C: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +NC: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +APP: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +AM: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
        +TOUS: ?{|
          +bases: ?$ReadOnlyArray<?{|
            +baseId: ?string,
            +baseTitle: ?string,
            +options: ?$ReadOnlyArray<?{|
              +optionId: ?string,
              +optionTitle: ?string,
            |}>,
          |}>
        |},
      |},
      +staff: ?{|
        +C: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +NC: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +APP: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +AM: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
        +TOUS: ?{|
          +isSelected: ?boolean,
          +count: ?number,
        |},
      |},
    |},
    +startDate: ?string,
  |}
|};
export type ContractDetailsQuery = {|
  variables: ContractDetailsQueryVariables,
  response: ContractDetailsQueryResponse,
|};
*/


/*
query ContractDetailsQuery(
  $contractId: ID!
  $withTitles: Boolean
) {
  contract(contractId: $contractId, withTitles: $withTitles) {
    id
    ccnTitle
    offerTypes
    relatedContract {
      startDate
      checked
    }
    contractDescription {
      C {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      NC {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      APP {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      AM {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
      TOUS {
        bases {
          baseId
          baseTitle
          options {
            optionId
            optionTitle
          }
        }
      }
    }
    amendment {
      formerContractDescription {
        C {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        NC {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        APP {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        AM {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        TOUS {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
      }
    }
    staff {
      C {
        isSelected
        count
      }
      NC {
        isSelected
        count
      }
      APP {
        isSelected
        count
      }
      AM {
        isSelected
        count
      }
      TOUS {
        isSelected
        count
      }
    }
    health {
      contractDescription {
        C {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        NC {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        APP {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        AM {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
        TOUS {
          bases {
            baseId
            baseTitle
            options {
              optionId
              optionTitle
            }
          }
        }
      }
      staff {
        C {
          isSelected
          count
        }
        NC {
          isSelected
          count
        }
        APP {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        TOUS {
          isSelected
          count
        }
      }
    }
    startDate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withTitles",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BaseProduct",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseOptionProduct",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionTitle",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "C",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "NC",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "APP",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "AM",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "TOUS",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDescription",
  "kind": "LinkedField",
  "name": "contractDescription",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isSelected",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "StaffFigures",
  "kind": "LinkedField",
  "name": "staff",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "C",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "NC",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "APP",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "AM",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "TOUS",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "withTitles",
        "variableName": "withTitles"
      }
    ],
    "concreteType": "Contract",
    "kind": "LinkedField",
    "name": "contract",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RelatedContract",
        "kind": "LinkedField",
        "name": "relatedContract",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checked",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Amendment",
        "kind": "LinkedField",
        "name": "amendment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractDescription",
            "kind": "LinkedField",
            "name": "formerContractDescription",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractHealth",
        "kind": "LinkedField",
        "name": "health",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractDetailsQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractDetailsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": "22ec1c7b0ad8fde2754cfdcc6580eca5",
    "metadata": {},
    "name": "ContractDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6065c14eb3df2bd4be3d032264104ba';

module.exports = node;
