/**
 * @flow
 * @relayHash 252634b59af0913e5311a769ca829db3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ElectronicSignatureQueryVariables = {|
  id: string
|};
export type ElectronicSignatureQueryResponse = {|
  +electronicSignature: ?{|
    +id: ?string,
    +status: ?string,
  |}
|};
export type ElectronicSignatureQuery = {|
  variables: ElectronicSignatureQueryVariables,
  response: ElectronicSignatureQueryResponse,
|};
*/


/*
query ElectronicSignatureQuery(
  $id: ID!
) {
  electronicSignature(id: $id) {
    id
    status
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ElectronicSignature",
    "kind": "LinkedField",
    "name": "electronicSignature",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ElectronicSignatureQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ElectronicSignatureQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "252634b59af0913e5311a769ca829db3",
    "metadata": {},
    "name": "ElectronicSignatureQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32a05c46adce195520d2beeb1573295b';

module.exports = node;
