import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation SignInMutation($email: String!, $password: String!) {
  signIn(email: $email, password: $password) { 
    ok
    error
    user {
      id
      username
      email
      mustCheckMFA
    }
  }
}
`;

export default (email, password, done) => {
  const variables = {
    email,
    password
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.signIn;
        done(ok, error);
      },
      onError: error => {
        done(false, error);
      },
    }
  );
};
