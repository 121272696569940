import * as R from 'ramda';
import React, { useState } from 'react';
import validator from 'validator';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../../Environment';
import Loading from '../../../../components/helpers/Loading';

import {
  Button,
  Col,
  Icon,
  Input,
  Row
} from 'antd';

const SelectManagerQuery = graphql`
query SelectManagerQuery ($email: String!) {
  account(email: $email) {
    id
    email
    roles
    extra {
      region
    }
  }
}
`;

const SelectManager = ({
  title,
  manager,
  setId,
  setIsValid,
  setManager,
  style
}) => {

  const [isClicked, setIsClicked] = useState(false);

  const reset = () => {
    setIsClicked(false);
    setIsValid(false);
    if (!R.isNil(setId)) setId(null);
  };

  return <Row style={style}>

    <Col span={5} style={{ marginTop: '5px' }}>
      <span>{title}</span>
    </Col>

    <Col span={5} style={{ marginRight: '5px' }}>
      <Input
        placeholder={'Adresse mail'}
        prefix={<Icon type={'mail'}/>}
        value={manager}
        onChange={(e) => {
          reset();
          setManager(e.target.value);
        }}
      />
    </Col>

    <Col span={2}>
      <Button onClick={() => setIsClicked(true)} disabled={R.isNil(manager) || R.isEmpty(manager) || !validator.isEmail(manager) || isClicked} type="primary" icon="check">Valider</Button>
    </Col>

    <Col span={8} style={{ margin: '5px 0 0 20px' }}>
      {
        isClicked &&
        <QueryRenderer
          environment={environment}
          query={SelectManagerQuery}
          variables={{
            email: manager
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>{error}</div>;
            } else if (!props) return <Loading/>;

            let err = null;

            const user = R.path(['account'])(props);
            const roles = R.propOr([], 'roles')(user);

            if (R.isNil(user)) err = 'L\'utilisateur n\'a pas été trouvé.';
            else if (!R.includes('manager')(roles)) err = 'L\'utilisateur n\'est pas manager.';
            else if (!R.includes('reseau_commercial')(roles)) err = 'L\'utilisateur ne fait pas partie du réseau commercial.';

            if (R.isNil(err)) {
              setIsValid(true);
              if (!R.isNil(setId)) setId(R.prop('id')(user));
              return <span>OK</span>;
            } else {
              setIsValid(false);
              return <span style={{ color: 'red' }}>{err}</span>;
            }

          }}
        />
      }
    </Col>
  </Row>;
};

export default SelectManager;
