import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation UpdateContractFromOMGMutation($file: FileInput!, $src: String!) {
    updateContractFromOmg(file: $file, src: $src) {
      ok
      error
    }
  }
`;

export default ({ file }, onDone = null) => {
  const variables = { };

  const uploadables = { file: file };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      uploadables,
      onCompleted: (response) => {
        const { ok, error } = response.updateContractFromOmg;
        onDone(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};

