import React from 'react';
import SignOutButton from '../../account/SignOutButton';

const Component = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '12px auto'
    }}
  >
    <SignOutButton/>
  </div>
);

export default Component;
