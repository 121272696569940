import * as R from 'ramda';
import React from 'react';
import { Tag } from 'antd';
import { getRole, sortedRoles } from '../../../../data/rolesList';

const AccountRoles = (
  ({
    roles,
    style
  }) => {
    if (!Array.isArray(roles) || R.length(roles) === 0) {
      return null;
    }

    return <div style={style}>
      {R.map(role => {
        const roleData = getRole(role);

        if (!R.isNil(roleData) && R.includes(role, roles)) {
          return <Tag
            color={roleData.color}
            key={role}
            style={{ fontWeight: roleData.bold ? 'bold' : 'normal' }}
          >
            {roleData.title}
          </Tag>;
        }
      })(sortedRoles)}
    </div>;

  }
);

export default AccountRoles;
