/**
 * @flow
 * @relayHash 2e68b59e4dca549b0a0a2f87a82dd498
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type TableParametrageQueryVariables = {|
  ccnId?: ?string,
  offerType: OfferTypeEnum,
|};
export type TableParametrageQueryResponse = {|
  +tableParametrage: ?$ReadOnlyArray<?string>,
  +ccns: ?$ReadOnlyArray<?{|
    +id: ?string,
    +shortTitle: ?string,
  |}>,
|};
export type TableParametrageQuery = {|
  variables: TableParametrageQueryVariables,
  response: TableParametrageQueryResponse,
|};
*/


/*
query TableParametrageQuery(
  $ccnId: String
  $offerType: OfferTypeEnum!
) {
  tableParametrage(ccnId: $ccnId, offerType: $offerType)
  ccns {
    id
    shortTitle
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      }
    ],
    "kind": "ScalarField",
    "name": "tableParametrage",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccns",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TableParametrageQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TableParametrageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2e68b59e4dca549b0a0a2f87a82dd498",
    "metadata": {},
    "name": "TableParametrageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '987b8afc729412bc501edef300d587ac';

module.exports = node;
