import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Input,
  Row,
  Col
} from 'antd';

import UpdateContractBuilderMutation from '../../mutations/UpdateContractBuilderMutation';

const useEditContractNumbers = (contractId, email) => {

  const [value, setValue] = useState(email);
  const [canSave, setCanSave] = useState(true);

  const onUpdateContractBuilder = (value) => {
    setValue(value);
    setCanSave(true);
  };

  const onSaveContractBuilder = () => {
    UpdateContractBuilderMutation(contractId, { email: value }, () => {

    });
  };

  return {
    onUpdateContractBuilder,
    onSaveContractBuilder,
    canSave,
    value
  };
};

const EditContractBuilder = ({
  contractId,
  email
}) => {
  const {
    canSave,
    onUpdateContractBuilder,
    value,
    onSaveContractBuilder
  } = useEditContractNumbers(contractId, email);

  return (
    <div>
      <div>
        <h4>{'Email du fabricant'}</h4>
        <Row gutter={8}>
          <Col span={8}>
            <Input
              size="small"
              value={value}
              placeholder={email}
              onChange={(e) => onUpdateContractBuilder(e.target.value)}
            />
          </Col>
          <Button size="small" disabled={!canSave} onClick={onSaveContractBuilder}>Enregistrer</Button>
        </Row>
      </div>
    </div>
  );
};

EditContractBuilder.propTypes = {
  email: PropTypes.string,
  contractId: PropTypes.string
};

export default EditContractBuilder;
