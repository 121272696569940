import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation SendOtpCodeMutation {
  sendOtpCode {
    ok
    error
    codeId
  }
}
`;

export default (done) => {
  commitMutation(
    environment,
    {
      mutation,
      variables: {},
      onCompleted: (response) => {
        const { ok, error, codeId } = response.sendOtpCode;
        done(ok, error, codeId);
      },
      onError: error => {
        done(false, error);
      },
    }
  );
};
