import * as R from 'ramda';

import React, { useState, useEffect } from 'react';

import {
  Alert,
  Button,
  Input,
  Row,
  Col
} from 'antd';

import UpdateElectronicSignatureIdMutation from '../../mutations/UpdateElectronicSignatureIdMutation';

const useEditElectronicSignatureId = (contractId, electronicSignatureId) => {

  const electronicSignatureIdIsValid = (v) => {
    return R.length(v) === 36 && v !== electronicSignatureId;
  };

  const [value, setValue] = useState(electronicSignatureId);
  const [canSave, setCanSave] = useState(electronicSignatureIdIsValid(electronicSignatureId));

  const onUpdateValue = (value) => {
    setValue(value);
    setCanSave(electronicSignatureIdIsValid(value));
  };

  const onSaveValue = () => {
    UpdateElectronicSignatureIdMutation(contractId, { electronicSignatureId: value }, () => {

    });
  };

  useEffect(() => {
    setCanSave(electronicSignatureIdIsValid(electronicSignatureId));
  }, [electronicSignatureId, electronicSignatureIdIsValid]);

  return {
    onUpdateValue,
    onSaveValue,
    canSave,
    value
  };
};

const EditElectronicSignatureId = ({
  contractId,
  electronicSignatureId
}) => {
  const {
    canSave,
    onUpdateValue,
    value,
    onSaveValue
  } = useEditElectronicSignatureId(contractId, electronicSignatureId);

  if (R.isNil(electronicSignatureId)) return null;

  return (
    <div>
      {
        R.isEmpty(value) && <Alert type="warning" message="Pas de numéro de contrat défini"/>
      }
      {
        !R.isEmpty(value) && (
          <div>
            <h4>{'Identifiant de signature électronique'}</h4>
            <Row gutter={8}>

              <Col span={8}>
                <Input
                  size="small"
                  value={value}
                  placeholder={value}
                  onChange={(e) => onUpdateValue(e.target.value)}
                />
                    &nbsp;
              </Col>
              <Button size="small" disabled={!canSave} onClick={onSaveValue}>Enregistrer</Button>
            </Row>
          </div>
        )
      }
    </div>
  );
};

export default EditElectronicSignatureId;
