import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Loading } from '../../components/helpers';
import { Select, Table, Button, message } from 'antd';
import GenerateTableParametrageMutation from '../../mutations/GenerateTableParametrageMutation';

const mapIndexed = R.addIndex(R.map);
const reduceIndexed = R.addIndex(R.reduce);

const TableParametrageQuery = graphql`
  query TableParametrageQuery($ccnId: String, $offerType: OfferTypeEnum!) {
    tableParametrage(ccnId: $ccnId, offerType: $offerType)
    ccns {
      id
      shortTitle
    }
  }
`;

const TableParametrage = ({ offerType }) => {
  const [ccn, setCcn] = useState();
  const [generationLoading, setGenerationLoading] = useState(false);

  const generateTableParametrage = () => {
    setGenerationLoading(true);
    GenerateTableParametrageMutation({ ccnId: ccn, offerType }, (ok, error, url) => {
      setGenerationLoading(false);

      if (ok && !error && !R.isNil(url)) {
        window.open(url, '_blank');
      } else {
        message.error('Une erreur est survenue, veuillez réessayer');
      }
    });
  };

  return <>
    <h4 style={{ marginBottom: '10px' }}>Visualiser la table de paramétrage</h4>

    <QueryRenderer
      environment={environment}
      query={TableParametrageQuery}
      variables={{
        ccnId: ccn,
        offerType
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        } else if (!props) {
          return <Loading/>;
        }

        const tableParametrage = R.compose(
          R.map(R.split(';;')),
          R.propOr([], 'tableParametrage')
        )(props);

        const headers = R.head(tableParametrage);

        const dataSource = R.compose(
          mapIndexed((line, lineIndex) => {
            return R.compose(
              R.assoc('rowKey', `line${lineIndex}`),
              reduceIndexed((acc, value, columnIndex) => {
                return R.assoc(`column${columnIndex}`, value)(acc);
              }, {})
            )(line);
          }),
          R.drop(1)
        )(tableParametrage);

        const ccns = R.propOr([], 'ccns', props);

        return <div>
          <Select
            value={ccn}
            onChange={setCcn}
            style={{ width: '400px' }}
            allowClear
            showSearch
            placeholder="Sélectionner une CCN"
          >
            {R.map(({ id, shortTitle }) => {
              return <Select.Option
                key={id}
                value={id}
              >
                {shortTitle}
              </Select.Option>;
            })(ccns)}
          </Select>

          <Button
            icon="file-excel"
            type="primary"
            onClick={generateTableParametrage}
            loading={generationLoading}
          >
            Exporter
          </Button>

          <Table
            dataSource={dataSource}
            rowKey="rowKey"
            size="small"
            pagination={false}
            scroll={{
              y: 600,
              x: true
            }}
            tableLayout="fixed"
          >
            {mapIndexed((header, headerIndex) => {
              return <Table.Column
                title={header}
                dataIndex={`column${headerIndex}`}
                width={200}
                render={text => {
                  return <div style={{ fontSize: '14px' }}>{text}</div>;
                }}
              />;
            })(headers)}

          </Table>

        </div>;
      }}
    />
  </>;
};

export default TableParametrage;
