import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { Output } from '../../components/index';
import environment from '../../Environment';
import FilesList from './components/FilesList';

const EscQuery = graphql`
  query EscQuery($type: String!) {
    files(type: $type) {
      id
      filename
      uploadDate
    }
  }
`;

const Esc = () => {
  return <Output>
    <QueryRenderer
      environment={environment}
      query={EscQuery}
      variables={{ type: 'ESC' }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        }

        return <FilesList
          files={R.path(['files'])(props)}
          exts={['csv']}
          pageSize={5}
          showFilename
        />;
      }}
    />
  </Output>;
};

export default Esc;
