/**
 * @flow
 * @relayHash 99e727b261ce3eea3fd57258ca660f27
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateContractNumbersMutationVariables = {|
  propositionId: string,
  contractNumbers: $ReadOnlyArray<?string>,
|};
export type UpdateContractNumbersMutationResponse = {|
  +updateContractNumbers: ?{|
    +ok: boolean,
    +error: ?string,
    +contract: ?{|
      +id: ?string,
      +contractNumbers: ?$ReadOnlyArray<?string>,
    |},
  |}
|};
export type UpdateContractNumbersMutation = {|
  variables: UpdateContractNumbersMutationVariables,
  response: UpdateContractNumbersMutationResponse,
|};
*/


/*
mutation UpdateContractNumbersMutation(
  $propositionId: ID!
  $contractNumbers: [String]!
) {
  updateContractNumbers(propositionId: $propositionId, contractNumbers: $contractNumbers) {
    ok
    error
    contract {
      id
      contractNumbers
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractNumbers",
    "type": "[String]!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractNumbers",
        "variableName": "contractNumbers"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "ContractResponse",
    "kind": "LinkedField",
    "name": "updateContractNumbers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contractNumbers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateContractNumbersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateContractNumbersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "99e727b261ce3eea3fd57258ca660f27",
    "metadata": {},
    "name": "UpdateContractNumbersMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9bdb42a70cf7bf7d96d42082377444da';

module.exports = node;
