/**
 * @flow
 * @relayHash c853d0381ad6e1f26df50534fcfc985d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RetryUpdateContractFromOMGMutationVariables = {|
  id: string
|};
export type RetryUpdateContractFromOMGMutationResponse = {|
  +retryUpdateContractFromOmg: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type RetryUpdateContractFromOMGMutation = {|
  variables: RetryUpdateContractFromOMGMutationVariables,
  response: RetryUpdateContractFromOMGMutationResponse,
|};
*/


/*
mutation RetryUpdateContractFromOMGMutation(
  $id: String!
) {
  retryUpdateContractFromOmg(id: $id) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "retryUpdateContractFromOmg",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RetryUpdateContractFromOMGMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RetryUpdateContractFromOMGMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "c853d0381ad6e1f26df50534fcfc985d",
    "metadata": {},
    "name": "RetryUpdateContractFromOMGMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '463527feab0c84379d16ea4bd6bbd8ff';

module.exports = node;
