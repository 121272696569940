import React from 'react';

import {
  Switch
} from 'react-router-dom';

import RouteWithSubRoutes from './RouteWithSubRoutes';

const Commands = ({ routes }) => (
  <Switch>
    {routes.map((route, i) => (
      <RouteWithSubRoutes key={i} {...route} />
    ))} 
  </Switch>
);

export default Commands;