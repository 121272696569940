import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation UpdateCommercialCodesMutation($file: FileInput!, $src: String!) {
    updateCommercialCodes(file: $file, src: $src) {
      ok
      error
      usersNotFound
    }
  }
`;

export default ({ file }, done) => {
  const variables = { };

  const uploadables = { file };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      uploadables,
      onCompleted: (response) => {
        const { ok, error, usersNotFound } = response.updateCommercialCodes;
        done(ok, error, usersNotFound);
      },
      onError: err => console.error(err),
    }
  );
};

