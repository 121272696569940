import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';
import { isNil, map } from 'ramda';

const mutation = graphql`
mutation CcnUpdateEnabledMutation($ccnId: String!, $scope: CcnScopeEnum!, $offerType: OfferTypeEnum!, $enabled: Boolean!) {
  ccnUpdateEnabled(ccnId: $ccnId, scope: $scope, offerType: $offerType, enabled: $enabled) { 
    ok
    error
  }
}
`;

export default ({ ccnId, enabled, offerType, scope }, done) => {
  const variables = {
    ccnId,
    enabled,
    scope,
    offerType
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { ccnUpdateEnabled: { ok } }) => {
        if (ok) {
          const ccn = proxyStore.get(ccnId);

          if (!isNil(enabled)) {
            let status = ccn.getLinkedRecords('status');

            status = map(statusItem => {
              const _scope = statusItem.getValue('scope');
              const _offerType = statusItem.getValue('offerType');

              if (scope === _scope && offerType === _offerType) {
                statusItem.setValue(enabled, 'enabled');
              }

              return statusItem;
            })(status);

            ccn.setLinkedRecords(status, 'status');
          }
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.ccnUpdateEnabled;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
