/**
 * @flow
 * @relayHash d8dfa475c775c495aa40287522ccb829
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractsUpdateFromOmgFeedbackCsvTasksQueryVariables = {||};
export type ContractsUpdateFromOmgFeedbackCsvTasksQueryResponse = {|
  +contractsUpdateFromOmgFeedbackCsvTasks: ?$ReadOnlyArray<?{|
    +id: ?string,
    +startDate: ?string,
    +endDate: ?string,
    +date: ?string,
    +status: ?string,
    +filename: ?string,
    +totalToProcess: ?number,
    +totalProcessed: ?number,
  |}>
|};
export type ContractsUpdateFromOmgFeedbackCsvTasksQuery = {|
  variables: ContractsUpdateFromOmgFeedbackCsvTasksQueryVariables,
  response: ContractsUpdateFromOmgFeedbackCsvTasksQueryResponse,
|};
*/


/*
query ContractsUpdateFromOmgFeedbackCsvTasksQuery {
  contractsUpdateFromOmgFeedbackCsvTasks {
    id
    startDate
    endDate
    date
    status
    filename
    totalToProcess
    totalProcessed
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractsUpdateFromOmgFeedbackCsvTask",
    "kind": "LinkedField",
    "name": "contractsUpdateFromOmgFeedbackCsvTasks",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalToProcess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalProcessed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractsUpdateFromOmgFeedbackCsvTasksQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContractsUpdateFromOmgFeedbackCsvTasksQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "d8dfa475c775c495aa40287522ccb829",
    "metadata": {},
    "name": "ContractsUpdateFromOmgFeedbackCsvTasksQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd617b1d5954a2a7201d46b41a9f4af5';

module.exports = node;
