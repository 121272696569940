import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation CensorUsersMutation($emails: [String!]!) {
    censorUsers(emails: $emails) { 
      ok
      error
      failedUsers
    }
  }
`;

export default ({
  emails
}, done) => {

  const variables = {
    emails
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error, failedUsers } = response.censorUsers;
        done(ok, error, failedUsers);
      },
      onError: error => console.error(error),
    }
  );
};
