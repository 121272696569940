import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Card, Col } from 'antd';
import { ElectronicSignature } from './components';

const useSignatureInfo = (contract) => {
  const getIsSignatureStatus = (contract, status) => {
    return R.includes(R.prop('status', contract), status);
  };

  const getIsElectronicSignature = (contract) => {
    return R.pathEq(['signature', 'type'], 'electronic', contract);
  };

  const getIsContractSentForSignature = (contract) => getIsSignatureStatus(contract, ['sentForSignature', 'sentForSignatureDelayed']);
  const getIsContractSigned = (contract) => getIsSignatureStatus(contract, ['confirmed', 'sentToManagementSystem', 'confirmedInManagementSystem', 'rejectedByManagementSystem', 'confirmedInManagementSystemReopened']);

  const [isSentForSignature, setIsSentForSignature] = useState(getIsContractSentForSignature(contract));
  const [isSigned, setIsSigned] = useState(getIsContractSigned(contract));
  const [isElectronic, setIsElectronic] = useState(getIsElectronicSignature(contract));

  useEffect(() => {
    setIsSentForSignature(getIsContractSentForSignature(contract));
    setIsSigned(getIsContractSigned(contract));
    setIsElectronic(getIsElectronicSignature(contract));
  }, [contract]);

  return {
    electronicSignatureId: R.pathOr(null, ['signature', 'id'], contract),
    isSentForSignature,
    isSigned,
    isElectronic
  };
};

const ContractSignatureInfo = ({
  contract
}) => {
  const {
    isSentForSignature,
    isSigned,
    isElectronic,
    electronicSignatureId
  } = useSignatureInfo(contract);

  if (R.isNil(electronicSignatureId)) {
    return null;
  }

  if (!isSentForSignature && !isSigned) {
    return null;
  }

  return <Col span={12}>
    <Card style={{ marginTop: '8px' }} size="small" title="Information sur la signature">
      {isSentForSignature && (
        <div>
          {`Le contrat n'a pas encore été signé ${isElectronic ? 'électroniquement' : 'manuellement'}`}
        </div>
      )}

      {isSigned && (
        <div>
          {`Le contrat est signé ${isElectronic ? 'électroniquement' : 'manuellement'}`}
        </div>
      )}

      {isElectronic && (
        <div>
          <ElectronicSignature id={electronicSignatureId} expectedStatus={isSigned ? 'completed' : 'ready'}/>
        </div>
      )}
    </Card>
  </Col>;
};

export default ContractSignatureInfo;
