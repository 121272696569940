/**
 * @flow
 * @relayHash 40ed2029e6130e7779135f5569571be9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type GenerateTableParametrageMutationVariables = {|
  ccnId?: ?string,
  offerType: OfferTypeEnum,
|};
export type GenerateTableParametrageMutationResponse = {|
  +generateTableParametrage: ?{|
    +ok: boolean,
    +error: ?string,
    +url: ?string,
  |}
|};
export type GenerateTableParametrageMutation = {|
  variables: GenerateTableParametrageMutationVariables,
  response: GenerateTableParametrageMutationResponse,
|};
*/


/*
mutation GenerateTableParametrageMutation(
  $ccnId: String
  $offerType: OfferTypeEnum!
) {
  generateTableParametrage(ccnId: $ccnId, offerType: $offerType) {
    ok
    error
    url
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      }
    ],
    "concreteType": "UrlResponse",
    "kind": "LinkedField",
    "name": "generateTableParametrage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateTableParametrageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateTableParametrageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "40ed2029e6130e7779135f5569571be9",
    "metadata": {},
    "name": "GenerateTableParametrageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b817ba0c158eeb1c5bfe4a5089d0fb80';

module.exports = node;
