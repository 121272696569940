import * as R from 'ramda';

import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../Environment';

import {
  Link
} from 'react-router-dom';

import {
  Loading
} from '../../helpers';

import {
  Alert,
  Row,
  Col,
  Tag
} from 'antd';

const ElectronicSignatureQuery = graphql`
  query ElectronicSignatureQuery($id: ID!) {
    electronicSignature(id: $id) {
      id
      status
    }
  }
`;

const ElectronicSignature = ({
  id,
  expectedStatus
}) => {
  if (R.isNil(id)) return null;
  return (
    <QueryRenderer
      environment={environment}
      query={ElectronicSignatureQuery}
      variables={{
        id
      }}
      render={({ props }) => {
        if (R.isNil(props)) {
          return <Loading/>;
        } else {
          if (R.isNil(props.electronicSignature)) return (
            <Alert
              type="error"
              description={'id de signature invalide'}
            />
          );
          const attr = (path) => R.pathOr({}, path.split('.'), props.electronicSignature);
          const getColor = (status) => R.ifElse(
            R.equals(expectedStatus),
            R.always('green'),
            R.always('red')
          )(status);

          return (
            <div>
              <Row style={{ marginTop: '8px' }}>
                <Col span={6}>{'id :'}</Col>
                <Col span={18}><Link to={`/universign/details?id=${attr('id')}`}>{attr('id')}</Link></Col>
              </Row>
              <Row>
                <Col span={6}>{'statut universign :'}</Col>
                <Col span={18}><Tag color={getColor(attr('status'))}>{attr('status')}</Tag></Col>
              </Row>
              {
                attr('status') !== expectedStatus && (
                  <div style={{ marginTop: '12px' }}>
                    <Alert
                      type="error"
                      description={'Le statut SPID du contrat et le statut universign de la signature ne sont pas cohérents.'}
                    />
                  </div>
                )
              }
            </div>
          );
        }
      }}
    />
  );
};

export default ElectronicSignature;
