/**
 * @flow
 * @relayHash 529fd8532057d1ab7f7824960922aace
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FindQueryVariables = {||};
export type FindQueryResponse = {|
  +data: ?{|
    +managementCenters: ?$ReadOnlyArray<?{|
      +name: ?string,
      +code: ?string,
    |}>
  |}
|};
export type FindQuery = {|
  variables: FindQueryVariables,
  response: FindQueryResponse,
|};
*/


/*
query FindQuery {
  data {
    managementCenters {
      name
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Center",
        "kind": "LinkedField",
        "name": "managementCenters",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FindQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FindQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "529fd8532057d1ab7f7824960922aace",
    "metadata": {},
    "name": "FindQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '93799ac14e230e5dbdb821700c5b3979';

module.exports = node;
