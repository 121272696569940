import * as R from 'ramda';

import React, { useState } from 'react';

import ContractTagsMutation from '../../mutations/ContractTagsMutation';

import {
  Input,
  Tag
} from 'antd';

const useManageNewTag = (contractId, tags) => {

  const [newTag, setNewTag] = useState();

  const onCreateNewTag = (e) => {
    ContractTagsMutation(contractId, { action: 'add', value: e.target.value }, () => {
      setNewTag('');
    });
  };

  const onUpdateNewTag = (e) => {
    setNewTag(e.target.value);
  };

  const onRemoveTag = (index) => {
    ContractTagsMutation(contractId, { action: 'remove', value: R.path([index, 'value'], tags) }, () => {
    });
  };

  return {
    onCreateNewTag,
    newTag,
    onUpdateNewTag,
    onRemoveTag
  };
};

const ContractTags = ({
  contractId,
  tags
}) => {
  const {
    newTag,
    onCreateNewTag,
    onRemoveTag,
    onUpdateNewTag
  } = useManageNewTag(contractId, tags);

  return (
    <div className="contract-thumb-tags">
      <Input
        placeholer="tapez un tag puis ENTER pour le créer"
        value={newTag}
        onChange={onUpdateNewTag}
        onPressEnter={onCreateNewTag}
      />

      <div className="contract-thumb-tags-list">
        {
          tags.map((tag, index) => (
            <Tag
              key={tag.id}
              closable
              onClose={() => onRemoveTag(index)}
            >{tag.value}</Tag>
          ))
        }
      </div>
    </div>
  );
};

export default ContractTags;
