import React from 'react';
import { Card, Col } from 'antd';
import { always, compose, cond, defaultTo, equals, find, includes, isNil, pathEq, propEq, prop, propOr } from 'ramda';
import moment from 'moment';

const ContractGED = ({ contract }) => {
  const displayStatus = includes(contract.status, [
    'confirmed',
    'sentToManagementSystem',
    'confirmedInManagementSystem',
    'rejectedByManagementSystem'
  ]) && pathEq(['signature', 'type'], 'electronic')(contract);

  if (!displayStatus) {
    return null;
  }

  const statusDate = compose(
    v => v.format('DD/MM/YYYY [à] HH:mm:ss'),
    moment,
    prop('when'),
    defaultTo({}),
    find(propEq('status', contract.GEDstatus)),
    propOr([], 'statusHistory')
  )(contract);

  return <Col span={12}>
    <Card
      size="small"
      style={{ marginTop: '8px', fontWeight: 'bold' }}
      title="Information sur la mise en GED"
    >
      {cond([
        [equals('done'), always(<span style={{ color: 'green' }}>La mise en GED a été effectuée le {statusDate}.</span>)],
        [equals('rejected'), always(<span style={{ color: 'red' }}>La mise en GED a échoué le {statusDate}.</span>)],
        [isNil, always('Ce contrat n\'a pas encore été mis en GED')]
      ])(contract.GEDstatus)}
    </Card>
  </Col>;
};

export default ContractGED;
