import * as R from 'ramda';
import React from 'react';
import { Tag } from 'antd';
import { getAccountStatus } from '../../../../lib/Status';

const getColor = (status) => R.prop(status)({
  pending: 'blue',
  confirmed: 'green',
  locked: 'red'
});

const AccountStatus = (
  ({
    status
  }) => {
    const allowedStatus = ['pending', 'confirmed', 'locked'];

    if (R.isNil(status) || R.isEmpty(status) || !R.includes(status, allowedStatus)) return null;

    return <Tag
      color={getColor(status)}
      key={status}
    >
      {getAccountStatus(status)}
    </Tag>;

  }
);

export default AccountStatus;
