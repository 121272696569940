import React from 'react';

const AccountField = (
  ({
    title,
    children,
    style
  }) => <div style={style}>
    <span style={{ fontWeight: 'bold' }}>{title} : </span>
    {children}
  </div>
);

export default AccountField;
