import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ConvertDocumentMutation($id: String!, $offerType: OfferTypeEnum!, $folder: String!, $version: String!) {
  convertDocument(id: $id, offerType: $offerType, folder: $folder, version: $version) { 
    ok
    error
    url
  }
}
`;

export default ({ fileId, offerType, version, folder }, done) => {
  const variables = {
    id: fileId,
    offerType,
    version,
    folder
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error, url } = response.convertDocument;
        done(ok, error, url);
      },
      onError: err => console.error(err),
    }
  );
};
