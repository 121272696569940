import * as R from 'ramda';
import React from 'react';
import { Output } from '../../components/index';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import CcnTable from './components/CcnTable';

const ManageQuery = graphql`
  query ManageQuery {
    ccnsFromCom {
      ccnId
      shortTitle
      status {
        enabled
        scope
        offerType
      }
      idcc
    }
    env(envVar: SPID_CLIENT_URL)
  }
`;

const Manage = () => {
  return <Output>
    <QueryRenderer
      environment={environment}
      query={ManageQuery}
      render={({ error, props }) => {

        if (error) {
          return <div>{error}</div>;
        }

        return <CcnTable
          ccns={R.propOr([], 'ccnsFromCom', props)}
          spidClientUrl={R.prop('env', props)}
          loading={!props}
        />;
      }}
    />
  </Output>;
};

export default Manage;
