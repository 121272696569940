/**
 * @flow
 * @relayHash 0b0f96bb827428e2966e525dfb2dc06a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractNotesQueryVariables = {|
  contractId: string
|};
export type ContractNotesQueryResponse = {|
  +contract: ?{|
    +notes: ?$ReadOnlyArray<?{|
      +id: ?string,
      +value: ?string,
      +dates: ?{|
        +creation: ?string,
        +lastUpdate: ?string,
      |},
      +action: ?{|
        +tag: ?string,
        +status: ?string,
      |},
    |}>
  |}
|};
export type ContractNotesQuery = {|
  variables: ContractNotesQueryVariables,
  response: ContractNotesQueryResponse,
|};
*/


/*
query ContractNotesQuery(
  $contractId: ID!
) {
  contract(contractId: $contractId) {
    notes {
      id
      value
      dates {
        creation
        lastUpdate
      }
      action {
        tag
        status
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractId",
    "variableName": "contractId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Note",
  "kind": "LinkedField",
  "name": "notes",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dates",
      "kind": "LinkedField",
      "name": "dates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActionNote",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractNotesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractNotesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0b0f96bb827428e2966e525dfb2dc06a",
    "metadata": {},
    "name": "ContractNotesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc78270d55287f41c9e7860c33cfdb3f';

module.exports = node;
