import * as R from 'ramda';
import React, { useState } from 'react';
import { useBuild } from './hooks/useBuild';
import { graphql, QueryRenderer } from 'react-relay';
import { Output } from '../../components/index';
import environment from '../../Environment';
import FilesList from './components/FilesList';
import { connect } from 'react-redux';
import { commandBuildFile, resetBuildStatus } from '../../redux_actions/command';
import FileHeader from './components/FilesHeader';
import { Col } from 'antd';
import moment from 'moment';
import FilterCcn from './filters/FilterCcn';
import FilterUpdateDatePeriod from './filters/FilterUpdateDatePeriod';
import FilterStatus from './filters/FilterStatus';
import FilterSelect from './filters/FilterSelect';
import FilterSales from './filters/FilterSales';

const type = 'remuneration';

const formatDate = date => {
  if (R.isNil(date)) {
    return undefined;
  }

  return moment(date).format('YYYY-MM-DD');
};

const RemunerationQuery = graphql`
  query RemunerationQuery($type: String) {
    files(type: $type) {
      id
      filename
      uploadDate
    }
    ccns {
      shortTitle
      id
    }
  }
`;

const RemunerationFilters = ({ filters, setFilters, ccns }) => {
  const updateFilter = (key, value) => setFilters(R.assoc(key, value));
  const getFilter = key => R.prop(key, filters);

  return <Col style={{ width: '600px', margin: '20px auto' }}>
    <FilterCcn
      ccns={ccns}
      value={getFilter('ccn')}
      onChange={v => updateFilter('ccn', v)}
    />

    <FilterStatus
      value={getFilter('status')}
      onChange={v => updateFilter('status', v)}
    />

    <FilterSelect
      label="Type d'affaire"
      placeholder="Sélectionner un type d'affaire"
      value={getFilter('type')}
      onChange={v => updateFilter('type', v)}
      options={[
        { label: 'Affaire nouvelle', value: 'newContract' },
        { label: 'Avenant', value: 'amendment' }
      ]}
    />

    <FilterSales
      value={getFilter('sales')}
      onChange={v => updateFilter('sales', v)}
    />

    <FilterUpdateDatePeriod
      fromValue={getFilter('fromUpdateDate')}
      toValue={getFilter('toUpdateDate')}
      onChange={updateFilter}
    />
  </Col>;
};

const Remuneration = ({
  remunerationBuildingStatus,
  buildRemuneration,
  resetBuild
}) => {
  const [filters, setFilters] = useState({
    ccn: undefined,
    status: undefined,
    type: undefined,
    sales: undefined,
    fromUpdateDate: undefined,
    toUpdateDate: undefined
  });

  const formatFilters = R.compose(
    R.over(R.lensProp('fromUpdateDate'), formatDate),
    R.over(R.lensProp('toUpdateDate'), formatDate)
  );

  const { reload, onReload } = useBuild(remunerationBuildingStatus, resetBuild);

  return <Output>
    <QueryRenderer
      environment={environment}
      query={RemunerationQuery}
      variables={{
        type,
        reload
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        }

        return <>
          <RemunerationFilters
            filters={filters}
            setFilters={setFilters}
            ccns={R.propOr([], 'ccns', props)}
          />

          <FileHeader
            label={'Générer un fichier de rémunération'}
            onReload={onReload}
            onBuild={() => buildRemuneration(formatFilters(filters))}
            status={remunerationBuildingStatus}
          />

          <FilesList
            files={R.path(['files'])(props)}
            type={'Rémunération'}
            exts={['csv']}
          />
        </>;
      }}
    />
  </Output>;
};

export default connect(
  (state) => {

    return {
      remunerationBuildingStatus: R.pathOr('', ['command', 'remunerationBuildingStatus'])(state)
    };
  },
  (dispatch) => ({
    buildRemuneration: (filters) => dispatch(commandBuildFile(type, filters)),
    resetBuild: () => dispatch(resetBuildStatus(type))
  })
)(Remuneration);
