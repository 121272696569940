import React from 'react';
import { Select } from 'antd';
import * as R from 'ramda';
import { salesList } from '../../../data/salesList';
import Filter from '../components/Filter';

const FilterSales = ({ value, onChange }) => {
  return <Filter
    label="Structure commerciale"
  >
    <Select
      placeholder="Sélectionner une structure commerciale"
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      allowClear
    >
      {R.map(({ label, value }) => {
        return <Select.Option
          value={value}
          key={value}
        >
          {label}
        </Select.Option>;
      })(salesList)}
    </Select>
  </Filter>;
};

export default FilterSales;
