import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { Output } from '../../components/index';
import MetricsList from './components/MetricsList';
import SelectCcn from './components/SelectCcn';
import environment from '../../Environment';
import { Loading } from '../../components/helpers';

const GetCcnsQuery = graphql`
  query CcnsQuery {
    ccns {
      id
      shortTitle
    }
  }
`;

const Ccns = () => {
  const [ccn, setCcn] = useState({ id: '', shortTitle: '' });
  const onChange = (ccn) => {
    if (R.isNil(ccn)){
      setCcn({ id: '', shortTitle: '' });
    } else {
      setCcn(ccn);
    }
  };

  return <Output>
    <h4>Statistiques des contrats par ccn</h4>
    <SelectCcn onChange={onChange}/>
    <QueryRenderer
      environment={environment}
      query={GetCcnsQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        } else if (!props) return <Loading/>;

        const { ccns } = props;
        return <MetricsList ccns={R.isEmpty(R.propOr('', 'id')(ccn)) ? ccns : [ccn]}/>;
      }}
    />
  </Output>;
};

export default Ccns;
