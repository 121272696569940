import React from 'react';
import { Col, Row } from 'antd';

const Filter = ({ label, children }) => {
  return <Row grid={16}>
    <Col span={8} style={{ textAlign: 'right' }}>
      {label} :&nbsp;
    </Col>

    <Col span={16}>
      {children}
    </Col>
  </Row>;
};

export default Filter;
