/**
 * @flow
 * @relayHash b0522c191a144562921ea3633a92601a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MetricTableQueryVariables = {|
  ccnId?: ?string,
  shortTitle?: ?string,
|};
export type MetricTableQueryResponse = {|
  +metrics: ?$ReadOnlyArray<?{|
    +ccnId: ?string,
    +shortTitle: ?string,
    +groupe_adhesion: ?{|
      +manual: ?{|
        +sentForSignature: ?number,
        +closed: ?number,
        +confirmed: ?number,
        +sentToManagementSystem: ?number,
      |},
      +electronic: ?{|
        +sentForSignature: ?number,
        +closed: ?number,
        +confirmed: ?number,
        +sentToManagementSystem: ?number,
      |},
    |},
    +reseau_commercial: ?{|
      +VAD: ?{|
        +manual: ?{|
          +sentForSignature: ?number,
          +closed: ?number,
          +confirmed: ?number,
          +sentToManagementSystem: ?number,
        |},
        +electronic: ?{|
          +sentForSignature: ?number,
          +closed: ?number,
          +confirmed: ?number,
          +sentToManagementSystem: ?number,
        |},
      |},
      +Mdpro: ?{|
        +manual: ?{|
          +sentForSignature: ?number,
          +closed: ?number,
          +confirmed: ?number,
          +sentToManagementSystem: ?number,
        |},
        +electronic: ?{|
          +sentForSignature: ?number,
          +closed: ?number,
          +confirmed: ?number,
          +sentToManagementSystem: ?number,
        |},
      |},
      +MDE: ?{|
        +manual: ?{|
          +sentForSignature: ?number,
          +closed: ?number,
          +confirmed: ?number,
          +sentToManagementSystem: ?number,
        |},
        +electronic: ?{|
          +sentForSignature: ?number,
          +closed: ?number,
          +confirmed: ?number,
          +sentToManagementSystem: ?number,
        |},
      |},
    |},
  |}>
|};
export type MetricTableQuery = {|
  variables: MetricTableQueryVariables,
  response: MetricTableQueryResponse,
|};
*/


/*
query MetricTableQuery(
  $ccnId: String
  $shortTitle: String
) {
  metrics(ccnId: $ccnId, shortTitle: $shortTitle) {
    ccnId
    shortTitle
    groupe_adhesion {
      manual {
        sentForSignature
        closed
        confirmed
        sentToManagementSystem
      }
      electronic {
        sentForSignature
        closed
        confirmed
        sentToManagementSystem
      }
    }
    reseau_commercial {
      VAD {
        manual {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
        electronic {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
      }
      Mdpro {
        manual {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
        electronic {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
      }
      MDE {
        manual {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
        electronic {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shortTitle",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sentForSignature",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "closed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "confirmed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sentToManagementSystem",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MetricsProfileStatus",
    "kind": "LinkedField",
    "name": "manual",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MetricsProfileStatus",
    "kind": "LinkedField",
    "name": "electronic",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "shortTitle",
        "variableName": "shortTitle"
      }
    ],
    "concreteType": "Metrics",
    "kind": "LinkedField",
    "name": "metrics",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MetricsProfile",
        "kind": "LinkedField",
        "name": "groupe_adhesion",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CommercialNetworkMetricsProfile",
        "kind": "LinkedField",
        "name": "reseau_commercial",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetricsProfile",
            "kind": "LinkedField",
            "name": "VAD",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetricsProfile",
            "kind": "LinkedField",
            "name": "Mdpro",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MetricsProfile",
            "kind": "LinkedField",
            "name": "MDE",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetricTableQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetricTableQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "b0522c191a144562921ea3633a92601a",
    "metadata": {},
    "name": "MetricTableQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ed0565ad10d688069f33866056b4248';

module.exports = node;
