import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
  mutation DumpUsersMutation {
    dumpUsers {
      ok
      error
      url
    }
  }
`;

export default (done) => {
  const variables = {};

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error, url } = response.dumpUsers;
        done(ok, error, url);
      },
      onError: err => console.error(err),
    }
  );
};
