/**
 * @flow
 * @relayHash 4678067a4418312b234917f8ed4eee93
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CcnScopeEnum = "SPID" | "SPID_CLIENT" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type CcnUpdateEnabledMutationVariables = {|
  ccnId: string,
  scope: CcnScopeEnum,
  offerType: OfferTypeEnum,
  enabled: boolean,
|};
export type CcnUpdateEnabledMutationResponse = {|
  +ccnUpdateEnabled: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type CcnUpdateEnabledMutation = {|
  variables: CcnUpdateEnabledMutationVariables,
  response: CcnUpdateEnabledMutationResponse,
|};
*/


/*
mutation CcnUpdateEnabledMutation(
  $ccnId: String!
  $scope: CcnScopeEnum!
  $offerType: OfferTypeEnum!
  $enabled: Boolean!
) {
  ccnUpdateEnabled(ccnId: $ccnId, scope: $scope, offerType: $offerType, enabled: $enabled) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scope",
    "type": "CcnScopeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "enabled",
    "type": "Boolean!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "enabled",
        "variableName": "enabled"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "scope",
        "variableName": "scope"
      }
    ],
    "concreteType": "CcnResponse",
    "kind": "LinkedField",
    "name": "ccnUpdateEnabled",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CcnUpdateEnabledMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CcnUpdateEnabledMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "4678067a4418312b234917f8ed4eee93",
    "metadata": {},
    "name": "CcnUpdateEnabledMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c5f7f882d665c8e01aaad852371273b';

module.exports = node;
