/**
 * @flow
 * @relayHash dcabc24ecbfe7083e6c53d7bf69364b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CensorUsersMutationVariables = {|
  emails: $ReadOnlyArray<string>
|};
export type CensorUsersMutationResponse = {|
  +censorUsers: ?{|
    +ok: boolean,
    +error: ?string,
    +failedUsers: ?$ReadOnlyArray<?string>,
  |}
|};
export type CensorUsersMutation = {|
  variables: CensorUsersMutationVariables,
  response: CensorUsersMutationResponse,
|};
*/


/*
mutation CensorUsersMutation(
  $emails: [String!]!
) {
  censorUsers(emails: $emails) {
    ok
    error
    failedUsers
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "emails",
    "type": "[String!]!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "emails",
        "variableName": "emails"
      }
    ],
    "concreteType": "CensorUsersResponse",
    "kind": "LinkedField",
    "name": "censorUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "failedUsers",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CensorUsersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CensorUsersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "dcabc24ecbfe7083e6c53d7bf69364b8",
    "metadata": {},
    "name": "CensorUsersMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '908ce741c03c11a13fd2023044488313';

module.exports = node;
