import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
  mutation BuildFileMutation($type: String!, $filters: BuildFileFiltersInput, $offerType: OfferTypeEnum) {
    buildFile(type: $type, filters: $filters, offerType: $offerType) {
      ok
      token
      error
    }
  }
`;

export default ({ type, filters, offerType }, done) => {
  const variables = {
    type,
    filters,
    offerType
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, token, error } = response.buildFile;
        done(ok, token, error);
      },
      onError: err => console.error(err),
    }
  );
};
