/**
 * @flow
 * @relayHash 5ef1e858d656206afd9af2c32f46fdb5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type ContractActionMutationVariables = {|
  contractId: string,
  action?: ?string,
|};
export type ContractActionMutationResponse = {|
  +contractAction: ?{|
    +ok: boolean,
    +error: ?string,
    +contract: ?{|
      +id: ?string,
      +dates: ?{|
        +lastUpdate: ?string
      |},
      +status: ?ContractStatusEnum,
      +statusHistory: ?$ReadOnlyArray<?{|
        +id: ?string,
        +status: ?string,
        +when: ?string,
        +forced: ?boolean,
      |}>,
    |},
  |}
|};
export type ContractActionMutation = {|
  variables: ContractActionMutationVariables,
  response: ContractActionMutationResponse,
|};
*/


/*
mutation ContractActionMutation(
  $contractId: ID!
  $action: String
) {
  contractAction(contractId: $contractId, action: $action) {
    ok
    error
    contract {
      id
      dates {
        lastUpdate
      }
      status
      statusHistory {
        id
        status
        when
        forced
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "action",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "ContractResponse",
    "kind": "LinkedField",
    "name": "contractAction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Dates",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUpdate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractStatusHistory",
            "kind": "LinkedField",
            "name": "statusHistory",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "when",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forced",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractActionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractActionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "5ef1e858d656206afd9af2c32f46fdb5",
    "metadata": {},
    "name": "ContractActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a5df9d5080efe91326d6755df46fd728';

module.exports = node;
