/**
 * @flow
 * @relayHash cb707541fa13c39f0c2cb8c8ee0099a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractTagsMutationVariables = {|
  contractId: string,
  value: string,
  action?: ?string,
|};
export type ContractTagsMutationResponse = {|
  +contractTags: ?{|
    +ok: boolean,
    +error: ?string,
    +tags: ?$ReadOnlyArray<?{|
      +id: ?string,
      +value: ?string,
      +dates: ?{|
        +creation: ?string
      |},
    |}>,
  |}
|};
export type ContractTagsMutation = {|
  variables: ContractTagsMutationVariables,
  response: ContractTagsMutationResponse,
|};
*/


/*
mutation ContractTagsMutation(
  $contractId: ID!
  $value: String!
  $action: String
) {
  contractTags(contractId: $contractId, value: $value, action: $action) {
    ok
    error
    tags {
      id
      value
      dates {
        creation
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "value",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "action",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "concreteType": "TagsResponse",
    "kind": "LinkedField",
    "name": "contractTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tags",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Dates",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creation",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractTagsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractTagsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cb707541fa13c39f0c2cb8c8ee0099a9",
    "metadata": {},
    "name": "ContractTagsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ff703dccfa76a25717f0560b554c9d9';

module.exports = node;
