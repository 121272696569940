import * as R from 'ramda';

const statusList = [
  'active',
  'sentToManagementSystem',
  'confirmed',
  'sentForSignature',
  'sentForSignatureDelayed',
  'confirmedInManagementSystem',
  'confirmedInManagementSystemReopened',
  'rejectedByManagementSystem',
  'unhold',
  'closed',
  'duplicate'
];

const getContractStatus = (status) => {
  return R.propOr(status, status)({
    'active': 'Proposition en cours',
    'sentToManagementSystem': 'Mise en gestion en cours',
    'confirmed': 'Signé',
    'sendingForSignature': 'Envoi en signature en cours',
    'sentForSignature': 'Envoyé en signature',
    'sentForSignatureDelayed': 'Signature DEM TEL 24h',
    'confirmedInManagementSystem': 'Mise en gestion confirmée',
    'confirmedInManagementSystemReopened': 'Mise en gestion confirmée réouvert',
    'rejectedByManagementSystem': 'Rejeté par le système de mise en gestion',
    'unhold': 'En attente d\'un document',
    'closed': 'Fermé',
    'duplicate': 'Duplicata',
    'done': 'Mise en GED effectuée',
    'rejected': 'Mise en GED rejetée'
  });
};

const getAccountStatus = (status) => {
  return R.propOr(status, status)({
    'pending': 'En attente',
    'confirmed': 'Validé',
    'locked': 'Verrouillé'
  });
};

export {
  getAccountStatus,
  getContractStatus,
  statusList
};
