import * as R from 'ramda';
import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

const Filter = ({
  children,
  label,
  field,
  filter,
  multiple,
  set,
  updateFilter
}) => <div
  style={{
    display: 'inline-flex',
    width: '100%'
  }}
>
  <h3
    style={{
      margin: '3px 15px',
      width: '180px'
    }}
  >
    {label} :
  </h3>

  {!R.isNil(children) ? children : <Select
    style={{ width: '300px' }}
    onChange={(value) => updateFilter(field, value)}
    allowClear
    value={R.prop(field, filter)}
    mode={multiple ? 'multiple' : 'default'}
  >
    {R.map(({ label, value }) => {
      return <Option value={value} key={value}>{label}</Option>;
    })(set)}
  </Select>}
</div>;

export default Filter;
