import { useCallback, useState } from 'react';
import * as R from 'ramda';

const usePagination = () => {
  const [pagination, setPagination] = useState({ page: 1, limit: 25 });

  const onUpdatePageNumber = useCallback((page) => {
    setPagination((pagination) => R.assoc('page', page, pagination));
  });

  const onUpdatePageSize = useCallback((current, limit) => {
    setPagination({ page: 1, limit });
  });

  return {
    pagination,
    onUpdatePageSize,
    onUpdatePageNumber
  };
};

export default usePagination;
