import * as R from 'ramda';
import React from 'react';
import { Icon, List } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../../Environment';
import Loading from '../../../../components/helpers/Loading';

const UsersListQuery = graphql`
query UsersListQuery ($commercialManagerId: String) {
  users(commercialManagerId: $commercialManagerId) {
    id
    email
    extra {
      firstname
      lastname
    }
  }
}
`;

const UsersList = ({
  id,
  setUsers
}) => {
  return <div style={{ marginBottom: '20px' }}>
    <span style={{ fontWeight: 'bold' }}><Icon type="warning"/> Ces utilisateurs seront mis à jour :</span>
    <QueryRenderer
      environment={environment}
      query={UsersListQuery}
      variables={{
        commercialManagerId: id
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        } else if (!props) return <Loading/>;

        const users = R.pathOr([], ['users'])(props);
        setUsers(users);

        return <List
          style={{ backgroundColor: '#ffffff', marginTop: '5px' }}
          size="small"
          bordered
          dataSource={users}
          locale={{ emptyText: 'Aucun utilisateur à mettre à jour.' }}
          renderItem={user => <List.Item>{`${R.pathOr('', ['extra', 'firstname'])(user)} ${R.pathOr('', ['extra', 'lastname'])(user)} (${user.email})`}</List.Item>}
        />;

      }}
    />
  </div>;
};

export default UsersList;
