/**
 * @flow
 * @relayHash c8f22c9dae9a61ab89e862c16a1e0aa4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountInput = {|
  email?: ?string,
  firstname?: ?string,
  lastname?: ?string,
  managementCenter?: ?string,
  password?: ?string,
  providerEmail?: ?string,
  providers?: ?$ReadOnlyArray<?string>,
  region?: ?string,
  role?: ?string,
  sales?: ?string,
  userCode?: ?string,
|};
export type AccountUpdateMutationVariables = {|
  id: string,
  values: AccountInput,
|};
export type AccountUpdateMutationResponse = {|
  +accountUpdate: ?{|
    +ok: boolean,
    +user: ?{|
      +email: ?string,
      +roles: ?$ReadOnlyArray<?string>,
      +extra: ?{|
        +managementCenter: ?string,
        +region: ?string,
        +sales: ?string,
        +lastname: ?string,
        +firstname: ?string,
        +userCode: ?string,
      |},
    |},
    +error: ?string,
  |}
|};
export type AccountUpdateMutation = {|
  variables: AccountUpdateMutationVariables,
  response: AccountUpdateMutationResponse,
|};
*/


/*
mutation AccountUpdateMutation(
  $id: String!
  $values: AccountInput!
) {
  accountUpdate(id: $id, values: $values) {
    ok
    user {
      email
      roles
      extra {
        managementCenter
        region
        sales
        lastname
        firstname
        userCode
      }
      id
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "values",
    "type": "AccountInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "values",
    "variableName": "values"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SpidUserExtra",
  "kind": "LinkedField",
  "name": "extra",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementCenter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "region",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sales",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SpidUserResponse",
        "kind": "LinkedField",
        "name": "accountUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SpidUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SpidUserResponse",
        "kind": "LinkedField",
        "name": "accountUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SpidUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c8f22c9dae9a61ab89e862c16a1e0aa4",
    "metadata": {},
    "name": "AccountUpdateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d74f796fcf1af853c58da8e31633dc0';

module.exports = node;
