/**
 * @flow
 * @relayHash b1880d328ef87119777a8e1879274ada
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UsersListQueryVariables = {|
  commercialManagerId?: ?string
|};
export type UsersListQueryResponse = {|
  +users: ?$ReadOnlyArray<?{|
    +id: ?string,
    +email: ?string,
    +extra: ?{|
      +firstname: ?string,
      +lastname: ?string,
    |},
  |}>
|};
export type UsersListQuery = {|
  variables: UsersListQueryVariables,
  response: UsersListQueryResponse,
|};
*/


/*
query UsersListQuery(
  $commercialManagerId: String
) {
  users(commercialManagerId: $commercialManagerId) {
    id
    email
    extra {
      firstname
      lastname
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "commercialManagerId",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commercialManagerId",
        "variableName": "commercialManagerId"
      }
    ],
    "concreteType": "SpidUser",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SpidUserExtra",
        "kind": "LinkedField",
        "name": "extra",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersListQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b1880d328ef87119777a8e1879274ada",
    "metadata": {},
    "name": "UsersListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c8be2716f8105145be83c0cfbdad83d';

module.exports = node;
