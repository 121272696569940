import React from 'react';
import { Output } from '../../components/index';
import { Alert, Button, Input, message } from 'antd';
import * as R from 'ramda';
import LockUsersModal from './components/LockUsersModal';
import CensorUsersMutation from '../../mutations/CensorUsersMutation';

const formatUsers = R.compose(
  R.reject(R.isEmpty),
  R.map(
    R.compose(
      R.trim,
      R.toLower
    )
  ),
  R.split('\n'),
  R.defaultTo('')
);

const useLock = () => {
  const [usersToLock, setUsersToLock] = React.useState([]);
  const [rawUsers, setRawUsers] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [failedUsers, setFailedUsers] = React.useState([]);

  const updateUsersToLock = e => {
    setRawUsers(e.target.value);
    setUsersToLock(formatUsers(e.target.value));
  };

  const onLock = () => {
    setLoading(true);
    setFailedUsers([]);

    CensorUsersMutation({ emails: usersToLock }, (ok, error, failedUsers) => {
      setLoading(false);

      if (ok && !error) {
        setShowModal(false);
        setUsersToLock([]);
        setRawUsers('');
        setFailedUsers(failedUsers);
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.');
      }
    });
  };

  return {
    numberOfUsersToLock: R.length(usersToLock),
    updateUsersToLock,
    showModal,
    setShowModal,
    loading,
    onLock,
    rawUsers,
    failedUsers
  };
};

const Lock = () => {
  const {
    numberOfUsersToLock,
    updateUsersToLock,
    showModal,
    setShowModal,
    loading,
    onLock,
    rawUsers,
    failedUsers
  } = useLock();

  return <Output>
    <LockUsersModal
      loading={loading}
      onCancel={() => setShowModal(false)}
      onOk={onLock}
      visible={showModal}
      numberOfUsersToLock={numberOfUsersToLock}
    />

    <h4>{'Désactiver des utilisateurs :'}</h4>

    <Input.TextArea
      autoSize={{ minRows: 10, maxRows: 15 }}
      onChange={updateUsersToLock}
      value={rawUsers}
      placeholder={'Mettre une adresse email par ligne, exemple :\njean@ag2rlamondiale.fr\nrobert@ag2rlamondiale.fr'}
    />

    <Button
      type="primary"
      disabled={numberOfUsersToLock < 1 || loading}
      onClick={() => setShowModal(true)}
      style={{
        display: 'block',
        margin: '20px auto 0'
      }}
    >
      Désactiver les utilisateurs
    </Button>

    {!R.isEmpty(failedUsers) && <Alert
      showIcon
      type="error"
      style={{ marginTop: '20px' }}
      message="Erreur"
      description={<>
        La désactivation a échoué pour les utilisateurs suivants :
        <ul>
          {R.map(email => {
            return <li
              key={`failed-${email}`}
            >
              {email}
            </li>;
          }, failedUsers)}
        </ul>
      </>}
    />}

  </Output>;
};

export default Lock;
