import * as R from 'ramda';
import React from 'react';

class List extends React.Component {
  constructor (loadingMoreComponent = undefined, loadMoreComponent = undefined) {
    super();

    this.component = {
      loadingMore: loadingMoreComponent,
      loadMore: loadMoreComponent
    };
    this.state = {
      loading: false
    };
  }

  loadMore () {
    if (!this.props.relay.hasMore()) {
      return;
    } else if (this.props.relay.isLoading()) {
      return;
    }
    this.setState({ loading: true });
    this.props.relay.loadMore(this.props.count || List.count, () => {
      this.setState({ loading: false });
    });
  }

  renderMore () {
    if (!this.props.relay.hasMore()) {
      return null;
    } else if (this.state.loading && !R.isNil(this.component.loadingMore)) {
      return React.createElement(this.component.loadingMore);
    } else if (!R.isNil(this.component.loadMore)) {
      return React.createElement(
        this.component.loadMore,
        {
          onClick: this.loadMore
        }
      );
    } else return null;
  }
}

List.count = 5;
export default List;
