import React from 'react';

const Count = ({ count, singular, plural }) =>
  <div style={{ width: '100%', textAlign: 'center' }}>
    <span style={{ fontSize: '1.3em' }}>
      <span style={{ fontWeight: 'bold', color: 'black' }}>{count}</span> {count > 1 ? plural : singular}
    </span>
  </div>;

export default Count;
