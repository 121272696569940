const status = [
  { value: 'active', label: 'Proposition en cours' },
  { value: 'sentToManagementSystem', label: 'Mise en gestion en cours' },
  { value: 'confirmed', label: 'Signé' },
  { value: 'sendingForSignature', label: 'Envoi en signature en cours' },
  { value: 'sentForSignature', label: 'Envoyé en signature' },
  { value: 'sentForSignatureDelayed', label: 'Signature DEM TEL 24h' },
  { value: 'confirmedInManagementSystem', label: 'Mise en gestion confirmée' },
  { value: 'confirmedInManagementSystemReopened', label: 'Mise en gestion confirmée réouvert' },
  { value: 'rejectedByManagementSystem', label: 'Rejeté par le système de mise en gestion' },
  { value: 'unhold', label: 'En attente d\'un document' },
  { value: 'closed', label: 'Fermé' },
  { value: 'duplicate', label: 'Duplicata généré' }
];

const signatureTypes = [
  { value: 'manual', label: 'Manuelle' },
  { value: 'electronic', label: 'Électronique' }
];

const roles = [
  { value: 'groupe_adhesion', label: 'Groupe adhésion' },
  { value: 'reseau_commercial', label: 'Réseau commercial' }
];

const sales = [
  { value: 'Mdpro', label: 'Mdpro' },
  { value: 'MDE', label: 'MDE' },
  { value: 'VAD', label: 'VAD' },
  { value: 'VADC', label: 'VADC' }
];

const yesNo = [
  { value: true, label: 'Oui' },
  { value: false, label: 'Non' }
];

const offerTypes = [
  { value: 'PREVOYANCE', label: 'Prévoyance' },
  { value: 'SANTE', label: 'Santé' },
];

export {
  roles,
  sales,
  signatureTypes,
  status,
  yesNo,
  offerTypes
};
