import React from 'react';
import { Modal, Button, Alert, Popconfirm } from 'antd';

const LockUsersModal = ({
  visible = false,
  loading = false,
  onOk,
  onCancel,
  numberOfUsersToLock
}) => {
  return <Modal
    visible={visible}
    title="Confirmer"
    onOk={onOk}
    onCancel={onCancel}
    footer={[
      <Button
        key="back-button"
        type="danger"
        onClick={onCancel}
      >
        Annuler
      </Button>,
      <Popconfirm
        key="lock-button"
        title="Êtes-vous sûr de vouloir désactiver ces utilisateurs ?"
        onConfirm={onOk}
        onCancel={onCancel}
        okText="Oui"
        cancelText="Non"
      >
        <Button
          type="primary"
          loading={loading}
        >
          Désactiver
        </Button>
      </Popconfirm>,
    ]}
  >
    <Alert
      message="Attention"
      description={<>
        <b>{numberOfUsersToLock}</b> {numberOfUsersToLock > 1 ? 'utilisateurs vont être désactivés.' : 'utilisateur va être désactivé.'}
        <br/>
        Les noms, prénoms, adresses mail et toutes les informations personnelles seront supprimées. Cette action est irréversible.
      </>}
      type="warning"
      showIcon
    />
  </Modal>;
};

export default LockUsersModal;
