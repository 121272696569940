import * as R from 'ramda';
import React, { useState } from 'react';
import AccountManageRoleMutation from '../../../../mutations/AccountManageRoleMutation';
import AccountUpdateStatusMutation from '../../../../mutations/AccountUpdateStatusMutation';
import AccountUpdateCommercialManagerMutation from '../../../../mutations/AccountUpdateCommercialManagerMutation';
import { Button, Icon, Input, message, Row, Switch } from 'antd';
import AccountUpdateRegionalManagerMutation from '../../../../mutations/AccountUpdateRegionalManagerMutation';

const displaySuccess = msg => message.success(msg);
const displayError = () => message.error('Une erreur est survenue, veuillez réessayer.');

const getManagerError = error => R.propOr('Une erreur est survenue.', error)({
  'USER_NOT_FOUND': 'Cet utilisateur n\'existe pas.',
  'USER_IS_NOT_MANAGER': 'Cet utilisateur n\'est pas un manager.',
  'USER_IS_NOT_REGIONAL_MANAGER': 'Cet utilisateur n\'est pas un manager régional.',
  'USER_IS_NOT_COMMERCIAL_NETWORK': 'Cet utilisateur ne fait pas partie du réseau commercial.',
  'USER_IS_NOT_SAME_ROLE': 'Ce manager n\'a pas le même rôle que l\'utilisateur.'
});

const useEditAccount = user => {
  const [state, setState] = useState({
    commercialManager: R.pathOr('', ['extra', 'commercialManagerEmail'], user),
    regionalManager: R.pathOr('', ['extra', 'regionalManagerEmail'], user),
    managerError: undefined
  });

  const userHasRole = role => R.propSatisfies(R.includes(role), 'roles')(user);
  const userStatusIs = status => R.propEq('status', status)(user);

  const updateRole = role => {
    const action = userHasRole(role) ? 'drop' : 'add';

    const update = () => {
      AccountManageRoleMutation({ id: user.id, role, email: user.email, action }, (ok, error) => {
        if (ok && !error) {
          displaySuccess('Le rôle de l\'utilisateur a bien été mis à jour.');
        } else {
          displayError();
        }
      });
    };

    if (role === 'manager' && userHasRole('regional_manager') && action === 'drop') {
      AccountManageRoleMutation({ id: user.id, role: 'regional_manager', email: user.email, action }, (ok, error) => {
        if (ok && !error) {
          update();
        } else {
          displayError();
        }
      });
    } else {
      update();
    }
  };

  const updateStatus = action => {
    AccountUpdateStatusMutation({ id: user.id, email: user.email, action }, (ok, error) => {
      if (ok && !error) {
        displaySuccess('Le statut de l\'utilisateur a bien été mis à jour.');
      } else {
        displayError();
      }
    });
  };

  const updateCommercialManager = () => {
    setState(R.assoc('managerError', undefined));

    AccountUpdateCommercialManagerMutation({ id: user.id, email: user.email, commercialManagerEmail: state.commercialManager, update: true }, (ok, error) => {
      if (ok && !error) {
        displaySuccess('Le manager commercial de l\'utilisateur a bien été mis à jour.');
      } else {
        displayError();
        setState(R.assoc('managerError', getManagerError(error)));
      }
    });
  };

  const updateRegionalManager = () => {
    setState(R.assoc('managerError', undefined));

    AccountUpdateRegionalManagerMutation({ id: user.id, email: user.email, regionalManagerEmail: state.regionalManager, update: true }, (ok, error) => {
      if (ok && !error) {
        displaySuccess('Le manager régional de l\'utilisateur a bien été mis à jour.');
      } else {
        displayError();
        setState(R.assoc('managerError', getManagerError(error)));
      }
    });
  };

  return {
    state,
    setState,
    userHasRole,
    userStatusIs,
    updateCommercialManager,
    updateRegionalManager,
    updateRole,
    updateStatus
  };
};

const AccountEdition = ({ user }) => {
  const {
    state,
    setState,
    userHasRole,
    userStatusIs,
    updateCommercialManager,
    updateRegionalManager,
    updateRole,
    updateStatus
  } = useEditAccount(user);

  return <div>

    {!userHasRole('apporteur') && <Row>
      <b>Rôle manager : </b>
      <Switch
        checked={userHasRole('manager')}
        onChange={() => updateRole('manager')}
      />
    </Row>}

    {userHasRole('manager') && <Row>
      <b>Rôle manager régional : </b>
      <Switch
        checked={userHasRole('regional_manager')}
        onChange={() => updateRole('regional_manager')}
      />
    </Row>}

    {!userStatusIs('pending') && <Row>
      <b>Compte verrouillé : </b>
      <Switch
        checked={userStatusIs('locked')}
        onChange={() => updateStatus(userStatusIs('locked') ? 'unlock' : 'lock')}
      />
    </Row>}

    {userHasRole('manager') && !userHasRole('regional_manager') && <Row>
      <b>Manager régional de cet utilisateur : </b>

      <Input
        prefix={<Icon type="mail"/>}
        value={state.regionalManager}
        placeholder="Adresse mail du manager"
        onChange={e => setState(R.assoc('regionalManager', e.target.value))}
        style={{ width: '350px', marginRight: '15px' }}
      />

      <Button
        type="primary"
        disabled={R.pathEq(['extra', 'regionalManagerEmail'], state.regionalManager)(user) || R.isNil(state.regionalManager)}
        onClick={() => updateRegionalManager()}
        size="small"
      >
        Enregistrer
      </Button>

      {!R.isNil(state.managerError) && <span style={{ color: 'red' }}>{state.managerError}</span>}
    </Row>}

    {!userHasRole('manager') && userHasRole('reseau_commercial') && <Row>
      <b>Manager de cet utilisateur : </b>

      <Input
        prefix={<Icon type="mail"/>}
        value={state.commercialManager}
        placeholder="Adresse mail du manager"
        onChange={e => setState(R.assoc('commercialManager', e.target.value))}
        style={{ width: '350px', marginRight: '15px' }}
      />

      <Button
        type="primary"
        disabled={R.pathEq(['extra', 'commercialManagerEmail'], state.commercialManager)(user) || R.isNil(state.commercialManager)}
        onClick={() => updateCommercialManager()}
        size="small"
      >
        Enregistrer
      </Button>

      {!R.isNil(state.managerError) && <span style={{ color: 'red' }}>{state.managerError}</span>}
    </Row>}

    {userStatusIs('pending') && (
      <Button onClick={() => updateStatus('confirm')}>
        Valider le compte
      </Button>
    )}

  </div>;
};

export default AccountEdition;
