/**
 * @flow
 * @relayHash f1d1ea262cb6f4922da14d008e88d993
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserRoleActionEnum = "add" | "drop" | "%future added value";
export type UserRoleEnum = "admin" | "apporteur" | "groupe_adhesion" | "manager" | "national_manager" | "national_manager_vadc" | "regional_manager" | "reseau_commercial" | "%future added value";
export type AccountManageRoleMutationVariables = {|
  role: UserRoleEnum,
  email: string,
  action: UserRoleActionEnum,
|};
export type AccountManageRoleMutationResponse = {|
  +accountManageRole: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AccountManageRoleMutation = {|
  variables: AccountManageRoleMutationVariables,
  response: AccountManageRoleMutationResponse,
|};
*/


/*
mutation AccountManageRoleMutation(
  $role: UserRoleEnum!
  $email: String!
  $action: UserRoleActionEnum!
) {
  accountManageRole(role: $role, email: $email, action: $action) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "role",
    "type": "UserRoleEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "action",
    "type": "UserRoleActionEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "accountManageRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountManageRoleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountManageRoleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f1d1ea262cb6f4922da14d008e88d993",
    "metadata": {},
    "name": "AccountManageRoleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a99e59be09d711f1d5ae13d6b56ebb53';

module.exports = node;
