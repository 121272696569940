/**
 * @flow
 * @relayHash b47485931286d7ee6a7c75ea7fec9b49
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type UserRoleEnum = "admin" | "apporteur" | "groupe_adhesion" | "manager" | "national_manager" | "national_manager_vadc" | "regional_manager" | "reseau_commercial" | "%future added value";
export type BuildFileFiltersInput = {|
  ccn?: ?string,
  ccnList?: ?$ReadOnlyArray<?string>,
  fromUpdateDate?: ?string,
  hasPricedOutstandings?: ?boolean,
  managementCenter?: ?string,
  managementCentersList?: ?$ReadOnlyArray<?string>,
  medicalCheck?: ?boolean,
  medicalCheckOverprice?: ?boolean,
  offerType?: ?OfferTypeEnum,
  region?: ?string,
  regionsList?: ?$ReadOnlyArray<?string>,
  role?: ?UserRoleEnum,
  sales?: ?string,
  status?: ?ContractStatusEnum,
  statusList?: ?$ReadOnlyArray<?ContractStatusEnum>,
  toUpdateDate?: ?string,
  type?: ?string,
  typesList?: ?$ReadOnlyArray<?string>,
  user?: ?string,
|};
export type BuildFileMutationVariables = {|
  type: string,
  filters?: ?BuildFileFiltersInput,
  offerType?: ?OfferTypeEnum,
|};
export type BuildFileMutationResponse = {|
  +buildFile: ?{|
    +ok: boolean,
    +token: ?string,
    +error: ?string,
  |}
|};
export type BuildFileMutation = {|
  variables: BuildFileMutationVariables,
  response: BuildFileMutationResponse,
|};
*/


/*
mutation BuildFileMutation(
  $type: String!
  $filters: BuildFileFiltersInput
  $offerType: OfferTypeEnum
) {
  buildFile(type: $type, filters: $filters, offerType: $offerType) {
    ok
    token
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters",
    "type": "BuildFileFiltersInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "TokenResponse",
    "kind": "LinkedField",
    "name": "buildFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildFileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildFileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b47485931286d7ee6a7c75ea7fec9b49",
    "metadata": {},
    "name": "BuildFileMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4548cf5270e77b10f93a0a3b72fa0daf';

module.exports = node;
