import * as R from 'ramda';

import React, { useState, useEffect } from 'react';

import {
  Alert,
  Button,
  Input,
  Row,
  Col
} from 'antd';

import UpdateContractNumbersMutation from '../../mutations/UpdateContractNumbersMutation';

const useEditContractNumbers = (contractId, contractNumbers) => {

  const contractNumberIsValid = (cn) => {
    return R.length(cn) === 8;
  };

  const contractNumbersAreValid = (cns) => {
    return R.reduce((r, v) => R.and(contractNumberIsValid(v), r), true, cns) && !R.equals(cns, contractNumbers);
  };

  const [values, setValues] = useState(contractNumbers);
  const [canSave, setCanSave] = useState(contractNumbersAreValid(contractNumbers));

  const onUpdateContractNumber = (index, value) => {
    const updateValues = R.update(index, value, values);
    setValues(updateValues);
    setCanSave(contractNumbersAreValid(updateValues));
  };

  const onSaveContractNumbers = () => {
    UpdateContractNumbersMutation(contractId, { contractNumbers: values }, () => {

    });
  };

  useEffect(() => {
    setCanSave(contractNumbersAreValid(contractNumbers));
  }, [contractNumbers]);

  return {
    onUpdateContractNumber,
    onSaveContractNumbers,
    canSave,
    values
  };
};

const EditContractNumbers = ({
  contractId,
  contractNumbers
}) => {
  const {
    canSave,
    onUpdateContractNumber,
    values,
    onSaveContractNumbers
  } = useEditContractNumbers(contractId, contractNumbers);

  return (
    <div>
      {
        R.isEmpty(values) && <Alert type="warning" message="Pas de numéro de contrat défini"/>
      }
      {
        !R.isEmpty(values) && (
          <div>
            <h4>{'Numéro(s) de contrat'}</h4>
            <Row gutter={8}>

              {
                values.map((value, index) => (
                  <Col key={index} span={2}>
                    <Input
                      size="small"
                      value={value}
                      placeholder={contractNumbers[index]}
                      onChange={(e) => onUpdateContractNumber(index, e.target.value)}
                    />
                    &nbsp;
                  </Col>
                ))
              }
              <Button size="small" disabled={!canSave} onClick={onSaveContractNumbers}>Enregistrer</Button>
            </Row>
          </div>
        )
      }
    </div>
  );
};

export default EditContractNumbers;
