import { either, isNil, isEmpty, pathOr, propOr, defaultTo, compose, when, always, propEq } from 'ramda';

const isNilOrEmpty = either(isNil, isEmpty);

const getAmendmentInfos = proposition => {
  const protectionAmendment = propOr({}, 'amendment', proposition);
  const healthAmendment = pathOr({}, ['health', 'amendment'], proposition);

  const amendment = compose(
    defaultTo({}),
    when(
      either(
        isNilOrEmpty,
        propEq('isAmendment', false)
      ),
      always(healthAmendment)
    )
  )(protectionAmendment);

  const isProtectionAmendment = propEq('isAmendment', true, protectionAmendment);
  const isHealthAmendment = propEq('isAmendment', true, healthAmendment);

  const courseIsAmendment = isProtectionAmendment || isHealthAmendment;
  const isKnownAmendment = !isNil(amendment.formerContractId);

  return {
    amendment,
    courseIsAmendment,
    isProtectionAmendment,
    isHealthAmendment,
    isKnownAmendment
  };
};

export default getAmendmentInfos;
