/**
 * @flow
 * @relayHash 62ec4041af61f3ccc69c4818613439d4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateContractBuilderMutationVariables = {|
  propositionId: string,
  email: string,
|};
export type UpdateContractBuilderMutationResponse = {|
  +updateContractBuilder: ?{|
    +ok: boolean,
    +error: ?string,
    +contract: ?{|
      +id: ?string,
      +user: ?{|
        +name: ?string,
        +roles: ?$ReadOnlyArray<?string>,
        +email: ?string,
      |},
    |},
  |}
|};
export type UpdateContractBuilderMutation = {|
  variables: UpdateContractBuilderMutationVariables,
  response: UpdateContractBuilderMutationResponse,
|};
*/


/*
mutation UpdateContractBuilderMutation(
  $propositionId: ID!
  $email: String!
) {
  updateContractBuilder(propositionId: $propositionId, email: $email) {
    ok
    error
    contract {
      id
      user {
        name
        roles
        email
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      }
    ],
    "concreteType": "ContractResponse",
    "kind": "LinkedField",
    "name": "updateContractBuilder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Contract",
        "kind": "LinkedField",
        "name": "contract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateContractBuilderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateContractBuilderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "62ec4041af61f3ccc69c4818613439d4",
    "metadata": {},
    "name": "UpdateContractBuilderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee1d01bd6384c2421c20786ba500e351';

module.exports = node;
