/**
 * @flow
 * @relayHash 989fc59544772ef57db4b2590fef7d4f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FileInput = {|
  draftId?: ?string,
  imported?: ?boolean,
  index?: ?number,
  mimeType?: ?string,
  originalName?: ?string,
  size?: ?number,
|};
export type UpdateCommercialCodesMutationVariables = {|
  file: FileInput,
  src: string,
|};
export type UpdateCommercialCodesMutationResponse = {|
  +updateCommercialCodes: ?{|
    +ok: boolean,
    +error: ?string,
    +usersNotFound: ?$ReadOnlyArray<?string>,
  |}
|};
export type UpdateCommercialCodesMutation = {|
  variables: UpdateCommercialCodesMutationVariables,
  response: UpdateCommercialCodesMutationResponse,
|};
*/


/*
mutation UpdateCommercialCodesMutation(
  $file: FileInput!
  $src: String!
) {
  updateCommercialCodes(file: $file, src: $src) {
    ok
    error
    usersNotFound
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file",
    "type": "FileInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "src",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "file",
        "variableName": "file"
      },
      {
        "kind": "Variable",
        "name": "src",
        "variableName": "src"
      }
    ],
    "concreteType": "UpdateCommercialCodesMutationResponse",
    "kind": "LinkedField",
    "name": "updateCommercialCodes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usersNotFound",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCommercialCodesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCommercialCodesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "989fc59544772ef57db4b2590fef7d4f",
    "metadata": {},
    "name": "UpdateCommercialCodesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c3619559145e16331b21baacabd056f';

module.exports = node;
