import React, { useState } from 'react';
import { Select } from 'antd';
import environment from '../../../Environment';
import { Loading } from '../../../components/helpers';
import { graphql, QueryRenderer } from 'react-relay';
import SelectCcn from '../../metrics/components/SelectCcn';
import {
  assoc,
  isNil,
  map,
  propOr,
  filter,
  compose,
  whereEq,
  propSatisfies,
  toLower,
  path,
  applySpec,
  pathOr, includes
} from 'ramda';
import { offerTypes } from '../../metrics/components/sets';

const Option = Select.Option;

const SelectDocumentQuery = graphql`
query SelectDocumentQuery {
  documents {
    id
    fileId
    version
    folder
    ccnId
    offerType
  }
}
`;

const SelectDocuments = ({
  onChange,
  setLoaded
}) => {
  const [filters, setFilters] = useState({
    ccn: undefined,
    offerType: undefined,
    version: undefined
  });

  const {
    ccnId,
    availableOfferTypes,
    versions
  } = applySpec({
    ccnId: path(['ccn', 'id']),
    availableOfferTypes: pathOr([], ['ccn', 'offerTypes']),
    versions: pathOr([], ['ccn', filters.offerType, 'versions'])
  })(filters);

  const updateFilter = (k, v) => {
    setFilters(assoc(k, v));
    onChange(undefined);
  };
  const filterIsDefined = k => !propSatisfies(isNil, k)(filters);

  return <QueryRenderer
    environment={environment}
    query={SelectDocumentQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error}</div>;
      } else if (!props) {
        setLoaded(false);
        return <Loading/>;
      }

      setLoaded(true);

      const documents = compose(
        filter(whereEq({
          ccnId,
          offerType: filters.offerType,
          version: filters.version
        })),
        propOr([], 'documents')
      )(props);

      return <div style={{ width: 600 }}>
        <SelectCcn
          onChange={ccn => {
            updateFilter('ccn', ccn);
            updateFilter('offerType', undefined);
            updateFilter('version', undefined);
          }}
          style={{ marginBottom: 0 }}
        />

        {filterIsDefined('ccn') && <>
          <Select
            placeholder="Sélectionner un type d'offre"
            onChange={v => {
              updateFilter('offerType', v);
              updateFilter('version', undefined);
            }}
            value={filters.offerType}
            allowClear
            style={{ width: '100%' }}
          >
            {map(offerType => {
              if (!includes(offerType.value, availableOfferTypes)) {
                return null;
              }

              return <Option
                key={offerType.value}
                value={offerType.value}
              >
                {offerType.label}
              </Option>;
            })(offerTypes)}
          </Select>

          {filterIsDefined('offerType') && <>
            <Select
              placeholder="Sélectionner une version"
              onChange={v => {
                updateFilter('version', v);
              }}
              value={filters.version}
              allowClear
              style={{ width: '100%', marginBottom: '15px' }}
            >
              {map(version => {
                return <Option
                  key={version.version}
                  value={version.version}
                >
                  version {version.version} {isNil(version.date) ? '' : `(${version.date})`}
                </Option>;
              })(versions)}
            </Select>

            {filterIsDefined('version') && <Select
              allowClear
              showSearch
              placeholder="ID du document"
              onChange={(document) => onChange(isNil(document) ? undefined : JSON.parse(document))}
              notFoundContent={'Aucun document trouvé'}
              style={{ width: '100%' }}>
              {map(document => {
                const documentId = `${document.folder}/${toLower(document.offerType)}/${document.fileId}`;

                return <Option
                  key={documentId}
                  value={JSON.stringify(document)}
                >
                  {`${document.fileId}.docx`}
                </Option>;
              })(documents)}
            </Select>}
          </>}
        </>}
      </div>;
    }}
  />;
};

export default SelectDocuments;
