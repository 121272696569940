import React from 'react';
import Filter from '../components/Filter';
import { Select } from 'antd';
import * as R from 'ramda';
import { getContractStatus, statusList } from '../../../lib/Status';

const FilterStatus = ({
  value,
  onChange,
  multi = false
}) => {
  return <Filter
    label="Statut"
  >
    <Select
      placeholder="Sélectionner un statut"
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      allowClear
      mode={multi ? 'multiple' : 'default'}
    >
      {R.map(status => {
        return <Select.Option
          key={status}
          value={status}
        >
          {getContractStatus(status)}
        </Select.Option>;
      })(statusList)}
    </Select>
  </Filter>;
};

export default FilterStatus;
