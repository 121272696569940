/**
 * @flow
 * @relayHash a0cf91b680463eaea0c2644f8a85d727
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type ContractFilterInput = {|
  formerContractId?: ?string,
  search?: ?string,
  status?: ?string,
|};
export type ContractAmendmentsQueryVariables = {|
  filter?: ?ContractFilterInput
|};
export type ContractAmendmentsQueryResponse = {|
  +contracts: ?$ReadOnlyArray<?{|
    +id: ?string,
    +propositionNumber: ?number,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +status: ?ContractStatusEnum,
    +companyInformation: ?{|
      +contactLastname: ?string,
      +contactFirstname: ?string,
      +contactEmail: ?string,
    |},
    +user: ?{|
      +roles: ?$ReadOnlyArray<?string>,
      +name: ?string,
      +email: ?string,
    |},
    +signature: ?{|
      +type: ?string,
      +id: ?string,
    |},
    +contractNumbers: ?$ReadOnlyArray<?string>,
  |}>
|};
export type ContractAmendmentsQuery = {|
  variables: ContractAmendmentsQueryVariables,
  response: ContractAmendmentsQueryResponse,
|};
*/


/*
query ContractAmendmentsQuery(
  $filter: ContractFilterInput
) {
  contracts(filter: $filter) {
    id
    propositionNumber
    dates {
      creation
      lastUpdate
    }
    status
    companyInformation {
      contactLastname
      contactFirstname
      contactEmail
    }
    user {
      roles
      name
      email
    }
    signature {
      type
      id
    }
    contractNumbers
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ContractFilterInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "Contract",
    "kind": "LinkedField",
    "name": "contracts",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propositionNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Dates",
        "kind": "LinkedField",
        "name": "dates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyInformation",
        "kind": "LinkedField",
        "name": "companyInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactLastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactFirstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractSignature",
        "kind": "LinkedField",
        "name": "signature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contractNumbers",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractAmendmentsQuery",
    "selections": (v2/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractAmendmentsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "a0cf91b680463eaea0c2644f8a85d727",
    "metadata": {},
    "name": "ContractAmendmentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74d3f8e6915659c6b8c9ddc2de8f4103';

module.exports = node;
