import environment from '../../Environment';

import * as R from 'ramda';

import React, { useState } from 'react';

import { Output } from '../../components/index';

import DumpUsersMutation from '../../mutations/DumpUsersMutation';
import UsersToIgnoreEditor from './components/UsersToIgnoreEditor';
import { graphql, QueryRenderer } from 'react-relay';

import {
  Button, message,
  Spin
} from 'antd';

const DumpQuery = graphql`
  query DumpQuery {
    usersToIgnore
  } 
`;

const useDump = () => {

  const [working, setWorking] = useState(false);
  const [url, setUrl] = useState(null);

  const onDump = () => {
    setWorking(true);
    DumpUsersMutation((ok, error, url) => {
      setWorking(false);

      if (ok && !error) {
        setUrl(url);
      } else {
        message.error('Une erreur est survenue, veuillez réessayer');
      }
    });
  };

  return {
    working,
    url,
    onDump
  };
};

const Dump = () => {

  const {
    working,
    url,
    onDump
  } = useDump();

  return (
    <Output>
      <h4>{'Export des utilisateurs vers un fichier csv'}</h4>
      <QueryRenderer
        environment={environment}
        query={DumpQuery}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.stack}</div>;
          } else if (!props) return <Spin/>;

          const { usersToIgnore } = props;

          return <div>
            <div style={{ marginBottom: '40px' }}>
              <Button
                disabled={working}
                onClick={onDump}
              >
                {'Générer'}
              </Button>
                  &nbsp;&nbsp;
              { !working && !R.isNil(url) && <a href={url} >télécharger</a>}
              { working && <Spin/>}
            </div>

            <h4>{'Utilisateurs qui ne doivent pas apparaître dans le fichier :'}</h4>
            <UsersToIgnoreEditor value={usersToIgnore}/>
          </div>;
        }}
      />
    </Output>
  );
};

export default Dump;
