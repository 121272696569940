/**
 * @flow
 * @relayHash 456fd8de70d686598610e497cbc7322f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BuildFileStatusMutationVariables = {|
  token: string
|};
export type BuildFileStatusMutationResponse = {|
  +buildFileStatus: ?{|
    +ok: boolean,
    +token: ?string,
    +status: ?string,
    +error: ?string,
  |}
|};
export type BuildFileStatusMutation = {|
  variables: BuildFileStatusMutationVariables,
  response: BuildFileStatusMutationResponse,
|};
*/


/*
mutation BuildFileStatusMutation(
  $token: String!
) {
  buildFileStatus(token: $token) {
    ok
    token
    status
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "TokenStatusResponse",
    "kind": "LinkedField",
    "name": "buildFileStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuildFileStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuildFileStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "456fd8de70d686598610e497cbc7322f",
    "metadata": {},
    "name": "BuildFileStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16f29c2d334a6a17123b905816e936a5';

module.exports = node;
