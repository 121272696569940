import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

import ContractRecord from './records/ContractRecord';

const mutation = graphql`
mutation ContractTagsMutation($contractId: ID!, $value: String!, $action: String) {
  contractTags(contractId: $contractId, value: $value, action: $action) { 
    ok
    error
    tags {
      id
      value
      dates {
        creation
      }
    }
  }
}
`;

export default (contractId, { action, value }, done) => {
  const variables = {
    action,
    contractId,
    value
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { contractTags: { tags } }) => {
        const contractRecord = ContractRecord(proxyStore);
        contractRecord.updateTags(contractId, tags);
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.contractTags;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
