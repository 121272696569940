/**
 * @flow
 * @relayHash 15463b25c103a1583c0618a682f40bda
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type SelectDocumentQueryVariables = {||};
export type SelectDocumentQueryResponse = {|
  +documents: ?$ReadOnlyArray<?{|
    +id: ?string,
    +fileId: ?string,
    +version: ?string,
    +folder: ?string,
    +ccnId: ?string,
    +offerType: ?OfferTypeEnum,
  |}>
|};
export type SelectDocumentQuery = {|
  variables: SelectDocumentQueryVariables,
  response: SelectDocumentQueryResponse,
|};
*/


/*
query SelectDocumentQuery {
  documents {
    id
    fileId
    version
    folder
    ccnId
    offerType
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "documents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fileId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "folder",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ccnId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectDocumentQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectDocumentQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "15463b25c103a1583c0618a682f40bda",
    "metadata": {},
    "name": "SelectDocumentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4b07b8987ecaaddd8d716f09494a620';

module.exports = node;
