import * as R from 'ramda';

const ContractRecord = (proxyStore) => {

  const createTag = ({ id, value, dates }) => {
    let record = proxyStore.get(id, 'Tag');
    if (R.isNil(record)) {
      record = proxyStore.create(`tag-${dates.creation}`, 'Tag');
      record.setValue(value, 'value');
      record.setLinkedRecord(createDates(dates), 'dates');
    }
    return record;
  };

  const createDates = ({ creation, lastUpdate }) => {
    const id = `dates-${creation}`;
    let record = proxyStore.get(id, 'Dates');
    if (R.isNil(record)) {
      record = proxyStore.create(id, 'Dates');
      record.setValue(creation, 'cretion');
      record.setValue(lastUpdate, 'lastUpdate');
    }
    return record;
  };

  const updateTags = (contractId, tags) => {
    tags = R.map(tag => createTag(tag))(tags);
    const link = proxyStore.get(contractId);
    link.setLinkedRecords(tags, 'tags');
  };

  return ({
    updateTags
  });
};

export default ContractRecord;

