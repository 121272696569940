/**
 * @flow
 * @relayHash 49c006ab2b24d68e6ff3f7acead6a8eb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ElectronicSignatureDetailsQueryVariables = {|
  id: string
|};
export type ElectronicSignatureDetailsQueryResponse = {|
  +electronicSignature: ?{|
    +id: ?string,
    +status: ?string,
    +creationDate: ?string,
    +signerInfo: ?{|
      +actionDate: ?string,
      +email: ?string,
      +lastName: ?string,
      +firstName: ?string,
      +status: ?string,
      +url: ?string,
    |},
  |}
|};
export type ElectronicSignatureDetailsQuery = {|
  variables: ElectronicSignatureDetailsQueryVariables,
  response: ElectronicSignatureDetailsQueryResponse,
|};
*/


/*
query ElectronicSignatureDetailsQuery(
  $id: ID!
) {
  electronicSignature(id: $id) {
    id
    status
    creationDate
    signerInfo {
      actionDate
      email
      lastName
      firstName
      status
      url
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "ElectronicSignature",
    "kind": "LinkedField",
    "name": "electronicSignature",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "creationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ElectronicSignatureSignerInfo",
        "kind": "LinkedField",
        "name": "signerInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actionDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ElectronicSignatureDetailsQuery",
    "selections": (v2/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ElectronicSignatureDetailsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "49c006ab2b24d68e6ff3f7acead6a8eb",
    "metadata": {},
    "name": "ElectronicSignatureDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec59d1a2e9edd55f9c674b1adcc0c7c6';

module.exports = node;
