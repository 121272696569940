/**
 * @flow
 * @relayHash c358d2e1946d1600d5abedefb3fea123
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SelectManagerQueryVariables = {|
  email: string
|};
export type SelectManagerQueryResponse = {|
  +account: ?{|
    +id: ?string,
    +email: ?string,
    +roles: ?$ReadOnlyArray<?string>,
    +extra: ?{|
      +region: ?string
    |},
  |}
|};
export type SelectManagerQuery = {|
  variables: SelectManagerQueryVariables,
  response: SelectManagerQueryResponse,
|};
*/


/*
query SelectManagerQuery(
  $email: String!
) {
  account(email: $email) {
    id
    email
    roles
    extra {
      region
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "SpidUser",
    "kind": "LinkedField",
    "name": "account",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SpidUserExtra",
        "kind": "LinkedField",
        "name": "extra",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "region",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectManagerQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectManagerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "c358d2e1946d1600d5abedefb3fea123",
    "metadata": {},
    "name": "SelectManagerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae9e4cfbb66590dd0eb7c4fdd9447086';

module.exports = node;
