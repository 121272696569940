import React from 'react';
import Filter from '../components/Filter';
import { DatePicker } from 'antd';

const FilterUpdateDatePeriod = ({
  fromValue,
  toValue,
  onChange
}) => {
  return <Filter label="Période">
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DatePicker
        value={fromValue}
        onChange={v => onChange('fromUpdateDate', v)}
        placeholder="Sélectionner une date"
        format="DD/MM/YYYY"
      />

      <div style={{ margin: '0 5px' }}>au</div>

      <DatePicker
        value={toValue}
        onChange={v => onChange('toUpdateDate', v)}
        placeholder="Sélectionner une date"
        format="DD/MM/YYYY"
      />
    </div>
  </Filter>;
};

export default FilterUpdateDatePeriod;
