/**
 * @flow
 * @relayHash 5a21e9b263bb5f498b502ec08765fc48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateHomeMessageMutationVariables = {|
  message: string,
  display: boolean,
  length?: ?number,
|};
export type UpdateHomeMessageMutationResponse = {|
  +updateHomeMessage: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateHomeMessageMutation = {|
  variables: UpdateHomeMessageMutationVariables,
  response: UpdateHomeMessageMutationResponse,
|};
*/


/*
mutation UpdateHomeMessageMutation(
  $message: String!
  $display: Boolean!
  $length: Int
) {
  updateHomeMessage(message: $message, display: $display, length: $length) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "message",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "display",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "length",
    "type": "Int"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "display",
        "variableName": "display"
      },
      {
        "kind": "Variable",
        "name": "length",
        "variableName": "length"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "updateHomeMessage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateHomeMessageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateHomeMessageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5a21e9b263bb5f498b502ec08765fc48",
    "metadata": {},
    "name": "UpdateHomeMessageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dd011513096cac2158dc32a5f1a7daa';

module.exports = node;
