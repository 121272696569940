import * as R from 'ramda';
import React from 'react';
import { Icon } from 'antd';

const TestOutput = ({ references }) => {
  const { numberPassed, numberFailed, errors } = references;

  return <div>
    <div>
      <span><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> {numberPassed} références ont été trouvées.</span>
    </div>
    <div>
      <span><Icon type="close-circle" theme="twoTone" twoToneColor="#ff0000" /> {numberFailed} références n&#39;ont pas été trouvées.</span>
    </div>

    <div style={{
      border: '1px solid grey',
      backgroundColor: '#e2e2e2',
      fontFamily: 'Consolas',
      marginTop: '10px',
      overflowY: 'scroll',
      maxHeight: '600px',
      fontSize: '0.9em'
    }}>
      {
        R.length(errors) === 0 ?
          <p>Toutes les références ont été trouvées.</p>
          :
          R.map(error => {
            return <p key={error} style={{ marginBottom: '5px' }}>{error}</p>;
          })(errors)
      }
    </div>
  </div>;
};

export default TestOutput;
