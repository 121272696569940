import React from 'react';
import { Icon, Tag } from 'antd';
import AccountRoles from './AccountRoles';

const AccountPanelHeader = (
  ({
    node: { name, roles, status }
  }) => (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <Tag>
          { name }
        </Tag>
        { status === 'locked' && <Icon type="lock" theme="twoTone" twoToneColor="#ff0000"/> }
        { status === 'pending' && <Icon type="clock-circle" theme="twoTone" twoToneColor="#0000ff"/> }
        <AccountRoles roles={roles} style={{ float: 'right' }}/>
      </div>
    </div>
  )
);

export default AccountPanelHeader;
