import React, { useState, useEffect } from 'react';
import withUser from '../../withUser';
import { isEmpty, propEq, assoc, toUpper, isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import { Button, Input, Icon } from 'antd';
import { Loading } from '../../components/helpers';
import SendOtpCodeMutation from '../../mutations/SendOtpCodeMutation';
import CheckOtpCodeMutation from '../../mutations/CheckOtpCodeMutation';

const useVerifyAccount = () => {
  const [state, setState] = useState({
    code: '',
    codeId: null,
    buttonLoading: false,
    sendingCodeLoading: false,
    error: null,
  });

  const updateState = (k, v) => setState(assoc(k, v));

  const onVerify = () => {
    updateState('buttonLoading', true);

    CheckOtpCodeMutation(state, (ok, error) => {
      updateState('buttonLoading', false);

      if (ok && !error) {
        window.location.reload();
      } else {
        updateState('error', 'Le code saisi n\'est pas reconnu.');
      }
    });
  };

  const onSendCode = () => {
    updateState('sendingCodeLoading', true);

    SendOtpCodeMutation((ok, error, codeId) => {
      updateState('sendingCodeLoading', false);

      if (ok && !error && !isNil(codeId)) {
        updateState('codeId', codeId);
      } else {
        updateState('error', 'Une erreur est survenue, veuillez réessayer.');
      }
    });

    setTimeout(() => {
      updateState('sendingCodeLoading', false);

    }, 3000);
  };

  useEffect(onSendCode, []);

  return {
    ...state,
    updateState,
    onVerify,
    onSendCode
  };
};

const VerifyAccount = ({ user }) => {
  const history = useHistory();

  const {
    code,
    buttonLoading,
    sendingCodeLoading,
    error,
    updateState,
    onVerify,
    onSendCode
  } = useVerifyAccount();

  if (!propEq('mustCheckMFA', true, user)) {
    history.replace('/');
    return null;
  }

  if (sendingCodeLoading) {
    return <Loading/>;
  }

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    rowGap: '10px',
  }}>
    <Icon
      type="lock"
      style={{ fontSize: '25px', color: 'red' }}
    />
    <div style={{ fontSize: '16px' }}>Un code de vérification vous a été envoyé par mail.</div>

    <Input
      placeholder="Saisir le code"
      onChange={e => updateState('code', toUpper(e.target.value))}
      value={code}
      style={{
        width: '150px',
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '20px 0',
        height: '50px',
        textAlign: 'center'
      }}
    />

    <Button
      onClick={onVerify}
      disabled={isEmpty(code)}
      loading={buttonLoading}
      type="primary"
    >
      Vérifier le code
    </Button>

    <div
      onClick={onSendCode}
      style={{
        fontSize: '13px',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#0064cf'
      }}
    >
      Code non reçu ?
    </div>

    {error && <div style={{ color: 'red' }}>{error}</div>}
  </div>;
};

export default withUser(VerifyAccount);
