import React from 'react';
import Filter from '../components/Filter';
import { Select } from 'antd';
import {
  compose,
  sortBy,
  prop,
  defaultTo,
  map, anyPass, propSatisfies, includes, toLower, find, propEq
} from 'ramda';

const propIncludes = (prop, value = '') => propSatisfies(
  compose(
    includes(toLower(value)),
    toLower,
    defaultTo('')
  ),
  prop
);

const filterCcn = (inputValue, ccn) => anyPass([
  propIncludes('shortTitle', inputValue),
  propIncludes('id', inputValue)
])(ccn);

const FilterCcn = ({
  ccns,
  onChange,
  value,
  multi = false
}) => {
  const getCcn = id => find(propEq('id', id))(ccns);

  return <Filter
    label="CCN"
  >
    <Select
      placeholder="Sélectionner une CCN"
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      showSearch
      allowClear
      filterOption={(inputValue, item) => filterCcn(inputValue, getCcn(item.key))}
      mode={multi ? 'multiple' : 'default'}
    >
      {compose(
        map(({ shortTitle, id }) => {
          return <Select.Option
            key={id}
            value={id}
          >
            {shortTitle}
          </Select.Option>;
        }),
        sortBy(prop('shortTitle')),
        defaultTo([])
      )(ccns)}
    </Select>
  </Filter>;
};

export default FilterCcn;
