/**
 * @flow
 * @relayHash ecc7a396f3e2a0265f3891775e035ba5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractHistoryItemsQueryVariables = {|
  contractId: string
|};
export type ContractHistoryItemsQueryResponse = {|
  +contract: ?{|
    +id: ?string,
    +statusHistory: ?$ReadOnlyArray<?{|
      +id: ?string,
      +status: ?string,
      +when: ?string,
      +forced: ?boolean,
    |}>,
  |}
|};
export type ContractHistoryItemsQuery = {|
  variables: ContractHistoryItemsQueryVariables,
  response: ContractHistoryItemsQueryResponse,
|};
*/


/*
query ContractHistoryItemsQuery(
  $contractId: ID!
) {
  contract(contractId: $contractId) {
    id
    statusHistory {
      id
      status
      when
      forced
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "Contract",
    "kind": "LinkedField",
    "name": "contract",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractStatusHistory",
        "kind": "LinkedField",
        "name": "statusHistory",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "when",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "forced",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractHistoryItemsQuery",
    "selections": (v2/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractHistoryItemsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "ecc7a396f3e2a0265f3891775e035ba5",
    "metadata": {},
    "name": "ContractHistoryItemsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13fc078cadf4bfa3db6b687681704c59';

module.exports = node;
