import * as R from 'ramda';
import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation AccountUpdateMutation($id: String!, $values: AccountInput!) {
  accountUpdate(id: $id, values: $values) { 
    ok
    user {
      email
      roles
      extra {
        managementCenter
        region
        sales
        lastname
        firstname
        userCode
      }
    }
    error
  }
}
`;

export default ({
  id,
  values
}, done) => {

  const variables = {
    id,
    values
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, { accountUpdate: { ok, user } }) => {
        if (ok && !R.isNil(user)) {
          const account = proxyStore.get(id);
          const extra = account.getLinkedRecord('extra');

          extra.setValue(user.extra.managementCenter, 'managementCenter');
          extra.setValue(user.extra.region, 'region');
          extra.setValue(user.extra.lastname, 'lastname');
          extra.setValue(user.extra.firstname, 'firstname');
          extra.setValue(user.extra.sales, 'sales');
          extra.setValue(user.extra.userCode, 'userCode');

          account.setValue(user.email, 'email');
          account.setValue(user.roles, 'roles');

          account.setLinkedRecord(extra, 'extra');
        }
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.accountUpdate;
        done(ok, error);
      },
      onError: error => console.error(error),
    }
  );
};
