import React from 'react';

const UserContext = React.createContext();

export function withUser (Component) {
  return function UserComponent (props) {
    return (
      <UserContext.Consumer>
        {user => <Component {...props} user={user} />}
      </UserContext.Consumer>
    );
  };
}

export default withUser;

export {
  UserContext
};