/**
 * @flow
 * @relayHash 2d95450f0dfaea792ec8fb42ed2a74e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type ContractOriginQueryVariables = {|
  contractId: string
|};
export type ContractOriginQueryResponse = {|
  +contract: ?{|
    +id: ?string,
    +propositionNumber: ?number,
    +siret: ?string,
    +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
    +siretInfo: ?{|
      +name: ?string
    |},
    +companyInformation: ?{|
      +contactLastname: ?string,
      +contactFirstname: ?string,
      +contactEmail: ?string,
    |},
    +user: ?{|
      +roles: ?$ReadOnlyArray<?string>,
      +name: ?string,
      +email: ?string,
    |},
    +signature: ?{|
      +type: ?string,
      +id: ?string,
    |},
    +contractNumbers: ?$ReadOnlyArray<?string>,
    +health: ?{|
      +contractNumbers: ?$ReadOnlyArray<?string>
    |},
  |}
|};
export type ContractOriginQuery = {|
  variables: ContractOriginQueryVariables,
  response: ContractOriginQueryResponse,
|};
*/


/*
query ContractOriginQuery(
  $contractId: ID!
) {
  contract(contractId: $contractId) {
    id
    propositionNumber
    siret
    offerTypes
    siretInfo {
      name
    }
    companyInformation {
      contactLastname
      contactFirstname
      contactEmail
    }
    user {
      roles
      name
      email
    }
    signature {
      type
      id
    }
    contractNumbers
    health {
      contractNumbers
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbers",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "Contract",
    "kind": "LinkedField",
    "name": "contract",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propositionNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SiretInfo",
        "kind": "LinkedField",
        "name": "siretInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyInformation",
        "kind": "LinkedField",
        "name": "companyInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactLastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactFirstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractSignature",
        "kind": "LinkedField",
        "name": "signature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ContractHealth",
        "kind": "LinkedField",
        "name": "health",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractOriginQuery",
    "selections": (v4/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractOriginQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "2d95450f0dfaea792ec8fb42ed2a74e0",
    "metadata": {},
    "name": "ContractOriginQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54a5fe1bf2aeaf39d7d09b92f3ca41a2';

module.exports = node;
