import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
mutation ContractActionMutation($contractId: ID!, $action: String) {
  contractAction(contractId: $contractId, action: $action) { 
    ok
    error
    contract {
      id
      dates {
        lastUpdate
      }
      status
      statusHistory{
        id
        status
        when
        forced
      }
    }
  }
}
`;

export default ({ action, contractId }, done) => {
  const variables = {
    action,
    contractId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.contractAction;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
