import * as R from 'ramda';
import React from 'react';

import {
  Row,
  Col,
  Card
} from 'antd';
import moment from 'moment';

const getBuilderRole = R.cond([
  [R.isNil, R.always('')],
  [R.includes('groupe_adhesion'), R.always('groupe adhésion')],
  [R.includes('reseau_commercial'), R.always('réseau commercial')],
  [R.includes('apporteur'), R.always('apporteur')],
  [R.T, R.always('')]
]);

const getSource = v => R.prop(v, {
  SPID_CLIENT: 'SPID Client'
});

const ContractGeneral = ({
  contract,
  isAmendment
}) => {
  const attr = (k, or = '') => R.pathOr(or, k.split('.'), contract);

  const hasOfferType = offerType => R.includes(offerType, attr('offerTypes', []));

  const establishments = R.compose(
    R.uniqBy(R.prop('siret')),
    R.prepend(R.propOr({}, 'siretInfo', contract)),
    R.propOr([], 'extraEstablishments')
  )(contract);

  const getEstablishmentsInfos = prop => R.compose(
    R.join(' / '),
    R.uniq,
    R.reject(R.isNil),
    R.pluck(prop)
  )(establishments);

  const legalPersonIds = getEstablishmentsInfos('identifiantDansSilo');
  const applicationCodes = getEstablishmentsInfos('codeApplication');
  const escAdminRepresentativeExportDate = R.path(['companyInformation', 'escAdmin', 'exportsDates', 'representative'], contract);

  return <Col span={12}>
    <Card
      title={'Général'}
      size="small"
    >
      <Row>
        <Col span={18}/>
      </Row>

      {!isAmendment && <Row style={{ marginTop: '8px' }}>
        <Col span={6}>{'SIRET :'}</Col>
        <Col span={18}>{attr('siret')}</Col>
      </Row>}

      {!isAmendment && <Row>
        <Col span={6}>{'Raison sociale :'}</Col>
        <Col span={18}>{attr('siretInfo.name')}</Col>
      </Row>}

      {!R.isEmpty(legalPersonIds) && <Row>
        <Col span={6}>ID PM REPERE :</Col>
        <Col span={18}>{legalPersonIds}</Col>
      </Row>}

      {!R.isEmpty(applicationCodes) && <Row>
        <Col span={6}>Code Silo REPERE :</Col>
        <Col span={18}>{applicationCodes}</Col>
      </Row>}

      <Row>
        <Col span={6}>{'Type :'}</Col>
        <Col span={18}>{getSource(attr('source')) || getBuilderRole(attr('user.roles'))}</Col>
      </Row>

      <Row>
        <Col span={6}>{'Fabricant :'}</Col>
        <Col span={18}>{`${attr('user.name')}`}</Col>
      </Row>

      <Row>
        <Col span={6}>{'Client :'}</Col>
        <Col span={18}>{`${attr('companyInformation.contactLastname')} ${attr('companyInformation.contactFirstname')}`}</Col>
      </Row>

      <Row>
        <Col span={6}>{'Email de contact :'}</Col>
        <Col span={18}>{`${attr('companyInformation.contactEmail')}`}</Col>
      </Row>

      {hasOfferType('PREVOYANCE') && <Row>
        <Col span={10}>{'N° de contrat Prévoyance :'}</Col>
        <Col span={14}>{attr('contractNumbers', []).join(' - ')}</Col>
      </Row>}

      {hasOfferType('SANTE') && <Row>
        <Col span={10}>{'N° de contrat Santé :'}</Col>
        <Col span={14}>{attr('health.contractNumbers', []).join(' - ')}</Col>
      </Row>}

      {!R.isNil(escAdminRepresentativeExportDate) && <Row style={{ marginTop: '18px' }}>
        <Col span={6}>Désignation ESC :</Col>
        <Col span={18}>Envoyé le {moment(escAdminRepresentativeExportDate).format('DD/MM/YYYY [à] HH:mm:SS')}</Col>
      </Row>}

      {!R.isNil(attr('relatedContract')) && attr('relatedContract.checked') &&
      <Card title="Suite économique" size="small">
        <Row>
          <Col span={10}>Ancienne dénomination sociale :</Col>
          <Col span={14}>{attr('relatedContract.oldLegalForm')}</Col>
        </Row>

        <Row>
          <Col span={10}>N° de contrat prédécesseur :</Col>
          <Col span={14}>{R.join(' - ', attr('relatedContract.contractNumbers', []))}</Col>
        </Row>

        {hasOfferType('SANTE') && <>
          <Row>
            <Col span={10}>Suite économique totale ou partielle :</Col>
            <Col span={14}>{attr('relatedContract.type') === 'TOTAL' ? 'Totale' : 'Partielle'}</Col>
          </Row>

          <Row>
            <Col span={10}>KBIS récupéré :</Col>
            <Col span={14}>{attr('relatedContract.hasKBIS') === true ? 'OUI' : 'NON'}</Col>
          </Row>
        </>}
      </Card>}
    </Card>
  </Col>;
};

export default ContractGeneral;
