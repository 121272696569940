import * as R from 'ramda';

import './Command.less';

import React from 'react';
import rs from 'randomstring';

import {
  withRouter
} from 'react-router-dom';

import {
  Mention,
} from 'antd';

const suggestions = (routes) => R.compose(
  R.append('help'),
  R.map(
    R.compose(
      R.join(''),
      R.remove(0, 1),
      R.prop('path')
    )
  )
)(routes);

const Command = ({
  routes,
  history
}) => {

  const onSelectCommand = (command) => {
    history.push(`/${command}` + `?q=${rs.generate(4)}`);
  };

  return (
    <div className="command">
      <Mention
        prefix="/"
        defaultSuggestions={suggestions(routes)}
        onSelect={onSelectCommand}
        placement="top"
      />
    </div>
  );
};

export default withRouter(Command);

