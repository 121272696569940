/**
 * @flow
 * @relayHash f3849185d09dec5a75c3eea73cbab0b0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type EmailsFilterInput = {|
  recipient?: ?string
|};
export type EmailListQueryVariables = {|
  pagination?: ?PaginationInput,
  filter?: ?EmailsFilterInput,
|};
export type EmailListQueryResponse = {|
  +emailsWithPagination: ?{|
    +emails: ?$ReadOnlyArray<?{|
      +id: ?string,
      +subject: ?string,
      +to: ?$ReadOnlyArray<?{|
        +email: ?string
      |}>,
      +recipients: ?$ReadOnlyArray<?string>,
      +status: ?string,
      +receivedAt: ?string,
    |}>,
    +pagination: ?{|
      +total: ?number,
      +page: ?number,
      +pages: ?number,
      +limit: ?number,
    |},
  |}
|};
export type EmailListQuery = {|
  variables: EmailListQueryVariables,
  response: EmailListQueryResponse,
|};
*/


/*
query EmailListQuery(
  $pagination: PaginationInput
  $filter: EmailsFilterInput
) {
  emailsWithPagination(filter: $filter, pagination: $pagination) {
    emails {
      id
      subject
      to {
        email
      }
      recipients
      status
      receivedAt
    }
    pagination {
      total
      page
      pages
      limit
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "EmailsFilterInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      }
    ],
    "concreteType": "EmailsPagination",
    "kind": "LinkedField",
    "name": "emailsWithPagination",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Email",
        "kind": "LinkedField",
        "name": "emails",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailField",
            "kind": "LinkedField",
            "name": "to",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recipients",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receivedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailListQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f3849185d09dec5a75c3eea73cbab0b0",
    "metadata": {},
    "name": "EmailListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66103634c4b448c07a0284d0dd306307';

module.exports = node;
