/**
 * @flow
 * @relayHash 24923efe821fcb741803f2c9b5769818
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TraceOfferQueryVariables = {|
  type?: ?string
|};
export type TraceOfferQueryResponse = {|
  +files: ?$ReadOnlyArray<?{|
    +id: ?string,
    +filename: ?string,
    +uploadDate: ?string,
  |}>,
  +ccns: ?$ReadOnlyArray<?{|
    +id: ?string,
    +shortTitle: ?string,
  |}>,
  +users: ?$ReadOnlyArray<?{|
    +id: ?string,
    +extra: ?{|
      +firstname: ?string,
      +lastname: ?string,
    |},
  |}>,
  +data: ?{|
    +managementCenters: ?$ReadOnlyArray<?{|
      +code: ?string,
      +name: ?string,
    |}>,
    +regions: ?$ReadOnlyArray<?{|
      +code: ?string,
      +title: ?string,
    |}>,
  |},
|};
export type TraceOfferQuery = {|
  variables: TraceOfferQueryVariables,
  response: TraceOfferQueryResponse,
|};
*/


/*
query TraceOfferQuery(
  $type: String
) {
  files(type: $type) {
    id
    filename
    uploadDate
  }
  ccns {
    id
    shortTitle
  }
  users {
    id
    extra {
      firstname
      lastname
    }
  }
  data {
    managementCenters {
      code
      name
    }
    regions {
      code
      title
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Files",
    "kind": "LinkedField",
    "name": "files",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccns",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SpidUser",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SpidUserExtra",
        "kind": "LinkedField",
        "name": "extra",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Center",
        "kind": "LinkedField",
        "name": "managementCenters",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraceOfferQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraceOfferQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "24923efe821fcb741803f2c9b5769818",
    "metadata": {},
    "name": "TraceOfferQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2eaeb69bb24bd7bed41ee952fb521a87';

module.exports = node;
