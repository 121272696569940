import * as R from 'ramda';
import React from 'react';
import { Collapse, Tag } from 'antd';
import List from '../../../lib/List';
import MetricTable from './MetricTable';

const Panel = Collapse.Panel;

const PanelHeader = ({ node: { id, shortTitle } }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ flex: 1 }}>
      <Tag>
        { id }
      </Tag>
      <Tag>
        { shortTitle }
      </Tag>
    </div>
  </div>
);

class MetricsList extends List {
  render () {
    const { ccns } = this.props;

    return R.isNil(ccns) ?
      (<span>{'Aucune donnée n\'a été retournée.'}</span>)
      :
      (<Collapse accordion>
        {ccns.map((ccn) => (
          <Panel header={<PanelHeader node={ccn}/>} key={ccn.id}>
            <MetricTable ccn={ccn}/>
          </Panel>
        ))}
      </Collapse>);
  }
}

export default MetricsList;
