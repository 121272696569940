/**
 * @flow
 * @relayHash 34c62f40ee8a070bee2d5e214d603d02
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoutesQueryVariables = {||};
export type RoutesQueryResponse = {|
  +user: ?{|
    +id: ?string,
    +username: ?string,
    +firstname: ?string,
    +email: ?string,
    +mustCheckMFA: ?boolean,
  |}
|};
export type RoutesQuery = {|
  variables: RoutesQueryVariables,
  response: RoutesQueryResponse,
|};
*/


/*
query RoutesQuery {
  user {
    id
    username
    firstname
    email
    mustCheckMFA
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mustCheckMFA",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoutesQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoutesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "34c62f40ee8a070bee2d5e214d603d02",
    "metadata": {},
    "name": "RoutesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3cf82d5dd9dd36e1be4eea2347f68f97';

module.exports = node;
