import * as R from 'ramda';
import React from 'react';
import { Card, Col, Row, Tabs } from 'antd';
import Moment from 'moment';
import AccountField from './components/AccountField';
import AccountRoles from './components/AccountRoles';
import AccountEdition from './components/AccountEdition';
import AccountStatus from './components/AccountStatus';
import AccountUpdate from './components/AccountUpdate';

const TabPane = Tabs.TabPane;

const Account = (
  ({
    user
  }) => {

    const get = (path) => R.pathOr('', path)(user);
    const getDate = (path) => {
      const date = Moment(get(path)).format('DD/MM/YYYY H[h]mm');
      return date !== 'Invalid date' ? date : '';
    };
    const name = `${get(['extra', 'firstname'])} ${get(['extra', 'lastname'])}`;
    const roles = R.propOr([], 'roles')(user);
    const userIs = (role) => R.includes(role)(roles);

    return <div>
      <Row gutter={8}>
        <Col span={16}>
          <Card size="small" title="Informations">
            <AccountField title={'Nom'}>{name}</AccountField>
            <AccountField title={'Email'}>{get(['email'])}</AccountField>
            <AccountField title={'Rôle(s)'} style={{ display: 'inline-flex' }}>&nbsp;<AccountRoles roles={roles}/></AccountField>
            <AccountField title={'Code'}>{get(['extra', 'userCode'])}</AccountField>
            <AccountField title={'Région'}>{get(['extra', 'regionName'])}</AccountField>
            { userIs('groupe_adhesion') && <AccountField title={'Centre de gestion'}>{get(['extra', 'managementCenterName'])}</AccountField> }
            { userIs('reseau_commercial') && <AccountField title={'Structure commerciale'}>{get(['extra', 'sales'])}</AccountField> }
          </Card>
        </Col>

        <Col span={8}>
          <Card size="small" title="État du compte">
            <AccountField title={'Statut'}><AccountStatus status={get(['status'])} /></AccountField>
            <AccountField title={'Inscription'}>{getDate(['dates', 'signUp'])}</AccountField>
            <AccountField title={'Dernière connexion'}>{getDate(['dates', 'lastSignIn'])}</AccountField>
            <AccountField title={'Dernière déconnexion'}>{getDate(['dates', 'lastSignOut'])}</AccountField>
          </Card>
        </Col>
      </Row>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Édition" key="1">
          <AccountEdition user={user}/>
        </TabPane>
        <TabPane tab="Mise à jour des informations" key="2">
          <AccountUpdate user={user}/>
        </TabPane>
      </Tabs>

    </div>;
  }
);

export default Account;
