/**
 * @flow
 * @relayHash 27e3cb1bafa5601d0b04887008235841
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountUpdateStatusMutationVariables = {|
  action: string,
  email: string,
|};
export type AccountUpdateStatusMutationResponse = {|
  +accountUpdateStatus: ?{|
    +ok: boolean,
    +status: ?string,
    +error: ?string,
  |}
|};
export type AccountUpdateStatusMutation = {|
  variables: AccountUpdateStatusMutationVariables,
  response: AccountUpdateStatusMutationResponse,
|};
*/


/*
mutation AccountUpdateStatusMutation(
  $action: String!
  $email: String!
) {
  accountUpdateStatus(action: $action, email: $email) {
    ok
    status
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "action",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "action",
        "variableName": "action"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "StatusResponse",
    "kind": "LinkedField",
    "name": "accountUpdateStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountUpdateStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountUpdateStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "27e3cb1bafa5601d0b04887008235841",
    "metadata": {},
    "name": "AccountUpdateStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a655d389f2e62cfaad2edf9ec50dc150';

module.exports = node;
