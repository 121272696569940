import * as R from 'ramda';
import Moment from 'moment';
import React from 'react';
import { Timeline } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { getContractStatus } from '../../lib/Status';
import Loading from '../helpers/Loading';

const ContractHistoryItemsQuery = graphql`
  query ContractHistoryItemsQuery($contractId: ID!) {
    contract(contractId: $contractId) {
      id
      statusHistory {
        id
        status
        when
        forced
      }
    }
  }
`;

const ContractHistoryItems = ({ contractId }) => (
  <QueryRenderer
    environment={environment}
    query={ContractHistoryItemsQuery}
    variables={{
      contractId
    }}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.stack}</div>;
      } else if (!props) {
        return <Loading />;
      }

      const statusHistory = R.pathOr([], ['contract', 'statusHistory'], props);

      const humanizeForced = (forced) => forced ? ' (réalisé par l\'administrateur)' : '';

      return <div style={{ margin: '16px 0px 8px 0px' }}>
        {!R.isEmpty(statusHistory) && <Timeline reverse mode="left">
          {R.compose(
            R.map(({ status, when, forced }) => {
              return <Timeline.Item
                key={when}
              >
                {`${Moment(when).format('DD/MM/YYYY - HH:mm')} : ${getContractStatus(status)} ${humanizeForced(forced)}`}
              </Timeline.Item>;
            }),
            R.reject(
              R.propSatisfies(
                R.anyPass([
                  R.isNil,
                  R.equals('rejected'),
                  R.equals('done')
                ]),
                'status'
              )
            )
          )(statusHistory)}
        </Timeline>}
      </div>;
    }}
  />
);

export default ContractHistoryItems;
