import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation DownloadContractsBySirenMutation($siren: String!) {
    downloadContractsBySiren(siren: $siren) { 
      ok
      error
      url
    }
  }
`;

export default ({
  siren
}, done) => {

  const variables = {
    siren
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error, url } = response.downloadContractsBySiren;
        done(ok, error, url);
      },
      onError: error => console.error(error),
    }
  );
};
