import { compose, fromPairs, map, propOr, replace, split } from 'ramda';

const getQueryParams = compose(
  fromPairs,
  map(split('=')),
  split('&'),
  replace('?', ''),
  propOr('', 'search'),
);

export default getQueryParams;
