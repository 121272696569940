import * as R from 'ramda';
import React from 'react';
import { Table } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import { Loading } from '../../../components/helpers';
import environment from '../../../Environment';

const MetricTableQuery = graphql`
  query MetricTableQuery($ccnId: String, $shortTitle: String) {
    metrics(ccnId: $ccnId, shortTitle: $shortTitle) {
      ccnId
      shortTitle
      groupe_adhesion {
        manual {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
        electronic {
          sentForSignature
          closed
          confirmed
          sentToManagementSystem
        }
      }
      reseau_commercial {
        VAD {
          manual {
            sentForSignature
            closed
            confirmed
            sentToManagementSystem
          }
          electronic {
            sentForSignature
            closed
            confirmed
            sentToManagementSystem
          }
        }
        Mdpro {
          manual {
            sentForSignature
            closed
            confirmed
            sentToManagementSystem
          }
          electronic {
            sentForSignature
            closed
            confirmed
            sentToManagementSystem
          }
        }
        MDE {
          manual {
            sentForSignature
            closed
            confirmed
            sentToManagementSystem
          }
          electronic {
            sentForSignature
            closed
            confirmed
            sentToManagementSystem
          }
        }
      }
    }
}
`;

const columns = [
  {
    title: '',
    dataIndex: 'header_status',
    fixed: 'left',
    width: 100
  },
  {
    title: 'Groupe Adhésion',
    children: [{
      title: 'Manuelle',
      dataIndex: 'ga_manual',
      align: 'center',
      sorter: (a, b) => a.ga_manual - b.ga_manual
    }, {
      title: 'Électronique',
      dataIndex: 'ga_electronic',
      align: 'center',
      sorter: (a, b) => a.ga_electronic - b.ga_electronic
    }]
  },
  {
    title: 'Réseau Commercial',
    children: [{
      title: 'VAD',
      children: [{
        title: 'Manuelle',
        dataIndex: 'rc_vad_manual',
        align: 'center',
        sorter: (a, b) => a.rc_vad_manual - b.rc_vad_manual
      }, {
        title: 'Électronique',
        dataIndex: 'rc_vad_electronic',
        align: 'center',
        sorter: (a, b) => a.rc_vad_electronic - b.rc_vad_electronic
      }]
    },
    {
      title: 'MDE',
      children: [{
        title: 'Manuelle',
        dataIndex: 'rc_mde_manual',
        align: 'center',
        sorter: (a, b) => a.rc_mde_manual - b.rc_mde_manual
      }, {
        title: 'Électronique',
        dataIndex: 'rc_mde_electronic',
        align: 'center',
        sorter: (a, b) => a.rc_mde_electronic - b.rc_mde_electronic
      }]
    },
    {
      title: 'Mdpro',
      children: [{
        title: 'Manuelle',
        dataIndex: 'rc_mdpro_manual',
        align: 'center',
        sorter: (a, b) => a.rc_mdpro_manual - b.rc_mdpro_manual
      }, {
        title: 'Électronique',
        dataIndex: 'rc_mdpro_electronic',
        align: 'center',
        sorter: (a, b) => a.rc_mdpro_electronic - b.rc_mdpro_electronic
      }]
    }]
  }
];

const formatMetric = (metric, ccnId) => {
  const getProp = (path) => R.pathOr(0, path)(metric) > 0 ? R.pathOr(0, path)(metric) : '';
  const ga = 'groupe_adhesion', rc = 'reseau_commercial', man = 'manual', elec = 'electronic', sts = 'sentForSignature', conf = 'confirmed', stms = 'sentToManagementSystem', closed = 'closed';

  const entry = (header, status) => {
    return {
      id: `${ccnId}-${header}`,
      header_status: header,
      ga_manual: getProp([ga, man, status]),
      ga_electronic: getProp([ga, elec, status]),
      rc_vad_manual: getProp([rc, 'VAD', man, status]),
      rc_vad_electronic: getProp([rc, 'VAD', elec, status]),
      rc_mde_manual: getProp([rc, 'MDE', man, status]),
      rc_mde_electronic: getProp([rc, 'MDE', elec, status]),
      rc_mdpro_manual: getProp([rc, 'Mdpro', man, status]),
      rc_mdpro_electronic: getProp([rc, 'Mdpro', elec, status]),
    };
  };

  const entries = [];
  entries.push(entry('Envoyés pour signature', sts));
  entries.push(entry('Signés', conf));
  entries.push(entry('Mis en gestion', stms));
  entries.push(entry('Clos', closed));

  return entries;
};

class MetricTable extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { ccn } = this.props;
    const ccnId = R.propOr('', 'id')(ccn);

    return <QueryRenderer
      environment={environment}
      query={MetricTableQuery}
      variables={{
        ccnId,
        shortTitle: R.propOr('', 'shortTitle')(ccn)
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error}</div>;
        } else if (!props) return <Loading/>;

        const { metrics } = props;
        return R.length(metrics) > 0 ? (
          <Table
            bordered
            pagination={false}
            scroll={{ x: 1400 }}
            columns={columns}
            dataSource={formatMetric(metrics[0], ccnId)}
            rowKey="id"
          />
        ) : 'Pas de données';
      }}
    />;
  }

}

export default MetricTable;
