/**
 * @flow
 * @relayHash ad47b3c660f7b38519caeea7b1b2815c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FileInput = {|
  draftId?: ?string,
  imported?: ?boolean,
  index?: ?number,
  mimeType?: ?string,
  originalName?: ?string,
  size?: ?number,
|};
export type UpdateContractFromOMGMutationVariables = {|
  file: FileInput,
  src: string,
|};
export type UpdateContractFromOMGMutationResponse = {|
  +updateContractFromOmg: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateContractFromOMGMutation = {|
  variables: UpdateContractFromOMGMutationVariables,
  response: UpdateContractFromOMGMutationResponse,
|};
*/


/*
mutation UpdateContractFromOMGMutation(
  $file: FileInput!
  $src: String!
) {
  updateContractFromOmg(file: $file, src: $src) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file",
    "type": "FileInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "src",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "file",
        "variableName": "file"
      },
      {
        "kind": "Variable",
        "name": "src",
        "variableName": "src"
      }
    ],
    "concreteType": "UpdateContractFromOmgFeedbackCsvTaskMutationResponse",
    "kind": "LinkedField",
    "name": "updateContractFromOmg",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateContractFromOMGMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateContractFromOMGMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ad47b3c660f7b38519caeea7b1b2815c",
    "metadata": {},
    "name": "UpdateContractFromOMGMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '96b369adfa34f0c7a9bf7fccc6ce0968';

module.exports = node;
