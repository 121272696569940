import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { graphql, useRelayEnvironment } from 'react-relay';
import ContractsUpdateFromOmgFeedbackCsvTask from './ContractsUpdateFromOmgFeedbackCsvTask';
import { Button, List } from 'antd';
import { fetchQuery } from 'relay-runtime';

const ContractsUpdateFromOmgFeedbackCsvTasksQuery = graphql`
  query ContractsUpdateFromOmgFeedbackCsvTasksQuery {
    contractsUpdateFromOmgFeedbackCsvTasks {
      id
      startDate
      endDate
      date
      status
      filename
      totalToProcess
      totalProcessed
    }
  }
`;

const useReload = () => {
  const [reloadIndex, setReloadIndex] = useState(0);

  const onReload = () => {
    setReloadIndex(R.inc);
  };

  return [
    reloadIndex,
    onReload
  ];
};

const useTasks = () => {
  const environment = useRelayEnvironment();
  const [tasks, setTasks] = useState([]);
  const [reloadIndex, onReload] = useReload();

  useEffect(() => {
    fetchQuery(environment, ContractsUpdateFromOmgFeedbackCsvTasksQuery, {}).subscribe({
      next: data => {
        const tasks = R.pathOr([], ['contractsUpdateFromOmgFeedbackCsvTasks'])(data);
        setTasks(tasks);
      }
    });
  }, [reloadIndex]);

  return {
    tasks,
    onReload
  };
};

const ContractsUpdateFromOmgFeedbackCsvTasks = () => {
  const { tasks, onReload } = useTasks();

  return <div style={{ marginTop: 8 }}>
    <Button
      size="small"
      shape="circle"
      icon="sync"
      onClick={onReload}
    />

    <br/>

    <List
      dataSource={tasks}
      renderItem={item => (
        <List.Item>
          <ContractsUpdateFromOmgFeedbackCsvTask
            task={item}
            onRefreshTasks={onReload}
          />
        </List.Item>
      )}
    />
  </div>;
};

export default ContractsUpdateFromOmgFeedbackCsvTasks;
