import './Account.less';
import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { userPropType } from '../../PropTypes';

import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import {
  Layout
} from 'antd';

import SignInForm from './SignInForm';
import withUser from '../../withUser';
import VerifyAccount from './VerifyAccount';

const userIsSignedId = (user) => {
  return R.compose(
    R.not,
    R.isNil,
    R.prop('email')
  )(user);
};

const Account = ({ match, user }) => (
  <Layout>
    <Layout.Content className="account-page">
      <div className="account-form">
        <Switch>
          <Route exact path={`${match.url}/verifyaccount`} component={VerifyAccount}/>
          {userIsSignedId(user) && <Redirect to="/"/>}
          <Route exact path={`${match.url}/signin`} component={SignInForm}/>
        </Switch>
      </div>
    </Layout.Content>
  </Layout>
);

SignInForm.propTypes = {
  match: PropTypes.object.isRequired,
  user: userPropType
};

export default withUser(Account);
