import {
  createActions
} from 'redux-actions';
import BuildFileMutation from '../mutations/BuildFileMutation';
import BuildFileStatusMutation from '../mutations/BuildFileStatusMutation';

const {
  done_command,
  updateCommand,
  clearCommand,
  buildFile,
  buildFileReset
} = createActions({
  DONE_COMMAND: action => action,
  UPDATE_COMMAND: action => (action),
  CLEAR_COMMAND: action => (action),
  BUILD_FILE: (type, status) => ({ type, status }),
  BUILD_FILE_RESET: type => ({ type })
});

export const commandBuildFile = (type, filters, offerType) => {
  return async (dispatch) => {
    dispatch(buildFileReset(type));
    BuildFileMutation({ type, filters, offerType }, (ok, token) => {

      if (ok) {
        dispatch(buildFile(type, 'building'));

        const getBuildStatus = (token) => {

          setTimeout(() => {
            BuildFileStatusMutation({ token }, (ok, token, status) => {

              if (status === 'build_failed') dispatch(buildFile(type, 'build_failed'));
              else if (status === 'built') dispatch(buildFile(type, 'built'));
              else if (status === 'canceled') dispatch(buildFile(type, 'canceled'));
              else {
                dispatch(buildFile(type, status));
                getBuildStatus(token);
              }

            });
          }, 5000);
        };
        getBuildStatus(token);
      }
    });
  };
};

export const resetBuildStatus = (type) => {
  return async (dispatch) => {
    dispatch(buildFileReset(type));
  };
};

export {
  done_command,
  updateCommand,
  clearCommand,
  buildFile,
  buildFileReset
};

