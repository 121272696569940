/**
 * @flow
 * @relayHash f64b3a1c1ae01bc08cab5890f253eab9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CcnScopeEnum = "SPID" | "SPID_CLIENT" | "%future added value";
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type ManageQueryVariables = {||};
export type ManageQueryResponse = {|
  +ccnsFromCom: ?$ReadOnlyArray<?{|
    +ccnId: ?string,
    +shortTitle: ?string,
    +status: ?$ReadOnlyArray<?{|
      +enabled: ?boolean,
      +scope: ?CcnScopeEnum,
      +offerType: ?OfferTypeEnum,
    |}>,
    +idcc: ?string,
  |}>,
  +env: ?string,
|};
export type ManageQuery = {|
  variables: ManageQueryVariables,
  response: ManageQueryResponse,
|};
*/


/*
query ManageQuery {
  ccnsFromCom {
    ccnId
    shortTitle
    status {
      enabled
      scope
      offerType
    }
    idcc
    id
  }
  env(envVar: SPID_CLIENT_URL)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ccnId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortTitle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "CcnFromComStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scope",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offerType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "idcc",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "envVar",
      "value": "SPID_CLIENT_URL"
    }
  ],
  "kind": "ScalarField",
  "name": "env",
  "storageKey": "env(envVar:\"SPID_CLIENT_URL\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CcnFromCom",
        "kind": "LinkedField",
        "name": "ccnsFromCom",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ManageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CcnFromCom",
        "kind": "LinkedField",
        "name": "ccnsFromCom",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "id": "f64b3a1c1ae01bc08cab5890f253eab9",
    "metadata": {},
    "name": "ManageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '44eb0fbe1b9f3d1d5307c52322d1c614';

module.exports = node;
