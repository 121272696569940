import { useEffect, useState } from 'react';
import { message } from 'antd';

export const useBuild = (status, resetBuild) => {
  const [reload, setReload] = useState(0);
  const [hasBeenReloaded, setHasBeenReloaded] = useState(false);
  const onReload = () => {
    resetBuild();
    setReload(reload + 1);
  };

  useEffect(() => {
    if (status === 'built' && !hasBeenReloaded) {
      setReload(reload + 1);
      setHasBeenReloaded(true);
    }

    if (status === 'building') {
      setHasBeenReloaded(false);
    }

    if (status === 'canceled') {
      message.info('Aucune ligne à générer.');
    }
  });

  return {
    reload,
    onReload
  };
};
