import Tokens from './lib/Tokens';
import * as R from 'ramda';
const {
  Environment,
  Network,
  RecordSource,
  Store,
} = require('relay-runtime');

const store = new Store(new RecordSource());

const sendRequest = async (headers, body, refresh_token = false) => {
  let access_token = await Tokens.getAccessToken(refresh_token);
  const response = await fetch('/graphql', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      ...headers,
      'Authorization': `Bearer ${access_token}`
    },
    body
  });

  if (response.status === 200) {
    return await response.json();
  }

  if (response.status === 401) {
    if (R.isNil(refresh_token)) {
      throw new Error('can not refresh access token');
    }
    return await sendRequest(headers, body, true);
  }

  return new Error('graphql request failed');
};

const network = Network.create(async (operation, variables, cacheConfig, uploadables) => {
  let body;
  let headers;

  // eslint-disable-next-line no-undef
  body = new FormData();

  if (uploadables) {
    // eslint-disable-next-line no-undef
    body = new FormData();
    body.append('queryId', operation.id);
    body.append('variables', JSON.stringify(variables));

    Object.keys(uploadables).forEach((filename) => {
      // eslint-disable-next-line no-prototype-builtins
      if (uploadables.hasOwnProperty(filename)) {
        body.append(filename, uploadables[filename]);
      }
    });
  } else {
    body = JSON.stringify({
      queryId: operation.id,
      variables,
    });
    headers = {
      Accept: '*/*',
      'Content-Type': 'application/json'
    };
  }

  return await sendRequest(headers, body);
});

const environment = new Environment({
  network,
  store,
});

export default environment;
