/**
 * @flow
 * @relayHash 3a3fd9fa193ed7d015b0d68a6e7ac872
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OmgQueryVariables = {|
  type?: ?string
|};
export type OmgQueryResponse = {|
  +files: ?$ReadOnlyArray<?{|
    +id: ?string,
    +filename: ?string,
    +uploadDate: ?string,
  |}>
|};
export type OmgQuery = {|
  variables: OmgQueryVariables,
  response: OmgQueryResponse,
|};
*/


/*
query OmgQuery(
  $type: String
) {
  files(type: $type) {
    id
    filename
    uploadDate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Files",
    "kind": "LinkedField",
    "name": "files",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OmgQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OmgQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "3a3fd9fa193ed7d015b0d68a6e7ac872",
    "metadata": {},
    "name": "OmgQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'def37c8be7856a35d8758db1218a104c';

module.exports = node;
