import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { Loading } from '../../components/helpers';
import environment from '../../Environment';
import Count from './components/Count';
import { Output } from '../../components/index';
import Filter from './components/Filter';
import { signatureTypes, yesNo, roles, sales, status, offerTypes } from './components/sets';
import SelectCcn from './components/SelectCcn';

const ContractsQuery = graphql`
  query ContractsQuery($filters: CountContractsFilterInput) {
    countContracts(filters: $filters)
  }
`;

const Contracts = () => {
  const [filter, setFilter] = useState({});

  const updateFilter = (field, value) => {
    setFilter(R.assoc(field, value)(filter));
  };

  return <Output>
    <h4 style={{ marginBottom: '30px' }}>Statistiques des contrats</h4>

    <div>
      <Filter label={'CCN'}>
        <SelectCcn
          onChange={v => updateFilter('ccnId', R.prop('id', v))}
          style={{ width: '300px', marginBottom: 0 }}
        />
      </Filter>
      <Filter set={status} field={'status'} label={'Statut'} filter={filter} updateFilter={updateFilter} multiple/>
      <Filter set={signatureTypes} field={'signatureMode'} label={'Mode de signature'} filter={filter} updateFilter={updateFilter}/>
      <Filter set={roles} field={'userRole'} label={'Rôle'} filter={filter} updateFilter={updateFilter}/>
      <Filter set={sales} field={'userSales'} label={'Structure commerciale'} filter={filter} updateFilter={updateFilter}/>
      <Filter set={yesNo} field={'relatedContract'} label={'Suite économique'} filter={filter} updateFilter={updateFilter}/>
      <Filter set={yesNo} field={'isAmendment'} label={'Avenant'} filter={filter} updateFilter={updateFilter}/>
      <Filter set={yesNo} field={'duplicata'} label={'Duplicata'} filter={filter} updateFilter={updateFilter}/>
      <Filter set={offerTypes} field={'offerType'} label={'Type d\'offre'} filter={filter} updateFilter={updateFilter}/>
    </div>

    <QueryRenderer
      environment={environment}
      query={ContractsQuery}
      variables={{ filters: filter }}
      render={({ error, props }) => {

        if (error) return <div>{error}</div>;
        if (!props) return <Loading/>;

        return <Count
          count={R.propOr(0, 'countContracts', props)}
          singular={'contrat'}
          plural={'contrats'}
        />;

      }}
    />
  </Output>;
};

export default Contracts;
