import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateHomeMessageMutation($message: String!, $display: Boolean!, $length: Int) {
  updateHomeMessage(message: $message, display: $display, length: $length) { 
    ok
    error
  }
}
`;

export default ({ message, display, length }, done) => {

  const variables = {
    message,
    display,
    length
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error } = response.updateHomeMessage;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
