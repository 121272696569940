/**
 * @flow
 * @relayHash 6cd005974f077b4c44da9a029289c259
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteContractsBySirenMutationVariables = {|
  siren: string
|};
export type DeleteContractsBySirenMutationResponse = {|
  +deleteContractsBySiren: ?{|
    +ok: boolean,
    +error: ?string,
    +reason: ?string,
  |}
|};
export type DeleteContractsBySirenMutation = {|
  variables: DeleteContractsBySirenMutationVariables,
  response: DeleteContractsBySirenMutationResponse,
|};
*/


/*
mutation DeleteContractsBySirenMutation(
  $siren: String!
) {
  deleteContractsBySiren(siren: $siren) {
    ok
    error
    reason
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siren",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "siren",
        "variableName": "siren"
      }
    ],
    "concreteType": "ReasonResponse",
    "kind": "LinkedField",
    "name": "deleteContractsBySiren",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reason",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteContractsBySirenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteContractsBySirenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6cd005974f077b4c44da9a029289c259",
    "metadata": {},
    "name": "DeleteContractsBySirenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ede00719c664bd9266e410e0ab90744b';

module.exports = node;
