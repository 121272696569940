import * as R from 'ramda';

import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import Moment from 'moment';

import {
  Card,
  Row,
  Col,
  Tag
} from 'antd';

import {
  Loading
} from '../helpers';

const ElectronicSignatureDetailsQuery = graphql`
  query ElectronicSignatureDetailsQuery($id: ID!) {
    electronicSignature(id: $id) {
      id
      status
      creationDate
      signerInfo {
        actionDate
        email
        lastName
        firstName
        status
        url
      }
    }
  }
`;

const CardTitle = ({ id }) => (
  <Tag>{id}</Tag>
);
const ElectronicSignatureDetails = ({
  id
}) => {
  return (
    <QueryRenderer
      environment={environment}
      query={ElectronicSignatureDetailsQuery}
      variables={{
        id
      }}
      render={({ props }) => {
        if (R.isNil(props)) {
          return <Loading/>;
        } else {
          const attr = (path) => R.path(path.split('.'), props.electronicSignature);

          if (R.isNil(attr('id'))) return null;

          const formatDate = (date) => Moment(date).format('DD/MM/YYYY - HH:mm:ss');
          return (
            <Card
              title={<CardTitle id={id}/>}
              style={{ marginTop: '8px' }}
            >
              <Row>
                <Col span={6}>{'status :'}</Col>
                <Col span={18}><Tag>{attr('status')}</Tag></Col>
              </Row>
              <Row>
                <Col span={6}>{'date de création :'}</Col>
                <Col span={18}>{formatDate(attr('creationDate'))}</Col>
              </Row>
              {
                !R.isNil(attr('signerInfo.actionDate')) && (
                  <Row>
                    <Col span={6}>{'date dernière action :'}</Col>
                    <Col span={18}>{formatDate(attr('signerInfo.actionDate'))}</Col>
                  </Row>
                )
              }
              <Row>
                <Col span={6}>{'client :'}</Col>
                <Col span={18}>{`${attr('signerInfo.lastName')} ${attr('signerInfo.firstName')}`}</Col>
              </Row>
              <Row>
                <Col span={6}>{'email :'}</Col>
                <Col span={18}>{attr('signerInfo.email')}</Col>
              </Row>
              <Row>
                <Col span={6}>{'url :'}</Col>
                <Col span={18}>{attr('signerInfo.url')}</Col>
              </Row>
            </Card>
          );
        }
      }}
    />
  );
};

export default ElectronicSignatureDetails;
