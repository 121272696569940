import * as R from 'ramda';

import { handleActions } from 'redux-actions';

const initialState = {
  value: ''
};

export const command = handleActions({
  CLEAR_COMMAND: (state) => {
    return R.assoc('value', '')(state);
  },
  UPDATE_COMMAND: (state, { payload } ) => {
    return R.assoc('value', payload)(state);
  },
  BUILD_FILE: (state, { payload: { type, status } }) => {
    return R.assoc(`${type}BuildingStatus`, status)(state);
  },
  BUILD_FILE_RESET: (state, { payload: { type } }) => {
    return R.assoc(`${type}BuildingStatus`, undefined)(state);
  }
}, initialState);
