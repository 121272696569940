import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateElectronicSignatureIdMutation($propositionId: ID!, $electronicSignatureId: [String]) {
  updateElectronicSignatureId(propositionId: $propositionId, electronicSignatureId: $electronicSignatureId) { 
    ok
    error
    contract {
      id
      signature {
        id
        type
      }
    }
  }
}
`;

export default (
  propositionId,
  {
    electronicSignatureId
  }, done) => {

  const variables = {
    propositionId,
    electronicSignatureId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.updateElectronicSignatureId;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
