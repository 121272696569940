/**
 * @flow
 * @relayHash e05670f4755cfd3f71eec94306da3c7a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type AccountsListQueryVariables = {|
  role?: ?string,
  managementCenter?: ?string,
  query?: ?string,
  pagination: PaginationInput,
|};
export type AccountsListQueryResponse = {|
  +usersWithPagination: ?{|
    +users: ?$ReadOnlyArray<?{|
      +id: ?string,
      +email: ?string,
      +username: ?string,
      +status: ?string,
      +roles: ?$ReadOnlyArray<?string>,
      +dates: ?{|
        +signUp: ?string,
        +lastSignIn: ?string,
        +lastConnection: ?string,
        +lastSignOut: ?string,
      |},
      +extra: ?{|
        +userCode: ?string,
        +region: ?string,
        +regionName: ?string,
        +managementCenter: ?string,
        +managementCenterName: ?string,
        +lastname: ?string,
        +firstname: ?string,
        +sales: ?string,
        +commercialManagerEmail: ?string,
        +regionalManagerEmail: ?string,
        +providers: ?$ReadOnlyArray<?string>,
        +providerEmail: ?string,
      |},
    |}>,
    +pagination: ?{|
      +limit: ?number,
      +page: ?number,
      +pages: ?number,
      +total: ?number,
    |},
  |}
|};
export type AccountsListQuery = {|
  variables: AccountsListQueryVariables,
  response: AccountsListQueryResponse,
|};
*/


/*
query AccountsListQuery(
  $role: String
  $managementCenter: String
  $query: String
  $pagination: PaginationInput!
) {
  usersWithPagination(role: $role, managementCenter: $managementCenter, query: $query, pagination: $pagination) {
    users {
      id
      email
      username
      status
      roles
      dates {
        signUp
        lastSignIn
        lastConnection
        lastSignOut
      }
      extra {
        userCode
        region
        regionName
        managementCenter
        managementCenterName
        lastname
        firstname
        sales
        commercialManagerEmail
        regionalManagerEmail
        providers
        providerEmail
      }
    }
    pagination {
      limit
      page
      pages
      total
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "role",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "managementCenter",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pagination",
    "type": "PaginationInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "managementCenter",
        "variableName": "managementCenter"
      },
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      }
    ],
    "concreteType": "UsersPagination",
    "kind": "LinkedField",
    "name": "usersWithPagination",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SpidUser",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SpidUserDate",
            "kind": "LinkedField",
            "name": "dates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signUp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastSignIn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastConnection",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastSignOut",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SpidUserExtra",
            "kind": "LinkedField",
            "name": "extra",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "region",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "regionName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "managementCenter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "managementCenterName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sales",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commercialManagerEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "regionalManagerEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "providers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "providerEmail",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsListQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountsListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "e05670f4755cfd3f71eec94306da3c7a",
    "metadata": {},
    "name": "AccountsListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81baf5d68ff69e6e3ed2bf80cfe4c464';

module.exports = node;
