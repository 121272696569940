import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
mutation UpdateContractBuilderMutation($propositionId: ID!, $email: String!) {
  updateContractBuilder(propositionId: $propositionId, email: $email) { 
    ok
    error
    contract {
      id
      user {
        name
        roles
        email
      }
    }
  }
}
`;

export default (
  propositionId,
  {
    email
  }, done) => {

  const variables = {
    propositionId,
    email
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: () => {
      },
      optimisticUpdater: () => {
      },
      onCompleted: (response) => {
        const { ok, error } = response.updateContractBuilder;
        done(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
