import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Col, Icon, Input, Row, Select } from 'antd';
import AccountsList from './components/AccountsList';
import AccountField from './components/components/AccountField';
import { graphql, QueryRenderer } from 'react-relay';
import { withRouter } from 'react-router-dom';
import { Output } from '../../components/index';
import environment from '../../Environment';
import { rolesList } from '../../data/rolesList';
import getQueryParams from '../../lib/getQueryParams';

const Option = Select.Option;

const FindQuery = graphql`
  query FindQuery {
    data {
      managementCenters {
        name
        code
      }
    }
  }
`;

const Find = ({
  history,
  location
}) => {
  const filters = getQueryParams(location);

  const [filterRole, setFilterRole] = useState(R.propOr('', 'role')(filters));
  const [filterManagementCenter, setFilterManagementCenter] = useState(R.propOr('', 'managementCenter')(filters));
  const [searchStr, setSearchStr] = useState(R.propOr('', 'searchStr')(filters));

  useEffect(() => {
    history.replace(`/accounts/find?role=${filterRole}&managementCenter=${filterManagementCenter}&searchStr=${searchStr}`);
  }, [filterRole, filterManagementCenter, searchStr, history]);

  return <Output>
    <h4>{'Recherche d\'utilisateurs :'}</h4>

    <Row>
      <Col span={8}>
        <AccountField title={'Email'}>
          <Input
            placeholder="Entrez une adresse mail"
            prefix={<Icon type="mail"/>}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            style={{ width: '80%' }}
          />
        </AccountField>
      </Col>

      <Col span={8}>
        <AccountField title="Rôle">
          <Select
            showSearch
            allowClear
            style={{ width: '80%' }}
            placeholder="Choisissez un rôle"
            optionFilterProp="children"
            value={filterRole}
            onChange={(role) => setFilterRole(R.defaultTo('', role))}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {rolesList.map((role) => <Option value={role.name} key={role.name}>{role.title}</Option>)}
          </Select>
        </AccountField>
      </Col>

      <Col span={8}>
        <QueryRenderer
          environment={environment}
          query={FindQuery}
          render={({ error, props }) => {
            if (error) {
              return <div>{error}</div>;
            } else if (!props) return null;

            const managementsCenters = R.pathOr([], ['data', 'managementCenters'])(props);
            if (R.length(managementsCenters) === 0) {
              return null;
            }

            return <AccountField title="Centre de gestion">
              <Select
                showSearch
                allowClear
                style={{ width: '55%' }}
                placeholder="Choisissez un centre de gestion"
                optionFilterProp="children"
                value={filterManagementCenter}
                onChange={(managementCenter) => setFilterManagementCenter(R.defaultTo('', managementCenter))}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {managementsCenters.map((managementCenter) => <Option value={managementCenter.code} key={managementCenter.code}>{managementCenter.name}</Option>)}
              </Select>
            </AccountField>;
          }}
        />
      </Col>
    </Row>

    <AccountsList
      userRole={filterRole}
      managementCenter={filterManagementCenter}
      searchStr={searchStr}
    />
  </Output>;
};

export default withRouter(Find);
