import * as R from 'ramda';
import React from 'react';
import { Menu } from 'antd';
const SubMenu = Menu.SubMenu;
import { Link } from 'react-router-dom';
import MenuItem from 'antd/lib/menu/MenuItem';

const toKey = R.replace(/ /g, '_');

const Component = ({ config }) => {
  return <Menu
    mode="inline"
    defaultOpenKeys={[toKey(R.nth(0, R.keys(config)))]}
  >
    {R.values(
      R.mapObjIndexed((subMenu, subMenuTitle) => (
        <SubMenu
          key={toKey(subMenuTitle)}
          title={<span>{subMenuTitle}</span>}
        >
          {R.values(
            R.mapObjIndexed((path, title) => {
              if (R.is(Object, path)) {
                return <SubMenu
                  key={toKey(title)}
                  title={<span>{title}</span>}
                >
                  {R.values(
                    R.mapObjIndexed((path, title) => {
                      return <MenuItem key={`${toKey(subMenuTitle)}-${toKey(title)}`}>
                        <Link to={path}>{title}</Link>
                      </MenuItem>;
                    }, path)
                  )}
                </SubMenu>;
              }

              return <MenuItem key={`${toKey(subMenuTitle)}-${toKey(title)}`}>
                <Link to={path}>{title}</Link>
              </MenuItem>;
            }, subMenu)
          )}
        </SubMenu>
      ), config)
    )}
  </Menu>;
};
export default Component;
