import React, { useState } from 'react';
import { Output } from '../../components/index';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { Alert, Button, Icon, message, Spin, Table, Upload } from 'antd';
import UpdateCommercialCodes from '../../mutations/UpdateCommercialCodes';
import { inc, isNil, map } from 'ramda';

const CommercialCodesQuery = graphql`
  query CommercialCodesQuery {
    commercialCodes {
      code
      email
      firstName
      lastName
      sales
    }
  }
`;

const useCommercialCodes = () => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [usersNotFound, setUsersNotFound] = useState();

  const onFileUploaded = ({ file }) => {
    setUsersNotFound(null);

    UpdateCommercialCodes({ file }, (ok, error, usersNotFound) => {
      setLoading(false);
      if (ok && !error) {
        message.success('Les codes commerciaux ont été mis à jour.');
        setKey(inc);
        setUsersNotFound(usersNotFound);
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.');
      }
    });
  };

  return {
    usersNotFound,
    loading,
    key,
    onFileUploaded
  };
};

const CommercialCodes = () => {
  const {
    loading,
    key,
    onFileUploaded,
    usersNotFound
  } = useCommercialCodes();

  return <Output>
    <h4>{'Mise à jour des codes commerciaux'}</h4>

    {loading && <Spin/>}

    {!loading && <>
      {<Upload.Dragger
        name="file"
        multiple={false}
        showUploadList={false}
        customRequest={onFileUploaded}
        accept=".csv"
      >
        <Button>
          <Icon type="upload"/>
        </Button>
      </Upload.Dragger>}

      {!isNil(usersNotFound) && <Alert
        type="error"
        message={map(email => {
          return <div>
            {email} non trouvé.
          </div>;
        }, usersNotFound)}
      />}

      <QueryRenderer
        environment={environment}
        query={CommercialCodesQuery}
        variables={{ key }}
        render={({ error, props }) => {
          if (error) {
            return <div>{error.stack}</div>;
          } else if (!props) {
            return <Spin/>;
          }

          const { commercialCodes = [] } = props;

          return <Table
            dataSource={commercialCodes}
            rowKey="email"
            size="small"
            tableLayout="fixed"
            style={{
              marginTop: '20px'
            }}
            pagination={{
              defaultPageSize: 25
            }}
          >
            <Table.Column
              title="Email"
              dataIndex="email"
              sorter={(a, b) => a.email.localeCompare(b.email)}
            />

            <Table.Column
              title="Code"
              dataIndex="code"
            />

            <Table.Column
              title="Structure commerciale"
              dataIndex="sales"
            />
          </Table>;
        }}
      />
    </>}

  </Output>;
};

export default CommercialCodes;
