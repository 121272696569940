/**
 * @flow
 * @relayHash fd152f073d3eb402e26afca3f0a8af87
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccountUpdateRegionalManagerMutationVariables = {|
  regionalManagerEmail: string,
  email: string,
|};
export type AccountUpdateRegionalManagerMutationResponse = {|
  +accountUpdateRegionalManager: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AccountUpdateRegionalManagerMutation = {|
  variables: AccountUpdateRegionalManagerMutationVariables,
  response: AccountUpdateRegionalManagerMutationResponse,
|};
*/


/*
mutation AccountUpdateRegionalManagerMutation(
  $regionalManagerEmail: String!
  $email: String!
) {
  accountUpdateRegionalManager(regionalManagerEmail: $regionalManagerEmail, email: $email) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regionalManagerEmail",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "regionalManagerEmail",
        "variableName": "regionalManagerEmail"
      }
    ],
    "concreteType": "BasicResponse",
    "kind": "LinkedField",
    "name": "accountUpdateRegionalManager",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountUpdateRegionalManagerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountUpdateRegionalManagerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "fd152f073d3eb402e26afca3f0a8af87",
    "metadata": {},
    "name": "AccountUpdateRegionalManagerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1230b1cea56632cf32be6fc1a256c761';

module.exports = node;
