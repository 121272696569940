import React from 'react';
import { Output } from '../../components/index';
import ConvertDocument from './components/ConvertDocument';

const Convert = () => {
  return <Output>
    <h4 style={{ marginBottom: '30px' }}>{'Conversion d\'un document'}</h4>
    <ConvertDocument/>
  </Output>;
};

export default Convert;
