import {
  commitMutation,
  graphql,
} from 'react-relay';

import environment from '../Environment';

const mutation = graphql`
  mutation RetryUpdateContractFromOMGMutation($id: String!) {
    retryUpdateContractFromOmg(id: $id) {
      ok
      error
    }
  }
`;

export default (id, onDone = null) => {
  const variables = { id };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, error } = response.retryUpdateContractFromOmg;
        onDone(ok, error);
      },
      onError: err => console.error(err),
    }
  );
};
