import * as R from 'ramda';
import React, { useState } from 'react';
import { Button, Table } from 'antd';
import CcnUpdateEnabledMutation from '../../../mutations/CcnUpdateEnabledMutation';
import { message } from 'antd';

const { Column } = Table;

const onUpdateEnabled = (ccnId, scope, offerType, enabled, setLoading) => {
  setLoading(true);

  CcnUpdateEnabledMutation({
    ccnId,
    scope,
    enabled,
    offerType
  }, (ok, error) => {
    if (error) console.log(error);
    setLoading(false);

    if (ok) {
      message.success(`La ccn a été ${enabled ? 'activée' : 'désactivée'}.`);
    } else {
      message.error('Une erreur est survenue, veuillez réessayer.');
    }
  });
};

const EnablingButtonsWrapper = ({ children }) => {
  return <div
    style={{
      display: 'flex',
      alignItems: 'center',
      columnGap: '5px'
    }}
  >
    {children}
  </div>;
};

const EnablingButton = ({
  ccnId,
  scope,
  offerType,
  scopeLabel,
  status
}) => {
  const [loading, setLoading] = useState(false);

  const hasScope = R.compose(
    R.not,
    R.isNil,
    R.find(
      R.whereEq({
        scope,
        offerType
      })
    )
  )(status);

  if (!hasScope) {
    return null;
  }

  const enabled = R.compose(
    R.propOr(false, 'enabled'),
    R.defaultTo({}),
    R.find(R.whereEq({ scope, offerType }))
  )(status);

  return <Button
    type={enabled ? 'danger' : 'primary'}
    loading={loading}
    onClick={() => onUpdateEnabled(ccnId, scope, offerType, !enabled, setLoading)}
    style={{ minWidth: '100px' }}
  >
    {enabled ? 'Désactiver' : 'Activer'} {scopeLabel}
  </Button>;
};

const compareString = (a, b, prop) => R.propOr('', prop)(a).localeCompare(R.propOr('', prop)(b));

const CcnTable = ({
  ccns,
  loading,
  spidClientUrl
}) => {
  return <Table
    dataSource={ccns}
    loading={loading}
    locale={{ emptyText: 'Aucune CCN n\'a été trouvée.' }}
    pagination={{
      position: 'both',
      size: 'small',
      style: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }
    }}
    rowKey="ccnId"
  >
    <Column
      title="ID"
      dataIndex="ccnId"
      sorter={(a, b) => compareString(a, b, 'ccnId')}
    />

    <Column
      title="CCN"
      dataIndex="shortTitle"
      sorter={(a, b) => compareString(a, b, 'shortTitle')}
    />

    <Column
      title="SPID"
      dataIndex="actionEnable"
      render={(text, { ccnId, status }) => <EnablingButtonsWrapper>
        <EnablingButton
          ccnId={ccnId}
          scope="SPID"
          offerType="PREVOYANCE"
          scopeLabel="Prévoyance"
          status={status}
        />

        <EnablingButton
          ccnId={ccnId}
          scope="SPID"
          offerType="SANTE"
          scopeLabel="Santé"
          status={status}
        />
      </EnablingButtonsWrapper>}
      width="100px"
    />

    <Column
      title="SPID Client"
      dataIndex="actionEnableClient"
      render={(text, { ccnId, status }) => <EnablingButtonsWrapper>
        <EnablingButton
          ccnId={ccnId}
          scope="SPID_CLIENT"
          offerType="PREVOYANCE"
          scopeLabel="Prévoyance"
          status={status}
        />

        <EnablingButton
          ccnId={ccnId}
          scope="SPID_CLIENT"
          offerType="SANTE"
          scopeLabel="Santé"
          status={status}
        />
      </EnablingButtonsWrapper>}
      width="100px"
    />

    {!R.isNil(spidClientUrl) && <Column
      title="URL"
      dataIndex="spidClient"
      render={(text, { idcc }) => <Button
        onClick={() => window.open(`${spidClientUrl}/${idcc}`, '_blank')}
        style={{
          backgroundColor: '#22b14c',
          color: 'white'
        }}
      >
        Souscrire en ligne
      </Button>}
      width="100px"
    />}
  </Table>;
};

export default CcnTable;
