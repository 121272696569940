import * as R from 'ramda';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../Environment';

import { Loading } from '../../components/helpers';

import {
  Row,
  Col,
  Table,
  Tag
} from 'antd';

import Moment from 'moment';

const tagColor = (k) => R.propOr('blue', k, {
  cancelled: 'cyan',
  Sent: 'green',
  on_its_way: 'purple'
});

const tagTranslation = (k) => R.propOr(k, k, {
  cancelled: 'annulée',
  Sent: 'Envoyé',
  on_its_way: 'en chemin'
});

const defaultColumns = [
  {
    title: 'Date',
    dataIndex: 'receivedAt',
    key: 'date',
    render: (v) => {
      return Moment(v).format('DD/MM/YYYY - HH:mm:ss');
    },
    width: 200
  },
  {
    title: 'Statut',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (v) => <Tag color={tagColor(v)}>{tagTranslation(v)}</Tag>
  },
  {
    title: 'Destinataires',
    dataIndex: 'recipients',
    key: 'recipients',
    render: R.join(', ')
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject'
  },
];

const EmailListQuery = graphql`
  query EmailListQuery($pagination: PaginationInput, $filter: EmailsFilterInput) {
    emailsWithPagination(filter: $filter, pagination: $pagination) {
      emails {
        id
        subject
        to {
          email
        }
        recipients
        status
        receivedAt
      }
      pagination {
        total
        page
        pages
        limit
      }
    }
  } 
`;

const formatPagination = (pagination) => ({
  total: pagination.total,
  pageSize: pagination.limit,
  current: pagination.page
});

const EmailListPage = ({
  filter
}) => {

  const [pagination, setPagination] = useState({ page: 1, limit: 25 });

  const onChangePagination = ({ current }) => {
    setPagination((pagination) => R.assoc('page', current, pagination));
  };

  let columns = defaultColumns;

  /*
  if (showActions) {
    columns = R.append(, columns);
  }
  */

  return (
    <QueryRenderer
      environment={environment}
      query={EmailListQuery}
      variables={{
        filter,
        pagination
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{error.message}</div>;
        } else if (props) {
          const emails = R.pathOr([], ['emailsWithPagination', 'emails'], props);
          const pagination = R.pathOr(undefined, ['emailsWithPagination', 'pagination'], props);
          return (
            <Row style={{ marginTop: 8 }}>
              <Col span={24}>
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={emails}
                  pagination={formatPagination(pagination)}
                  columns={columns}
                  onChange={onChangePagination}
                />
              </Col>
            </Row>
          );
        }
        return <Loading/>;
      }}
    />
  );
};

EmailListPage.defaultProps = {
  showActions: false,
  showPagination: true
};

EmailListPage.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string
  })
};

export default EmailListPage;
