import * as R from 'ramda';
import React, { useState } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { Loading } from '../../components/helpers';
import environment from '../../Environment';
import Count from './components/Count';
import { Output } from '../../components/index';
import Filter from './components/Filter';

const UsersQuery = graphql`
  query UsersQuery {
    users {
      id
      email
      status
      roles
      extra {
        region
        regionName
        managementCenter
        managementCenterName
        sales
      }
    }
  }
`;

const roles = [
  { label: 'Groupe adhésion', value: 'groupe_adhesion' },
  { label: 'Réseau commercial', value: 'reseau_commercial' },
  { label: 'Administrateur', value: 'admin' }
];

const sales = [
  { label: 'Mdpro', value: 'Mdpro' },
  { label: 'MDE', value: 'MDE' },
  { label: 'VAD', value: 'VAD' },
  { label: 'VADC', value: 'VADC' }
];

const manager = [
  { label: 'Oui', value: true },
  { label: 'Non', value: false }
];

const status = [
  { label: 'En attente', value: 'pending' },
  { label: 'Validé', value: 'confirmed' },
  { label: 'Verrouillé', value: 'locked' }
];

const Users = () => {
  const [filter, setFilter] = useState({});

  const updateFilter = (field, value) => {
    setFilter(R.assoc(field, value)(filter));
  };

  return <Output>
    <h4 style={{ marginBottom: '30px' }}>Statistiques des utilisateurs</h4>
    <QueryRenderer
      environment={environment}
      query={UsersQuery}
      render={({ error, props }) => {

        if (error) return <div>{error}</div>;
        if (!props) return <Loading/>;

        const count = R.compose(
          R.length,
          R.filter((user) =>
            R.reduce((b, [field, value]) => {
              if (R.isNil(value) || R.isEmpty(value)) return b;

              if (field === 'roles') return b && R.includes(value)(user.roles);
              else if (field === 'isManager') return b && (value ? R.includes('manager')(user.roles) : !R.includes('manager')(user.roles));
              else if (!value) return b && R.pathOr(false, field.split('.'))(user) === value;
              else return b && R.pathEq(field.split('.'), value)(user);
            }, true)(R.toPairs(filter))
          ),
          R.pathOr([], ['users'])
        )(props);

        return <div>
          <Filter set={roles} field={'roles'} label={'Rôle'} filter={filter} updateFilter={updateFilter}/>
          <Filter set={sales} field={'extra.sales'} label={'Structure commerciale'} filter={filter} updateFilter={updateFilter}/>
          <Filter set={manager} field={'isManager'} label={'Manager'} filter={filter} updateFilter={updateFilter}/>
          <Filter set={status} field={'status'} label={'Statut'} filter={filter} updateFilter={updateFilter}/>

          <br/><br/>
          <Count count={count} singular={'utilisateur'} plural={'utilisateurs'}/>
        </div>;

      }}
    />
  </Output>;
};

export default Users;
