import './Contract.less';
import * as R from 'ramda';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Output } from '../../components/index';
import UpdateContractFromOMGMutation from '../../mutations/UpdateContractFromOMGMutation';
import ContractsUpdateFromOmgFeedbackCsvTasks from '../../components/contract/ContractsUpdateFromOmgFeedbackCsvTasks';

import {
  Upload,
  message,
  Button,
  Icon,
  Spin
} from 'antd';

const Dragger = Upload.Dragger;

const useUpdateFromOMG = () => {
  const [uploadKey, setUploadKey] = useState(0);

  /*
  * [null, null] => pending
  * [true, null] => processing
  * [false, false] => done
  * [false, true] => error
  */
  const [processing, setProcessing] = useState([null, null]);

  const onProcessing = () => {
    setProcessing([true, null]);
  };

  const onDone = () => {
    setProcessing([false, false]);
    setUploadKey(Math.random());
  };

  const onReset = () => {
    setProcessing([null, null]);
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    customRequest: ({ file }) => {
      onProcessing();
      UpdateContractFromOMGMutation({ file }, (ok) => {
        if (ok) {
          message.success(`${file.name} file uploaded successfully, processing...`);
          onDone();
        } else {
          message.error(`${file.name} file upload failed.`);
        }
      });
    }
  };

  return {
    processing,
    onReset,
    uploadProps,
    uploadKey
  };
};

const UpdateFromOMG = () => {
  const {
    processing,
    onReset,
    uploadProps,
    uploadKey
  } = useUpdateFromOMG();

  return <Output>
    <h4>{'Mise à jour des contrat à partir d\'un OMG'}</h4>
    {R.equals(processing, [null, null]) && (
      <Dragger {...uploadProps}>
        <Button>
          <Icon type="upload"/>
        </Button>
      </Dragger>
    )}

    {R.equals(processing, [true, null]) && (
      <div>
        <Spin size="small"/>
              &nbsp;
        <span>Traitement du fichier</span>
      </div>
    )}

    {R.equals(processing, [false, false]) && (
      <div>
        <div>Traitement terminé</div>
        <br/>
        <Button onClick={onReset} size="small">{'Nouveau fichier OMG'}</Button>
      </div>
    )}

    {R.equals(processing, [false, true]) && (
      <div>
        <div>Nous avons rencontré un problème</div>
        <br/>
        <Button onClick={onReset} size="small">{'Réessayer'}</Button>
      </div>
    )}

    <ContractsUpdateFromOmgFeedbackCsvTasks
      key={uploadKey}
    />
  </Output>;
};

export default withRouter(UpdateFromOMG);
