import * as R from 'ramda';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../../Environment';
import { Select } from 'antd';

const Option = Select.Option;
const SelectCcnQuery = graphql`
  query SelectCcnQuery {
    ccns {
      id
      idcc
      codeBrochure
      title
      naf
      shortTitle
      offerTypes
      PREVOYANCE {
        versions {
          date
          version
        }
      }
      SANTE {
        versions {
          date
          version
        }
      }
    }
  }
`;

const SelectCcn = ({
  onChange,
  style = {}
}) => (
  <QueryRenderer
    environment={environment}
    query={SelectCcnQuery}
    render={({ error, props }) => {
      if (error) {
        throw new Error('300');
      } else if (!props) return null;

      const { ccns } = props;

      return (
        <Select
          allowClear
          showSearch
          placeholder="Nom de la CCN, code NAF, IDCC, brochure…"
          style={{ width: '100%', marginBottom: '15px', ...style }}
          onChange={(value) => {
            R.forEach(ccn => {
              if (R.isNil(value)) onChange();
              else if (ccn.id === value) onChange(ccn);
            })(ccns);
          }}
          filterOption={(input, option) => {
            const ccn = R.pathOr({}, ['props', 'ccn'])(option);
            const propOr = (prop) => R.propOr('', prop)(ccn).toLowerCase();

            input = input.toLowerCase();
            const id = propOr('id');
            const idcc = propOr('idcc');
            const codeBrochure = propOr('codeBrochure');
            const title = propOr('title');
            const shortTitle = propOr('shortTitle');
            const naf = R.propOr([], 'naf')(ccn);

            return R.includes(input)(id) || R.includes(input)(shortTitle) || R.includes(input)(title) || R.includes(input)(idcc) || R.includes(input)(codeBrochure) || R.reduce((acc, n) => acc || R.includes(input)(n), false)(naf);

          }}>
          {
            R.map(ccn => {
              return <Option key={ccn.id} value={ccn.id} ccn={ccn}>{ ccn.shortTitle }</Option>;
            })(ccns)
          }
        </Select>
      );
    }}
  />
);

export default SelectCcn;
