import * as R from 'ramda';
import React, { useState, useEffect } from 'react';

import {
  Button,
  Input,
  Form
} from 'antd';

import SignInMutation from '../../mutations/SignInMutation';
import SignInError from './components/SignInError';

const useSignIn = (form) => {
  const [request, setRequest] = useState({ processing: false, error: null });

  useEffect(() => {
    if (form.isFieldsTouched()) setRequest({ processing: false, error: null });
  }, [form]);

  const onSignIn = (e) => {
    e.preventDefault();
    setRequest({ processing: true, error: null });
    form.validateFields((err, { email, password }) => {
      if (!err) {
        SignInMutation(email, password, (ok, error) => {
          if (!ok) setRequest({ processing: false, error });
        });
      }
    });
  };

  return {
    request,
    onSignIn
  };
};

const SignInForm = ({
  form
}) => {

  const {
    request,
    onSignIn
  } = useSignIn(form);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Form {...formItemLayout} onSubmit={onSignIn}>
      <Form.Item {...tailFormItemLayout}>
        <h4 className="account-form-title">{'Connexion à l\'administration SPID'}</h4>
      </Form.Item>
      <Form.Item
        label="Email"
      >
        {form.getFieldDecorator('email', {
          defaultValue: '',
          rules: [{
            type: 'email',
            message: 'Votre email n\'est pas valide'
          }, {
            required: true,
            message: 'Veuillez saisir votre email'
          }]
        })(
          <Input placeholder="Votre email"/>
        )}
      </Form.Item>
      <Form.Item
        label="Mot de passe"
      >
        {form.getFieldDecorator('password', {
          defaultValue: '',
          rules: [{
            required: true,
            message: 'Veuillez saisir votre mot de passe'
          }, {
            validator: (rule, value, cb) => {
              if (R.length(value) === 0) cb();
              if (R.length(value) >= 6) cb();
              cb('error');
            },
            message: 'Votre mot de passe doit contenir plus de 6 caractères'
          }]
        })(
          <Input type="password" placeholder="Votre mot de passe"/>
        )}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button
          htmlType="submit"
          disabled={request.processing}
        >
          {'Connexion'}
        </Button>
        <SignInError error={request.error}/>
      </Form.Item>
    </Form>
  );
};

export default Form.create({ name: 'signIn' })(SignInForm);
