/**
 * @flow
 * @relayHash 9d5ce4b88dac7e1dd94d6b669ec618f0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemunerationQueryVariables = {|
  type?: ?string
|};
export type RemunerationQueryResponse = {|
  +files: ?$ReadOnlyArray<?{|
    +id: ?string,
    +filename: ?string,
    +uploadDate: ?string,
  |}>,
  +ccns: ?$ReadOnlyArray<?{|
    +shortTitle: ?string,
    +id: ?string,
  |}>,
|};
export type RemunerationQuery = {|
  variables: RemunerationQueryVariables,
  response: RemunerationQueryResponse,
|};
*/


/*
query RemunerationQuery(
  $type: String
) {
  files(type: $type) {
    id
    filename
    uploadDate
  }
  ccns {
    shortTitle
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "Files",
    "kind": "LinkedField",
    "name": "files",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccns",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemunerationQuery",
    "selections": (v2/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemunerationQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "9d5ce4b88dac7e1dd94d6b669ec618f0",
    "metadata": {},
    "name": "RemunerationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa6c608907094823334704e4e37bfbae';

module.exports = node;
