import './Output.less';
import React from 'react';
import { updateCommand } from '../redux_actions/command';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const Output = ({
  onChange,
  children,
  help,
  history
}) => {
  const onSelectCommand = (value) => {
    onChange(value);
    setTimeout(() => {
      history.push(value);
    }, 200);
  };

  return (
    <div className="output">
      { help &&
          React.Children.map(
            children,
            (child) => React.cloneElement(
              child,
              {
                onSelectCommand
              }
            )
          )
      }
      {
        !help && children
      }
    </div>
  );
};

export default withRouter(
  connect(
    ({ command }) => ({
      command: command.value
    }),
    (dispatch) => ({
      onChange: (command) => dispatch(updateCommand(command)),
      clear: () => dispatch(updateCommand(''))
    })
  )(Output));
