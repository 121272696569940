import {
  commitMutation,
  graphql,
} from 'react-relay';
import environment from '../Environment';

const mutation = graphql`
  mutation GenerateTableParametrageMutation($ccnId: String, $offerType: OfferTypeEnum!) {
    generateTableParametrage(ccnId: $ccnId, offerType: $offerType) {
      ok
      error
      url
    }
  }
`;

export default ({ ccnId, offerType }, done) => {
  const variables = {
    ccnId,
    offerType
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => {
        const { ok, token, url } = response.generateTableParametrage;
        done(ok, token, url);
      },
      onError: err => console.error(err),
    }
  );
};
