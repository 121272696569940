/**
 * @flow
 * @relayHash 34edb5f245b20256abc388397d5fccb7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type SelectCcnQueryVariables = {||};
export type SelectCcnQueryResponse = {|
  +ccns: ?$ReadOnlyArray<?{|
    +id: ?string,
    +idcc: ?string,
    +codeBrochure: ?string,
    +title: ?string,
    +naf: ?$ReadOnlyArray<?string>,
    +shortTitle: ?string,
    +offerTypes: ?$ReadOnlyArray<?OfferTypeEnum>,
    +PREVOYANCE: ?{|
      +versions: ?$ReadOnlyArray<?{|
        +date: ?string,
        +version: ?string,
      |}>
    |},
    +SANTE: ?{|
      +versions: ?$ReadOnlyArray<?{|
        +date: ?string,
        +version: ?string,
      |}>
    |},
  |}>
|};
export type SelectCcnQuery = {|
  variables: SelectCcnQueryVariables,
  response: SelectCcnQueryResponse,
|};
*/


/*
query SelectCcnQuery {
  ccns {
    id
    idcc
    codeBrochure
    title
    naf
    shortTitle
    offerTypes
    PREVOYANCE {
      versions {
        date
        version
      }
    }
    SANTE {
      versions {
        date
        version
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CcnVersion",
    "kind": "LinkedField",
    "name": "versions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Ccn",
    "kind": "LinkedField",
    "name": "ccns",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idcc",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "codeBrochure",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "naf",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offerTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferTypeInformations",
        "kind": "LinkedField",
        "name": "PREVOYANCE",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferTypeInformations",
        "kind": "LinkedField",
        "name": "SANTE",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCcnQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectCcnQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "34edb5f245b20256abc388397d5fccb7",
    "metadata": {},
    "name": "SelectCcnQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52b1122a7fd767386b206301c7345259';

module.exports = node;
