import './ContractThumb.less';
import * as R from 'ramda';
import React from 'react';
import ContractActionMutation from '../../mutations/ContractActionMutation';
import ContractGeneral from './ContractGeneral';
import ContractHistory from './ContractHistory';
import ContractDetails from './ContractDetails';
import ContractSignatureInfo from './ContractSignatureInfo';
import ContractTags from './ContractTags';
import ContractNotes from './ContractNotes';
import ContractAmendments from './ContractAmendments';
import ContractOrigin from './ContractOrigin';
import EditContractNumbers from './EditContractNumbers';
import EditElectronicSignatureId from './EditElectronicSignatureId';
import EditContractBuilder from './EditContractBuilder';
import ContractGED from './ContractGED';

import {
  Button,
  Row,
  Tabs
} from 'antd';

const TabPane = Tabs.TabPane;

const ContractThumb = ({
  contract
}) => {
  const action_ContractSetStatusTo = status => {
    ContractActionMutation({ contractId: contract.id, action: `set_status_to_${status}` }, () => {});
  };

  const action_ContractSignatureEmail = () => {
    ContractActionMutation({ contractId: contract.id, action: 'resend_signature_email' }, () => {});
  };

  return <div className="contract-thumb">
    <Row gutter={8} style={{ padding: '8px', backgroundColor: '#fafafa' }}>
      <Row gutter={8}>
        <ContractGeneral contract={contract}/>
        <ContractDetails contractId={contract.id}/>
      </Row>

      <Row gutter={8}>
        <ContractSignatureInfo contract={contract}/>
        <ContractGED contract={contract}/>
      </Row>
    </Row>

    <ContractAmendments contract={contract}/>
    <ContractOrigin contract={contract}/>

    <Tabs defaultActiveKey="1">
      <TabPane tab="Historique" key="1">
        <ContractHistory contractId={contract.id}/>
      </TabPane>

      <TabPane tab="Actions" key="2">
        {contract.status === 'sentForSignature' && !R.isNil('signature.id') && (
          <Button className="contract-thumb-action-btn" onClick={action_ContractSignatureEmail} size="small">{'renvoyer le mail de signature'}</Button>
        )}

        {contract.status !== 'close' && !R.isNil('signature.id') && (
          <React.Fragment>
            {[
              ['sentForSignature', <>mettre à : <b>en signature</b></>],
              ['sentToManagementSystem', <>mettre à : <b>{'envoyé à l\'OMG'}</b></>],
              ['confirmedInManagementSystem', <>mettre à : <b>{'confirmé dans l\'OMG'}</b></>],
              ['rejectedByManagementSystem', <>mettre à : <b>{'rejeté par l\'OMG'}</b></>],
              ['confirmed', <>mettre à : <b>signé</b></>],
              ['closed', 'classer sans suite'],
            ].map((v, index) => (
              <Button
                key={index}
                className="contract-thumb-action-btn"
                onClick={() => action_ContractSetStatusTo(v[0])}
                size="small"
              >
                {v[1]}
              </Button>
            ))}
          </React.Fragment>
        )}
      </TabPane>

      <TabPane tab="Tags" key="3">
        <ContractTags
          contractId={contract.id}
          tags={R.propOr([], 'tags', contract)}
        />
      </TabPane>

      <TabPane tab="Edition" key="4">
        <EditContractNumbers
          contractId={contract.id}
          contractNumbers={R.propOr([], 'contractNumbers', contract)}
          previousContractNumbers={R.propOr([], 'previousContractNumbers', contract)}
        />

        <EditElectronicSignatureId
          contractId={contract.id}
          electronicSignatureId={R.pathOr(null, ['signature', 'id'], contract)}
        />

        <EditContractBuilder
          contractId={contract.id}
          email={R.pathOr(null, ['user', 'email'], contract)}
        />
      </TabPane>

      <TabPane tab="Notes" key="5">
        <ContractNotes
          contractId={contract.id}
        />
      </TabPane>
    </Tabs>
  </div>;
};

export default ContractThumb;
